import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { makeStyles } from '@mui/styles';
import {
  create_item,
  createMultiItem,
  delete_item,
  set_path,
  store_value,
  setKey,
  setItemCount,
  setItemStep,
  setOfferPromotionToken,
} from '../../store/actions/StoreUpdateItems';
import { filterItems } from '../../utils/filterData';
import {
  get_brands,
  get_categories,
  get_condition_questions,
  get_descriptors,
  get_product_name,
  get_sizes,
} from '../../store/actions/GetRequests';
import { inputProduct } from '../../utils/analytics';
import {
  post_products,
  obo_set_api_validation_fail,
  post_user,
  postSavedUser,
  reset_error,
  store_user_value,
  storeSavedUser,
  transitionSaved,
  transitionRequested,
} from '../../store/actions/PostRequests';
import BottomNav from './steps/BottomNav';
import { ROUTES, SELLBACK_MODE, CONFIG_DATA } from '../../utils/consts';

const mapStateToProps = (state: any) => {
  const { get_data, store_items, post_items } = state;
  return { get_data, store_items, post_items };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      get_brands: get_brands,
      get_categories: get_categories,
      get_descriptors: get_descriptors,
      get_product_name: get_product_name,
      get_sizes: get_sizes,
      get_condition_questions: get_condition_questions,
      set_path: set_path,
      create_item: create_item,
      createMultiItem: createMultiItem,
      store_value: store_value,
      delete_item: delete_item,
      setKey: setKey,
      setItemCount: setItemCount,
      setItemStep: setItemStep,
      setOfferPromotionToken: setOfferPromotionToken,
      post_products: post_products,
      obo_set_api_validation_fail: obo_set_api_validation_fail,
      store_user_value: store_user_value,
      storeSavedUser: storeSavedUser,
      transitionSaved: transitionSaved,
      transitionRequested: transitionRequested,
      post_user: post_user,
      postSavedUser: postSavedUser,
      reset_error: reset_error,
    },
    dispatch,
  );

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const WizardHoc =
  (isWrapper = true) =>
  (OriginalComponent: any) => {
    const NewComponent: React.FC<Props> = ({ ...Props }) => {
      const {
        get_data,
        store_items,
        post_items,
        get_brands,
        get_categories,
        get_descriptors,
        get_product_name,
        get_sizes,
        get_condition_questions,
        set_path,
        create_item,
        createMultiItem,
        store_value,
        delete_item,
        setKey,
        setItemCount,
        setItemStep,
        setOfferPromotionToken,
        post_products,
        obo_set_api_validation_fail,
        store_user_value,
        storeSavedUser,
        transitionSaved,
        transitionRequested,
        post_user,
        postSavedUser,
        reset_error,
      } = Props;

      const get_requests = {
        get_brands,
        get_categories,
        get_descriptors,
        get_product_name,
        get_sizes,
        get_condition_questions,
      };

      const store_update_items = {
        set_path,
        create_item,
        createMultiItem,
        store_value,
        delete_item,
        setKey,
        setItemCount,
        setItemStep,
        setOfferPromotionToken,
      };

      const store_post_items = {
        storeSavedUser,
        postSavedUser,
        transitionSaved,
        transitionRequested,
      };

      const current_item = store_items.items[store_items.key];
      const [disable, disableHandler] = useState<boolean>(false);
      const history: any = useHistory();
      const { search: queryString } = useLocation();
      const location = history.location.pathname;

      useEffect(() => {
        const step = location === '/' ? 'BRAND CATEGORY' : location.replace('/', '').toUpperCase();
        inputProduct(step);
      }, [location]);
      const postUserInfo = () => {
        const paymentMethods =
          store_items.path !== 'drop_off' ? post_items.user.paymentMethods : CONFIG_DATA.payment_methods;
        const findPayment = paymentMethods[post_items.user.payment_provider];

        var initialArguments = {
          first: post_items.user.first,
          last: post_items.user.last,
          email: post_items.user.email,
          phone_number: post_items.user.phone_number === '' ? null : post_items.user.phone_number,
          address1: post_items.user.address1,
          address2: post_items.user.address2,
          city: post_items.user.city,
          state: post_items.user.state,
          zip: post_items.user.zip,
          payment_handle: post_items.user.payment_handle,
          payment_provider: findPayment.name,
          selected_offer_mode: findPayment.offer_mode,
        };

        if (findPayment.giftcard_label) {
          initialArguments = Object.assign(initialArguments, {
            selected_giftcard_label: findPayment.giftcard_label,
          });
        }

        const id = post_items.offer.entity.id;
        post_user(id, {
          arguments: initialArguments,
        });
        if (store_items.path !== SELLBACK_MODE.MAIL) {
          history.push(`${ROUTES.TIME}${queryString}`);
        } else {
          history.push(`${ROUTES.SUCCESS}${queryString}`);
        }
      };

      const offerEndpointHandler = (path: string) => {
        var url = '';
        switch (path) {
          case SELLBACK_MODE.MAIL:
            url = '/api/2/sellback/request/online';
            break;
          case SELLBACK_MODE.DROPOFF:
            url = '/api/2/sellback/request/dropoff/long';
            break;
          default:
            url = '/api/2/sellback/request/online';
            break;
        }
        return url;
      };

      const offerHandler = () => {
        const { path, items, offerPromotionToken } = store_items;
        var params: any = {
          items: filterItems(items),
        };
        if (offerPromotionToken) {
          params.offer_promotion_token = offerPromotionToken;
        }

        post_products('post', offerEndpointHandler(path), params);
      };

      const offerHandlerDropOffLong = () => {
        const { offer, user } = post_items;
        const id = offer.entity.id;
        post_products('put', `/api/2/sellback/request/${id}/online_waiting_for_dropoff`, {
          arguments: {
            retail_store_id: user.location_id,
            retail_dropoff_appointment_timestamp_phrase: user.date_time,
          },
        });
        history.push(`${ROUTES.SUCCESS}${queryString}`);
        window.scrollTo(0, 0);
      };

      const useStyles = makeStyles({
        container: {
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '30px',
          background: '#f9f9f9',
          '@media (max-width:500px)': {
            padding: '30px 20px',
          },
        },
        containerBackgroundImage: {
          width: '100%',
          maxWidth: '60rem',
          display: 'flex',
          flexDirection: 'column' as 'column',
          justifyContent: 'center' as 'center',
          border: '1px solid #dee1e6',
          padding: location === ROUTES.PROVIDER_ITEMS ? '0' : '40px',
          background: '#ffffff',
          '@media (max-width:820px)': {
            width: location === ROUTES.OFFER ? '80%' : 'none',
            alignItems: 'center' as 'center',
          },
          '@media (max-width:500px)': {
            width: '100%',
            height: '100%',
            padding: location === ROUTES.PROVIDER_ITEMS ? '0px' : '15px',
            maxHeight: 'unset',
          },
        },
        childContainerFullWidth: {
          width: '100%',
          height: '100%',
          display: 'flex',
          backgroundColor: 'rgba(255,255,255, 0.75)',
          borderRadius: '10px',
          flexDirection: 'column' as 'column',
          alignItems: 'center' as 'center',
          justifyContent: 'center' as 'center',
          '@media (max-width:820px)': {
            height: '100%',
          },
        },
      });

      const classes = useStyles();

      const original = (
        <OriginalComponent
          get_requests={get_requests}
          store_update_items={store_update_items}
          store_post_items={store_post_items}
          get_data={get_data}
          store_items={store_items}
          post_items={post_items}
          current_item={current_item}
          disableHandle={disableHandler}
          store_user_value={store_user_value}
          postSavedUser={postSavedUser}
          reset_error={reset_error}
          obo_set_api_validation_fail={obo_set_api_validation_fail}
          disable={disable}
        />
      );

      if (!isWrapper) {
        return original;
      }

      return (
        <Fragment>
          <div className={classes.container}>
            <div className={classes.containerBackgroundImage}>
              <div className={classes.childContainerFullWidth}>
                {original}
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {location !== ROUTES.OFFER && location !== ROUTES.SUCCESS && (
                    <BottomNav
                      disable={disable}
                      current_item={current_item}
                      post_items={post_items}
                      store_items={store_items}
                      setKey={setKey}
                      offerHandler={offerHandler}
                      postUserInfo={postUserInfo}
                      store_update_items={store_update_items}
                      offerHandlerDropOffLong={offerHandlerDropOffLong}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    };
    return NewComponent;
  };

const composeWrapper = (arg1?: any) => compose(connect(mapStateToProps, mapDispatchToProps), WizardHoc(arg1));

export default composeWrapper;
