import React, { useEffect } from 'react';
import { withStyles } from '@mui/styles';
import HOC from '../HOC';
import Count from '../../../components/Count';
import { ROUTES } from '../../../utils/consts';

const ItemCount: React.FC<IChildComponent> = ({ classes, store_items, store_update_items }): JSX.Element => {
  const { itemCount } = store_items;
  const { setItemCount } = store_update_items;

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      if (window.location.pathname === ROUTES.ITEM_COUNT) {
        window.history.pushState(null, document.title, window.location.href);
      }
    });

    return () => window.removeEventListener('popstate', () => {});
  }, []);

  const onCount = (value: number) => {
    setItemCount(itemCount + value);
  };

  return (
    <div className={classes.container}>
      <div className="headline">How many items do you want to sell today?</div>
      <div className={classes.itemWrapper}>
        <div className={classes.inputWrapper}>
          <Count itemCount={itemCount} onCallback={onCount} />
        </div>
        <div className={classes.description}>Don't worry, you can always add more later...</div>
      </div>
    </div>
  );
};

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  itemWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    margin: '6em 0',
  },
  description: {
    maxWidth: '560px',
    fontSize: '1.1em',
    fontWeight: 500,
    margin: '30px 0',
    textAlign: 'center' as 'center',
    '@media (max-width:500px)': {
      fontSize: '1em',
    },
  },
});

export default HOC()(withStyles(styles)(ItemCount));
