import { SELLBACK_STEP } from './consts';
import { hasSizes } from './filterData';

export const getObjectValues = (object: any) => {
  const values = Object.keys(object).map(function (key) {
    return object[key];
  });
  return values;
};

export const getObjectEntries = (object: any) => {
  if (!object) return [];
  var ownProps = Object.keys(object),
    i = ownProps.length,
    resArray = new Array(i);

  while (i--) resArray[i] = [ownProps[i], object[ownProps[i]]];
  return resArray;
};

export const getLastStep = (current_item: any) => {
  var step = SELLBACK_STEP.INITIAL;
  const {
    category,
    brand,
    descriptor,
    product_type,
    name,
    suggested_name,
    suggested_style_id,
    size,
    colors,
    condition,
    condition_responses,
  } = current_item;
  if (!brand || !category || (category === 'Sleeping Pad' ? false : !descriptor)) {
    step = SELLBACK_STEP.INITIAL;
  } else if (product_type === 'name' && !suggested_name && !name) {
    step = SELLBACK_STEP.PRODUCT;
  } else if (product_type === 'sku' && !suggested_style_id) {
    step = SELLBACK_STEP.PRODUCT;
  } else if (hasSizes({ category, descriptor }) && !size) {
    step = SELLBACK_STEP.SIZE;
  } else if (colors.length === 0) {
    step = SELLBACK_STEP.COLOR;
  } else if (!condition) {
    step = SELLBACK_STEP.CONDITION;
  } else if (getObjectValues(condition_responses).filter((val) => val === 'yes' || val === 'no').length !== 4) {
    step = SELLBACK_STEP.SURVEY;
  } else {
    step = SELLBACK_STEP.NEXTITEM;
  }
  return step;
};

export const validateEmail = (email: any) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateVenmo = (value: any) => {
  return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value);
};

export const capitalizeFirstLetter = (string: any) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
