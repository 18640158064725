import React, { Fragment, useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { Button } from '@mui/material';
import HOC from '../HOC';
import CheckBoxButton from '../../../components/CheckBoxButton';
import { toCamelCase } from '../../../utils/Objects';
import { CONFIG_DATA, SELLBACK_STEP } from '../../../utils/consts';

interface TabArray {
  title: string;
  description: string;
}

const NewOrUsed: React.FC<IChildComponent> = ({
  store_update_items,
  current_item,
  classes,
  disable,
  disableHandle,
}): JSX.Element => {
  let tabsArray: TabArray[] = [];
  CONFIG_DATA.condition_descriptions.forEach((desc: any) => {
    tabsArray.push({
      title: toCamelCase(desc[0]).trim(),
      description: desc[1],
    });
  });
  const { store_value, setItemStep } = store_update_items;
  const { condition } = current_item;

  const [selectedTab, setSelectedTab] = useState<string>(condition === '' ? 'New' : condition);

  const setSelectedTabAndClearConditions = (tab: string) => {
    setSelectedTab(tab);
    store_value({ condition: '' });
  };

  useEffect(() => {
    disableHandle(!(condition !== ''));
  }, [disableHandle, condition]);

  const valueHandler = (value: string) => {
    store_value({ condition: value });
  };

  const descriptionHandler = () => {
    const filterTabs = tabsArray.filter((ele) => ele.title === selectedTab)[0];
    return (
      <Fragment>
        <div className={classes.descriptionTitleText}>{filterTabs.title}</div>
        <div className={classes.descriptionText}>{filterTabs.description}</div>
        <div className={classes.certifyText}>
          I certify this item to be "{filterTabs.title}" and meets the criteria above{' '}
          <CheckBoxButton
            valueHandler={() => valueHandler(filterTabs.title)}
            checked={condition === filterTabs.title}
          />
        </div>
      </Fragment>
    );
  };

  return (
    <div className={classes.container}>
      <div className="headline">Select the condition that best describes this item</div>
      <div className={classes.contentContainer}>
        <div className={classes.tabContainer}>
          {tabsArray.map((tab) => {
            return (
              <Fragment key={tab.title}>
                <div
                  onClick={() => setSelectedTabAndClearConditions(tab.title)}
                  className={selectedTab === tab.title ? classes.selectedTabButton : classes.tabButton}
                >
                  {tab.title === 'New' || tab.title === 'Like New' ? tab.title : tab.title.split(' ')[2]}
                </div>
              </Fragment>
            );
          })}
        </div>
        <div className={classes.descriptionContainer}>{descriptionHandler()}</div>
      </div>
      <Button
        onClick={() => setItemStep(SELLBACK_STEP.SURVEY)}
        className={classes.button}
        color="primary"
        variant="contained"
        disabled={disable}
      >
        Next
      </Button>
    </div>
  );
};

const styles = (theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'space-between',
    '& .headline': {
      marginBottom: '30px',
    },
  },
  contentContainer: {
    width: '400px',
    border: '1px solid #dee1e6',
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    width: '100%',
    fontWeight: '500',
    fontSize: 14,
    '@media (max-width:500px)': {
      fontSize: 12,
    },
  },
  tabButton: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '25%',
    borderBottom: '1px solid #dee1e6',
    borderRight: '1px solid #dee1e6',
    textTransform: 'uppercase' as 'uppercase',
    '@media (max-width:500px)': {
      padding: '0 5px',
    },
  },
  selectedTabButton: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '25%',
    height: '45px',
    borderBottom: `3px solid #7A444E`,
    borderRight: '1px solid #dee1e6',
    fontWeight: '800',
    textTransform: 'uppercase' as 'uppercase',
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    padding: '15px 10px',
  },
  descriptionTitleText: {
    margin: '10px',
    fontWeight: '800',
  },
  descriptionText: {
    margin: '0px 10px 10px 10px',
  },
  certifyText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '.8em',
    fontWeight: '500',
    margin: '0px 10px',
  },
  button: {
    width: '250px',
    margin: '30px 5px 0 !important',
    fontSize: '16px !important',
    fontWeight: '900 !important',
  },
});

export default HOC(false)(withStyles(styles)(NewOrUsed));
