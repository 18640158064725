import { CONFIG_DATA } from './consts';

const validate = (object: any, schema: any, category: any, descriptor: any) =>
  Object.keys(schema)
    .filter((key) => {
      let shouldValidateSize1 = false;
      let shouldValidateSize2 = false;
      const sizeIndexes = ['size', 'size_two']; // for MRW it needs to be size1 and size2

      let isDescriptorRequired = true;
      const hasProductProfiles =
        CONFIG_DATA['product_profiles'].hasOwnProperty(category) &&
        CONFIG_DATA['product_profiles'][category].hasOwnProperty(descriptor);

      if (hasProductProfiles && descriptor === '' && key === 'descriptor') {
        isDescriptorRequired = false;
      }
      if (hasProductProfiles) {
        shouldValidateSize1 = CONFIG_DATA['product_profiles'][category][descriptor]['size'];
        shouldValidateSize2 = CONFIG_DATA['product_profiles'][category][descriptor]['size2'];
      } else {
        console.error('validate key error');
      }
      const inArray = (searchElem: any, arr: any) => arr.indexOf(searchElem) !== -1;
      const isNotASizeField = !inArray(key, sizeIndexes);
      const isSize1Required = key === sizeIndexes[0] && shouldValidateSize1;
      const isSize2Required = key === sizeIndexes[1] && shouldValidateSize2;
      return (isNotASizeField && isDescriptorRequired) || isSize1Required || isSize2Required;
    })
    .filter((key) => !schema[key](object[key]))
    .map((key) => new Error(`${key} is invalid.`));

const defaultSchema = {
  brand: (value: any) => value.length > 0,
  category: (value: any) => value.length > 0,
  descriptor: (value: any) => value.length > 0,
  condition: (value: any) => value.length > 0,
  condition_responses: (value: any) =>
    value.promotional_item !== null &&
    value.item_broken !== null &&
    value.tags_removed_unreadable !== null &&
    value.item_very_old !== null,
  colors: (value: any) => value.length > 0,
  size: (value: any) => value.length > 0, // for MRW it needs to be size1
  size_two: (value: any) => value.length > 0, // for MRW it needs to be size2
};

export default function validation(object: any) {
  const category = object.category;
  const descriptor = object.descriptor;
  var error = [];
  if (category.length > 0) {
    error = validate(object, defaultSchema, category, descriptor);
  } else {
    error = [{ message: 'Missing Category' }];
  }
  return error;
}
