import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import moment from 'moment-timezone';
import HOC from '../HOC';
import { CircularProgress, Button } from '@mui/material';
import Map from './Maps/Map';
import { CONFIG_DATA, ROUTES, SELLBACK_MODE, SELLBACK2_REQUEST_STATE, COMMON_RESPONSE } from '../../../utils/consts';

const Success: React.FC<IChildComponent> = ({
  classes,
  post_items,
  store_items,
  get_data,
  store_user_value,
  reset_error,
}): JSX.Element => {
  const history: any = useHistory();
  const { search: queryString } = useLocation();
  const { path } = store_items;
  const { user, loading, offer, error_code, error_message } = post_items;
  const { date_time, location, location_id, brand } = user;
  const [metro] = useState<string>(user.closest_metro_id);
  const isValidationError = error_code === `${COMMON_RESPONSE.CODE_VALIDATION_EXCEPTION}`;

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
      window.location.reload();
    });

    return () => window.removeEventListener('popstate', () => {});
  }, []);

  let storeInfo: any = {};
  if (CONFIG_DATA.hasOwnProperty('stores_by_subdomain') && CONFIG_DATA['stores_by_subdomain'].hasOwnProperty(brand)) {
    storeInfo = CONFIG_DATA['stores_by_subdomain'][brand].find((domain: any) => domain.id === location_id);
  }

  const valueHandler = (value: string | any, location_id: string, brand: string) => {
    store_user_value(['location'], value);
    store_user_value(['location_id'], location_id);
    store_user_value(['brand'], brand);
  };

  const formatAddress = (address: any) => {
    return address ? ` ${address}` : '';
  };

  if (offer && offer.entity && offer.entity.state === SELLBACK2_REQUEST_STATE.ONLINE_SAVED) {
    return (
      <Fragment>
        <div className={classes.title}>Success</div>
        <div className={classes.timeContainer} style={{ width: '100%', lineHeight: '1.5em' }}>
          Your sellback has been saved. <br />
          You will receive an email with a link to resume this sellback when you are ready.
        </div>
        <Button
          style={{ width: '15em', backgroundColor: '#1C2229', color: '#fff' }}
          className={classes.ReviewButton}
          color="inherit"
          variant="contained"
          onClick={() => {
            window.location.href = '/';
          }}
        >
          Home
        </Button>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {error_code ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '0 15px',
          }}
        >
          <div className={classes.errorHeader}>Oops, something’s not right.</div>
          <div className={classes.errorTextSmall}>
            {isValidationError
              ? error_message || 'Please check your submission and try again.'
              : 'There was a problem with your information'}
          </div>
          <Button
            onClick={() => {
              reset_error();
              if (store_items.path !== SELLBACK_MODE.MAIL) {
                history.push(`${ROUTES.TIME}${queryString}`);
              } else {
                history.push(`${ROUTES.USER_INFO}${queryString}`);
              }
            }}
            className={classes.backButton}
            color="info"
            variant="contained"
          >
            Back
          </Button>
        </div>
      ) : loading ? (
        <CircularProgress color={'inherit'} style={{ color: '#262262' }} />
      ) : (
        <Fragment>
          <div className={classes.title}>
            {path === SELLBACK_MODE.DROPOFF ? 'YOUR DROPOFF DETAILS' : 'YOUR MAIL-IN DETAILS'}
          </div>
          <div className={classes.container}>
            {path === SELLBACK_MODE.DROPOFF && (
              <div className={classes.mapContainer}>
                {metro !== '' && (
                  <Map
                    valueHandler={valueHandler}
                    metro={metro}
                    location={location}
                    location_id={location_id}
                    get_data={get_data}
                  />
                )}
                {location && date_time && (
                  <div className={classes.detail}>
                    <div className={classes.detailTitle}>Your Drop Off Details:</div>
                    <div>{`${storeInfo['address1']}`}</div>
                    {storeInfo['address2'] && <div>{`${storeInfo['address2']}`}</div>}
                    <div>{`${formatAddress(storeInfo['city'])},${formatAddress(storeInfo['state'])}${formatAddress(storeInfo['zip'])}`}</div>
                    <div style={{ marginTop: '10px' }}>
                      {moment(date_time).format('MM/DD/yyyy')} &nbsp;&nbsp;&nbsp; {moment(date_time).format('h:mm A')}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className={classes.timeContainer} style={{ width: path === SELLBACK_MODE.DROPOFF ? '50%' : '100%' }}>
              <div>
                {path === SELLBACK_MODE.DROPOFF
                  ? `Gather your gear (say your last goodbyes) and head to your selected location. Upon arrival, notify an associate that you are dropping off gear to Out&Back and enjoy getting paid!`
                  : `Gather your gear (say your last goodbyes) and pack it up. Affix the pre-paid shipping label – emailed to you by our team, to the outside of your package and drop-off at your nearest USPS location.`}
              </div>
              <br />
              <div>We appreciate your business and are looking forward to seeing you soon. Cheers!</div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

const styles = () => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
  },
  title: {
    textTransform: 'uppercase' as 'uppercase',
    fontFamily: 'OldSansBlack',
    fontSize: '2.5em',
    fontWeight: 500,
    textAlign: 'center' as 'center',
    marginBottom: '30px',
    '@media (max-width:500px)': {
      fontSize: '2em',
    },
  },
  mapContainer: {
    width: '50%',
    '@media (max-width:500px)': {
      width: '100% !important',
      order: 2,
    },
  },
  timeContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    height: '100%',
    padding: '0 20px',
    fontSize: '1.5em',
    textAlign: 'center' as 'center',
    '@media (max-width:500px)': {
      width: '100% !important',
      fontSize: '1.1em',
      order: 1,
      marginBottom: '30px',
    },
  },
  detail: {
    fontSize: '1.2em',
    fontWeight: 500,
    textTransform: 'uppercase' as 'uppercase',
    marginTop: '20px',
    '@media (max-width:500px)': {
      fontSize: '1.1em',
    },
  },
  detailTitle: {
    marginBottom: '20px',
  },
  backButton: {
    width: '15em',
    fontSize: '16px !important',
    fontWeight: '900 !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    marginTop: '30px !important',
  },
  errorHeader: {
    color: '#000',
    fontSize: '35px',
    fontWeight: 400,
    alignSelf: 'center',
    margin: '30px 0',
  },
  errorTextSmall: {
    textAlign: 'center' as 'center',
    fontSize: '18px',
    fontWeight: 400,
    color: '#000',
    marginBottom: '5px',
  },
  ReviewButton: {
    margin: '30px 5px 0px 5px !important',
    fontSize: '16px !important',
    fontWeight: '900 !important',
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
    },
    '@media (max-width:1023px)': {
      width: '100%',
    },
  },
});

export default HOC()(withStyles(styles)(Success));
