export const CONFIG_DATA = window.website_config;

const subdomain = CONFIG_DATA.invocation_brand;

export const ROUTES = {
  HOME: '/',
  DROP_OFF_MAIL_IN: '/drop-off-mail-in',
  ITEM_COUNT: '/item-count',
  PROVIDER_ITEMS: '/provide-items',
  REVIEW: '/review',
  OFFER: '/offer',
  USER_INFO: '/user-info',
  TIME: '/time',
  SUCCESS: '/success',
};

const baseBrandSpecificRoute = `/b/${subdomain}`;

export const BRAND_SPECIFIC_ROUTES = {
  HOME: baseBrandSpecificRoute,
  PROVIDER_ITEMS: `${baseBrandSpecificRoute}/provide-items`,
  BRAND_SPECIFIC_OFFER: `${baseBrandSpecificRoute}/offer`,
  BRAND_SPECIFIC_USER_INFO: `${baseBrandSpecificRoute}/user-info`,
  BRAND_SPECIFIC_SUCCESS: `${baseBrandSpecificRoute}/success`,
};

export const OFFER_MODE = {
  DIRECT: 'direct',
  DIRECT_GIFT_CARD: 'direct,gift_card',
};

export const COMMON_RESPONSE = {
  CODE_NOT_FOUND_EXCEPTION: 460,
  CODE_ALREADY_EXISTS_EXCEPTION: 461,
  CODE_VALIDATION_EXCEPTION: 462,
};

export const SELLBACK_MODE = {
  DROPOFF: 'drop_off_estimate',
  MAIL: 'mail_in',
};

export const SELLBACK_STEP = {
  INITIAL: 0,
  PRODUCT: 1,
  SIZE: 2,
  COLOR: 3,
  CONDITION: 4,
  SURVEY: 5,
  NEXTITEM: 6,
};

export const SELLBACK_BRAND_SPECIFIC_STEP = {
  INITIAL: 0,
  PRODUCT: 1,
};

export const SELLBACK2_REQUEST_STATE = {
  COMMITTED: 'committed',
  ONLINE_SAVED: 'online_saved',
  REQUESTED: 'requested',
};

export const INVALID_OFFER_PROMOTION_TOKEN = 'invalid_offer_promotion_token';
