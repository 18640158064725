import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { withStyles } from '@mui/styles';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField, CircularProgress, Button } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment-timezone';
import HOC from '../HOC';
import Map from './Maps/Map';
import { CONFIG_DATA, ROUTES, COMMON_RESPONSE } from '../../../utils/consts';

const Time: React.FC<IChildComponent> = ({
  classes,
  disableHandle,
  store_user_value,
  post_items,
  get_data,
  obo_set_api_validation_fail,
}): JSX.Element => {
  const history: any = useHistory();
  const { search: queryString } = useLocation();
  const { user, loading, error_code, error_message } = post_items;
  const { location, location_id } = user;
  const { invocationBrand } = get_data;
  const [metro] = useState<string>(user.closest_metro_id);
  const isValidationError = error_code === `${COMMON_RESPONSE.CODE_VALIDATION_EXCEPTION}`;
  const fourWeeksLimitedDateUTC = moment().add(28, 'days');

  window.obo_set_api_validation_fail = function (errorCode, errorMessage) {
    if (errorCode) {
      history.push(`${ROUTES.SUCCESS}${queryString}`);
      obo_set_api_validation_fail(errorCode, errorMessage);
    }
  };

  let storeInfo: any = {};
  Object.keys(CONFIG_DATA.stores_by_subdomain).forEach((key) => {
    CONFIG_DATA.stores_by_subdomain[key].forEach((row: any) => {
      if (row.id === location_id) {
        storeInfo = row;
      }
    });
  });
  if (!storeInfo.hasOwnProperty('store_hours')) {
    const data = {
      retail_store_address: location,
      retail_store_id: location_id,
    };
    Sentry.captureMessage(`We can't find this store in the config: ${JSON.stringify(data)}`, Sentry.Severity.Error);
  }
  const timeZoneOfLocation = storeInfo['tz_olson_zone'];
  const store_hours = storeInfo['store_hours'];
  const days_open = Object.keys(store_hours['hours_index']);

  let startDateUTC: Date;
  if (store_hours.hasOwnProperty('appointments_available_on_or_after_timestamp_phrase')) {
    const piecesTimeStamp = store_hours['appointments_available_on_or_after_timestamp_phrase'].split('+');
    startDateUTC = moment(piecesTimeStamp[0]).tz('Etc/UTC', true).utcOffset(0, false).toDate();
  }

  let stopDateUTC: Date;
  if (store_hours.hasOwnProperty('appointments_available_before_timestamp_phrase')) {
    const piecesTimeStamp = store_hours['appointments_available_before_timestamp_phrase'].split('+');
    stopDateUTC = moment(piecesTimeStamp[0]).tz('Etc/UTC', true).utcOffset(0, false).toDate();
  }

  // Build set from list of per-store blackout dates

  let blackoutDatesSet = new Set<string>();

  const unavailableDatesKey = 'unavailable_appointment_date_phrases';
  if (storeInfo.hasOwnProperty(unavailableDatesKey)) {
    const unavailableDatesValue = storeInfo[unavailableDatesKey];

    unavailableDatesValue.forEach((dateString: string) => {
      blackoutDatesSet.add(dateString);
    });
  }

  const isDate = (day: Date) => {
    return day instanceof Date && !isNaN(day.valueOf());
  };

  const [timeState, timeStateHandler] = useState<string>('');
  const [dateState, dateStateHandler] = useState<string | any>(null);

  let timesAvailable: string[] = [];
  if (dateState !== null) {
    // Append to timesAvailable array based off of the hours_index for the day selected.
    const day_of_week = Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(dateState);

    const hours_index = store_hours['hours_index'];

    if (hours_index.hasOwnProperty(day_of_week)) {
      const store_start_time = hours_index[day_of_week][0];
      const store_start_hour = store_start_time[0];
      const store_start_minute = store_start_time[1];

      const store_stop_time = hours_index[day_of_week][1];
      const store_stop_hour = store_stop_time[0];

      const selectedDate = moment(dateState).format('YYYY-MM-DD');

      for (let i = store_start_hour; i < store_stop_hour; i++) {
        const selectedMoment = moment(selectedDate).set({ hour: i, minute: 0, second: 0, millisecond: 0 });

        if (moment().isAfter(selectedMoment)) continue;

        const AMPM = i < 12 ? 'AM' : 'PM';
        const standard_time_hour = i > 12 ? i - 12 : i;

        if (i > store_start_hour || store_start_minute === 0) {
          // if we ever want half-hour increments again, use this https://gist.github.com/davidrenne/3b58b9af2b8656a49087219a80e0535f
          timesAvailable.push(`${standard_time_hour}:00 ${AMPM}`);
        }
      }
    }
  }

  useEffect(() => {
    disableHandle(!(isDate(dateState) && timeState !== '' && location));
  }, [timeState, dateState, location, disableHandle]);

  useEffect(() => {
    const day_of_week = Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(dateState);
    const storeData = {
      day_of_week,
      storeInfo,
      selectedData: moment(dateState).format('YYYY-MM-DD'),
    };
    localStorage.setItem('storeData', JSON.stringify(storeData));
  }, [dateState]);

  useEffect(() => {
    const timePieces = timeState.split(' ');
    let [hours, minutes] = timePieces[0].split(':');
    if (timePieces[1] === 'PM' && hours !== '12') {
      hours = `${Number(hours) + Number('12')}`;
    }
    if (Number(hours) < 10) hours = '0' + hours;
    if (isDate(dateState) && timeState !== '') {
      // Establish midnight as local time string

      // TODO(dustin): There are going to be weird math issues if the city the person searches for does not match their current timezone. We'd need to use the timezone of the store to manipulate timestamps absolutely rather than using any 'local' time functions.

      // The date of `dateState` currently matches the appointment day but the
      // hour, minute, and second match 'now'.
      var date_year = String(dateState.getFullYear());
      var date_month = String(dateState.getMonth() + 1);
      var date_day = String(dateState.getDate());

      if (date_month.length === 1) {
        date_month = '0' + date_month;
      }

      if (date_day.length === 1) {
        date_day = '0' + date_day;
      }

      const timestampLocal = `${date_year}-${date_month}-${date_day}T${hours}:${minutes}:00`;

      // Build UTC appointment timestamp

      const timestamp_utc = moment(timestampLocal).tz(timeZoneOfLocation, true).utcOffset(0, false).format();
      const day_hours_utc = timestamp_utc.split('T');
      const date_utc = day_hours_utc[0];

      const hours_minutes_utc = day_hours_utc[1].split(':');
      const hours_utc = hours_minutes_utc[0];
      const minutes_utc = hours_minutes_utc[1];

      const appointment_timestamp_utc = `${date_utc}T${hours_utc}:${minutes_utc}:00.000Z`;

      store_user_value(['date_time'], appointment_timestamp_utc);
    }
  }, [timeState, dateState, store_user_value]);

  const valueHandler = (value: string | any, location_id: string, brand: string) => {
    store_user_value(['location'], value);
    store_user_value(['location_id'], location_id);
    store_user_value(['brand'], brand);
  };

  const formatAddress = (address: any) => {
    return address ? ` ${address}` : '';
  };

  return (
    <Fragment>
      {error_code ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '0 15px',
          }}
        >
          <div className={classes.errorHeader}>Oops, something’s not right.</div>
          <div className={classes.errorTextSmall}>
            {isValidationError
              ? error_message || 'Please check your submission and try again.'
              : 'There was a problem with the request. Contact the software group for support.'}
          </div>
          {isValidationError ? (
            <Button
              onClick={() => {
                history.push(`${ROUTES.USER_INFO}${queryString}`);
              }}
              className={classes.backButton}
              color="info"
              variant="contained"
            >
              Back
            </Button>
          ) : (
            <Button
              onClick={() => {
                window.location.href = '/';
              }}
              className={classes.backButton}
              color="primary"
              variant="contained"
            >
              Start Over
            </Button>
          )}
        </div>
      ) : loading ? (
        <CircularProgress color={'inherit'} style={{ color: '#262262' }} />
      ) : (
        <Fragment>
          <div className={classes.title}>Schedule your drop off</div>
          <div className={classes.container}>
            <div className={classes.mapContainer}>
              {metro !== '' && (
                <Map
                  valueHandler={valueHandler}
                  metro={metro}
                  location={location}
                  location_id={location_id}
                  get_data={get_data}
                />
              )}
              <div className={classes.location}>
                <div>{`${storeInfo['address1']}`}</div>
                {storeInfo['address2'] && <div>{`${storeInfo['address2']}`}</div>}
                <div>{`${formatAddress(storeInfo['city'])},${formatAddress(storeInfo['state'])}${formatAddress(storeInfo['zip'])}`}</div>
              </div>
            </div>
            <div className={classes.timeContainer}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className={classes.dateWrapper}>
                  <CalendarMonthIcon style={{ margin: '0 5px' }} />
                  <MobileDatePicker
                    maxDate={fourWeeksLimitedDateUTC.toDate()}
                    shouldDisableDate={(calendarTimestampLocal: Date) => {
                      // If the date is beyond the 4-week limit from 'today', disable it.
                      if (moment(calendarTimestampLocal).isAfter(fourWeeksLimitedDateUTC)) {
                        return true;
                      }

                      // The current date in UTC
                      const calendarMomentUTC = moment(calendarTimestampLocal).tz('Etc/UTC', true).utcOffset(0, false);

                      // The current date in the TZ of the store
                      const calendarMomentStore = calendarMomentUTC.tz(timeZoneOfLocation, true);

                      // Check if the store has bounds on the dates that it's
                      // available for appointments and if where the current
                      // date is in relation to it

                      const calendarTimestampUTC = calendarMomentUTC.toDate();

                      if (startDateUTC && calendarTimestampUTC.getTime() < startDateUTC.getTime()) {
                        // If store has a startDateUTC (appointment-scheduling
                        // start date) and the current time is less than it,
                        // disable the date
                        return true;
                      } else if (stopDateUTC && calendarTimestampUTC.getTime() >= stopDateUTC.getTime()) {
                        // If store has a stopDateUTC (appointment-scheduling
                        // stop date) and the current time is greater-than-or-
                        // equal-to-it, disable the date
                        return true;
                      }

                      // Check that the store even has hours on this day

                      const dayOfWeekLocal = Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(
                        calendarTimestampLocal,
                      );

                      if (days_open.indexOf(dayOfWeekLocal) === -1) {
                        return true;
                      }

                      // Check if this date is blacked-out for every store

                      const calendarMomentStoreFormattedDate = calendarMomentStore.format('YYYY-MM-DD');

                      if (
                        CONFIG_DATA.hasOwnProperty('dropoff_blackout_dates_general') &&
                        CONFIG_DATA['dropoff_blackout_dates_general'].hasOwnProperty(calendarMomentStoreFormattedDate)
                      ) {
                        return true;
                      }

                      // Check if this date is blacked-out for this store
                      else if (blackoutDatesSet.has(calendarMomentStoreFormattedDate)) {
                        return true;
                      }

                      return false;
                    }}
                    inputFormat="MM/dd/yyyy"
                    value={dateState}
                    minDate={new Date()}
                    onChange={(e) => {
                      // Clear times as now the dates can change different times
                      timeStateHandler('');
                      dateStateHandler(e);
                    }}
                    renderInput={(params) => (
                      <div className={classes.formControl}>
                        <TextField
                          {...params}
                          color="secondary"
                          className={classes.select}
                          placeholder={'choose a date'}
                        />
                      </div>
                    )}
                  />
                </div>
                {dateState != null && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'flex-start',
                      marginTop: '20px',
                    }}
                  >
                    {timesAvailable.map((times, idx) => (
                      <div
                        key={idx}
                        onClick={() => timeStateHandler(times)}
                        style={{
                          width: '20%',
                          fontFamily: 'OldSansBlack',
                          backgroundColor: timeState === times ? '#1C2229' : '#949291',
                          textAlign: 'center',
                          color: '#fff',
                          padding: '5px 2px',
                          margin: '5px 5px',
                          cursor: 'pointer',
                        }}
                      >
                        {times}
                      </div>
                    ))}
                  </div>
                )}
                {dateState && timeState && (
                  <div className={classes.detail}>
                    <div className={classes.detailTitle}>Your Drop Off Details:</div>
                    <div className={classes.detailDate}>
                      {moment(dateState).format('MM/DD/yyyy')} &nbsp;&nbsp;&nbsp; {timeState}
                    </div>
                  </div>
                )}
              </LocalizationProvider>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

const styles = (theme: any) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
  },
  title: {
    textTransform: 'uppercase' as 'uppercase',
    fontFamily: 'OldSansBlack',
    fontSize: '2em',
    fontWeight: 500,
    textAlign: 'center' as 'center',
    marginBottom: '30px',
    '@media (max-width:500px)': {
      fontSize: '1.5em',
    },
  },
  mapContainer: {
    width: '50%',
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  timeContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    height: '100%',
    padding: '0 20px',
    marginBottom: '20px',
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  dateWrapper: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #d3d1d1',
    padding: '3px',
    width: '200px',
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px !important',
    },
    '& img': {
      width: '30px',
      margin: '0 10px',
    },
  },
  formControl: {
    width: '150px',
    '@media (max-width:500px)': {
      width: '100%',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d3d1d1',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d3d1d1',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d3d1d1 !important',
    },
  },
  select: {
    border: `solid 4px ${theme.palette.secondary.main}`,
    borderRadius: '15px',
    width: '100%',
    '&:before': {
      borderColor: 'none',
    },
  },
  location: {
    margin: '20px 0',
    fontSize: '1.2em',
    fontWeight: 500,
    textTransform: 'uppercase' as 'uppercase',
    '@media (max-width:500px)': {
      fontSize: '1.1em',
    },
  },
  detail: {
    fontSize: '1.2em',
    fontWeight: 500,
    textTransform: 'uppercase' as 'uppercase',
    marginTop: '20px',
    '@media (max-width:500px)': {
      fontSize: '1.1em',
    },
  },
  detailDate: {
    marginTop: '20px',
  },
  backButton: {
    width: '15em',
    fontSize: '16px !important',
    fontWeight: '900 !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    marginTop: '30px !important',
  },
  errorHeader: {
    color: '#000',
    fontSize: '35px',
    fontWeight: 400,
    alignSelf: 'center',
    margin: '30px 0',
  },
  errorTextSmall: {
    textAlign: 'center' as 'center',
    fontSize: '18px',
    fontWeight: 400,
    color: '#000',
    marginBottom: '5px',
  },
});

export default HOC()(withStyles(styles)(Time));
