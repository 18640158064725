import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { Button } from '@mui/material';
import HOC from '../HOC';
import ConditionCheckbox from './ConditionCheckBox';
import { ROUTES, SELLBACK_STEP } from '../../../utils/consts';
import { getLastStep, getObjectValues } from '../../../utils/functions';

const Condition: React.FC<IChildComponent> = ({
  get_data,
  get_requests,
  store_update_items,
  store_items,
  current_item,
  classes,
  disable,
  disableHandle,
}): JSX.Element => {
  const history: any = useHistory();
  const { search: queryString } = useLocation();
  const total_questions = 4;
  const { store_value, setItemStep } = store_update_items;
  const { items, key } = store_items;
  const { get_condition_questions } = get_requests;
  const { questions } = get_data;
  const { category, condition_responses } = current_item;
  const arrayNames = questions && questions.map((question: any) => question.identifier);

  const [state, setState] = useState<object>(condition_responses);

  useEffect(() => {
    setState(condition_responses);
  }, [condition_responses]);

  useEffect(() => {
    if (category) {
      get_condition_questions({ category });
    }
  }, [get_condition_questions, category]);

  useEffect(() => {
    store_value({ condition_responses: state });
  }, [store_value, state]);

  const valueHandler = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
    const { name, value } = e.target;
    setState((prevState: object) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const arrayQuestion = questions && questions.map((question: any) => question.text);
  const values = getObjectValues(state);
  const filteredValues = values.filter((val) => val === 'yes' || val === 'no').length;

  useEffect(() => {
    disableHandle(!(filteredValues === total_questions));
  }, [disableHandle, filteredValues]);

  const getAllLastStep = () => {
    const isAllLastStep = items.every(
      (item: any) => getLastStep(item) === SELLBACK_STEP.SURVEY || getLastStep(item) === SELLBACK_STEP.NEXTITEM,
    );
    return isAllLastStep;
  };

  const getComplete = () => {
    const isCompleted = items.every((item: any) => getLastStep(item) === SELLBACK_STEP.NEXTITEM);
    return isCompleted;
  };

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.checkBoxLabelWrap}>
          <div className={classes.checkBoxLabel} style={{ marginRight: 26 }}>
            Yes
          </div>
          <div className={classes.checkBoxLabel}>No</div>
        </div>
        <ConditionCheckbox
          classes={classes}
          valueHandler={valueHandler}
          arrayQuestion={arrayQuestion}
          arrayNames={arrayNames}
          values={values}
        />
      </div>
      {getAllLastStep() || key === items.length - 1 ? (
        <Button
          onClick={() => {
            history.push(`${ROUTES.REVIEW}${queryString}`);
          }}
          className={classes.sellbackButton}
          variant="contained"
          disabled={disable || !getComplete()}
        >
          Review item details
        </Button>
      ) : (
        <Button
          onClick={() => setItemStep(SELLBACK_STEP.NEXTITEM)}
          className={classes.button}
          color="primary"
          variant="contained"
          disabled={disable}
        >
          Next
        </Button>
      )}
    </div>
  );
};

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    height: '100%',
  },
  contentContainer: {
    flex: 1,
  },
  checkBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  checkBoxLabel: {
    fontSize: '1.2em',
    fontWeight: 500,
    textTransform: 'uppercase' as 'uppercase',
    marginBottom: '20px',
    '@media (max-width:500px)': {
      fontSize: '1.1em',
    },
  },
  checkTextWrap: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '90%',
    marginRight: '20px',
  },
  checkBoxLabelWrap: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingRight: '20px',
    '@media (max-width:500px)': {
      paddingRight: '0px',
    },
  },
  checkBoxWrap: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '30%',
    paddingRight: '20px',
    '@media (max-width:500px)': {
      paddingRight: '0px',
    },
  },
  button: {
    width: '250px',
    margin: '30px 5px 0 !important',
    fontSize: '16px !important',
    fontWeight: '900 !important',
  },
  sellbackButton: {
    width: '260px',
    margin: '30px 5px 0 !important',
    fontSize: '16px !important',
    fontWeight: '900 !important',
    backgroundColor: '#57ABC3 !important',
    color: '#ffffff',
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
    },
  },
});

export default HOC(false)(withStyles(styles)(Condition));
