import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { Button } from '@mui/material';

interface IButton {
  value?: string;
  valueHandler?: Function | any;
  text: string;
  variant: string | any;
  classes: any;
  to?: string;
  color?: any;
  style?: any;
}

const OutlineButton: React.FC<IButton> = ({
  value,
  valueHandler,
  text,
  variant,
  classes,
  to = null,
  color = 'secondary',
  style,
}): JSX.Element => {
  return to === null ? (
    <Button
      onClick={() => valueHandler(value)}
      className={style === undefined ? classes.button : style}
      color={color}
      variant={variant}
    >
      {text}
    </Button>
  ) : (
    <Link
      to={to}
      style={{
        textDecoration: 'none',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
      }}
    >
      <Button className={classes.button} color="secondary" variant={variant}>
        {text}
      </Button>
    </Link>
  );
};

const styles = {
  button: {
    width: '80%',
    margin: '0 5px !important',
    fontSize: '.9em !important',
    fontWeight: '900 !important',
    '@media (max-width:1180px)': {
      width: '90%',
    },
  },
};

export default withStyles(styles)(OutlineButton);
