import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import { FormControl, Select } from '@mui/material';
import { casualTopText } from '../utils/filterData';

interface Data {
  id: number;
  name: string;
}

interface TextFieldProps {
  value?: any;
  fieldHandler: Function;
  data: any;
  name: string;
  label: string | any;
  className: string;
  required: boolean;
  helperText: string;
  errorHandler?: Function | any;
  error?: boolean;
  classes: any;
  disabled?: boolean;
}

const NewIcon = (props: any) => {
  return (
    <div {...props} className="arrow">
      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <svg width="24" height="18" x="7" y="11" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.66732 0.999999L8.72964 10.8681C8.76363 10.9095 8.80536 10.9428 8.85208 10.9655C8.89879 10.9882 8.94943 11 9.00065 11C9.05187 11 9.10251 10.9882 9.14922 10.9655C9.19594 10.9428 9.23767 10.9095 9.27167 10.8681L17.334 1"
            stroke="#999797"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </svg>
    </div>
  );
};

const DropDown: React.FC<TextFieldProps> = ({
  fieldHandler,
  data,
  name,
  label = 'Outlined',
  required,
  helperText,
  error,
  value,
  classes,
  disabled = false,
}) => {
  const isDataKeyValuePair: boolean = data.every((option: object) => typeof option === 'object');

  return (
    <Fragment>
      <FormControl className={classes.formControl} error={error} required={required}>
        <Select
          disabled={disabled}
          className={classes.root}
          classes={{
            select: classes.select,
          }}
          native
          value={value}
          onChange={(e) => fieldHandler(e)}
          color="secondary"
          sx={{ '& .MuiSelect-icon': { top: 10 } }}
          IconComponent={NewIcon}
          inputProps={{
            name: name,
            id: 'outlined-age-native-simple',
          }}
        >
          <option aria-label="Other" value="" />
          {isDataKeyValuePair
            ? (data ?? []).map((item: any, idx: number) => (
                <option key={item.sku} value={item.style_id} data-product={JSON.stringify(item)}>
                  {item.label}
                </option>
              ))
            : (data ?? []).map((item: any, idx: number) => (
                <option key={idx} value={item.name}>
                  {name === 'brand' ||
                  name === 'size' ||
                  name === 'location' ||
                  name === 'name' ||
                  name === 'size_two' ||
                  name === 'color' ||
                  name === 'condition' ||
                  name === 'category' ||
                  name === 'state' ||
                  name === 'payment_provider' ||
                  name === 'descriptor'
                    ? item === 'Casual Tops'
                      ? casualTopText
                      : `${item}`
                    : `${item.name}`}
                </option>
              ))}
        </Select>
      </FormControl>
    </Fragment>
  );
};

const styles = (theme: any) => ({
  formControl: {
    width: '370px',
    '@media (max-width:500px)': {
      width: '250px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d3d1d1',
      borderRadius: '0px',
      background: '#f9f9f9',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d3d1d1',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d3d1d1 !important',
    },
    '& .arrow': {
      position: 'absolute',
      right: '0',
      height: '48px',
      width: '50px',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#ececec',
      border: '1px solid #d3d1d1',
      zIndex: 5,
      '@media (max-width:500px)': {
        height: '38px',
      },
    },
  },
  root: {
    borderRadius: '15px',
    width: '100%',
    height: '50px',
    '&:before': {
      borderColor: 'none',
    },
    '@media (max-width:500px)': {
      height: '40px',
    },
  },
  select: {
    zIndex: 5,
    fontFamily: 'Dopis-Light !important',
    color: '#1C2229 !important',
    fontSize: '1.1em !important',
    fontWeight: '500 !important',
  },
  icon: {
    color: '#fff',
  },
  font: {
    color: `${theme.palette.secondary.main}`,
  },
});

export default withStyles(styles)(DropDown);
