import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import OutlineButton from '../../../components/OutlineButton';

const ReviewCard: React.FC<any> = ({
  classes,
  items,
  createHandler,
  editHandler,
  deleteHandler,
  type = null,
}): JSX.Element => {
  return (
    <Fragment>
      {items.map((item: any, idx: number) => (
        <div key={idx} className={classes.container}>
          <div key={idx} className={classes.itemContainer}>
            <div className={classes.itemTitle}>{`Item ${idx + 1}`}</div>
            <div className={classes.margin}>{item.brand}</div>
            {item.name && <div className={classes.margin}>{item.name}</div>}
            {item.suggested_name && <div className={classes.margin}>{item.suggested_name}</div>}
            <div className={classes.margin}>{item.category}</div>
            <div className={classes.margin}>{item.descriptor}</div>
            {item.colors.length > 0 && (
              <div className={classes.colorsContainer}>
                Colors:&nbsp;
                {item.colors.map((color: string, idx: number) => (
                  <div
                    key={idx}
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: `#${color.split('#')[1]}`,
                      borderRadius: '50%',
                      margin: '0 4px 5px 0',
                    }}
                  />
                ))}
              </div>
            )}
            {item.size && (
              <div className={classes.margin}>
                Size:&nbsp;<b className={classes.boldText}>{item.size}</b>
              </div>
            )}
            {item.condition && (
              <div className={classes.margin}>
                Condition:&nbsp;
                <b className={classes.boldText}>{item.condition}</b>
              </div>
            )}

            {type === null && (
              <div className={classes.buttonContainer}>
                <OutlineButton
                  valueHandler={() => editHandler(idx)}
                  variant={'contained'}
                  text={'Edit Item'}
                  color={'primary'}
                  style={classes.button}
                />
              </div>
            )}
            {items.length > 1 && (
              <div className={classes.deleteWrapper} onClick={() => deleteHandler(idx)}>
                <CloseIcon fontSize={'small'} />
              </div>
            )}
          </div>
        </div>
      ))}
      {items.length < 10 && (
        <div className={classes.container}>
          <div className={`${classes.itemContainer} ${classes.addItem}`}>
            <div className={classes.addTitle}>Add another</div>
            <div className={classes.addTitle}>item</div>
            <div className={classes.addButton} onClick={createHandler}>
              <AddIcon style={{ fontSize: 50 }} />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const styles = () => ({
  container: {
    width: '25%',
    padding: '10px',
    alignSelf: 'stretch',
    '@media (max-width:820px)': {
      width: '50%',
    },
  },
  itemContainer: {
    height: '100%',
    position: 'relative' as 'relative',
    display: 'flex',
    flexDirection: 'column' as 'column',
    backgroundColor: '#fff',
    border: '1px solid #dee1e6',
    padding: '10px',
  },
  itemTitle: {
    fontSize: '1.2em',
    fontWeight: 500,
    textAlign: 'center' as 'center',
    marginBottom: '5px',
  },
  colorsContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    width: '100%',
  },
  margin: {
    marginBottom: '5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap' as 'nowrap',
  },
  buttonContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    flexWrap: 'wrap' as 'wrap',
    alignItems: 'flex-end',
    marginTop: '10px',
  },
  button: {
    width: '80%',
    margin: '0 5px !important',
    fontSize: '.8em !important',
    fontWeight: '900 !important',
    padding: '0 16px !important',
  },
  boldText: {
    fontWeight: 'bold',
  },
  deleteWrapper: {
    cursor: 'pointer',
    position: 'absolute' as 'absolute',
    right: '-10px',
    top: '-10px',
    border: '1px solid #d1d1d1',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    background: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ff0000',
  },
  addItem: {
    boxShadow: 'none',
    border: '3px dashed  #949291',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '7px',
    minHeight: '250px',
    height: '100%',
  },
  addButton: {
    cursor: 'pointer',
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    background: '#949291',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#ffffff',
    marginTop: '10px',
  },
  addTitle: {
    textTransform: 'uppercase' as 'uppercase',
    fontFamily: 'Dopis-Regular',
    fontSize: '1.2em',
    fontWeight: 500,
    textAlign: 'center' as 'center',
    marginBottom: '5px',
  },
});
export default withStyles(styles)(ReviewCard);
