import * as ActionType from '../types/PostRequestsTypes';

const initialState: PostItemsState = {
  user: {
    first: '',
    last: '',
    email: '',
    phone_number: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    payment_provider: -1,
    payment_handle: '',
    terms: false,
    mode: '',
    location: '',
    date_time: '',
    giftCardOption: {},
    paymentMethods: [],
  },
  savedUser: {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
  },
  offer: {},
  post_offer: {},
  error_code: '',
  error_message: '',
  loading: false,
  savedLoading: false,
};

const reducer = (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case ActionType.POST_PRODUCTS_STARTED:
    case ActionType.TRANSITION_REQESTED_STARTED:
      return {
        ...state,
        error_code: '',
        error_message: '',
        loading: true,
      };
    case ActionType.POST_PRODUCTS_FULFILLED:
      return {
        ...state,
        loading: false,
        offer: action.payload,
        error_code: '',
        error_message: '',
      };
    case ActionType.POST_PRODUCTS_ERROR:
    case ActionType.TRANSITION_REQESTED_ERROR:
      return {
        ...state,
        loading: false,
        error_code: `${action.payload?.response?.status}`,
        error_message: action.payload?.response?.data?.error_message,
      };
    case ActionType.STORE_USER_VALUE:
      const { key, value } = action.payload;
      return {
        ...state,
        user: { ...state.user, [key]: value },
      };
    case ActionType.STORE_SAVED_USER_VALUE:
      const { key: savedKey, value: savedValue } = action.payload;
      return {
        ...state,
        savedUser: { ...state.savedUser, [savedKey]: savedValue },
      };
    case ActionType.POST_USER_STARTED:
      return {
        ...state,
        loading: true,
        error_code: '',
        error_message: '',
      };
    case ActionType.POST_USER_FULFILLED:
      return {
        ...state,
        loading: false,
        error_code: '',
        error_message: '',
        sell_back: action.payload,
      };
    case ActionType.POST_USER_ERROR:
    case ActionType.POST_SAVED_USER_ERROR:
      return {
        ...state,
        loading: false,
        error_code: `${action.payload?.response?.status}`,
        error_message: action.payload?.response?.data?.error_message,
      };
    case ActionType.POST_SAVED_USER_STARTED:
      return {
        ...state,
        error_message: '',
      };
    case ActionType.POST_SAVED_USER_FULFILLED:
      return {
        ...state,
        loading: false,
        savedUser: action.payload.entity,
      };
    case ActionType.TRANSITION_SAVED_STARTED:
      return {
        ...state,
        savedLoading: true,
        error_message: '',
      };
    case ActionType.TRANSITION_SAVED_ERROR:
      return {
        ...state,
        savedLoading: false,
        error_message: 'error',
      };
    case ActionType.TRANSITION_SAVED_FULFILLED:
      return {
        ...state,
        savedLoading: false,
        error_code: '',
        error_message: '',
        offer: {
          ...state.offer,
          entity: action.payload.curtailed_entity,
        },
      };
    case ActionType.TRANSITION_REQESTED_FULFILLED:
      return {
        ...state,
        loading: false,
        offer: {
          entity: action.payload.curtailed_entity,
          gift_card_options: action.payload.gift_card_options,
          payment_methods: action.payload.payment_methods,
        },
        error_code: '',
        error_message: '',
      };
    case ActionType.RESET_ERROR:
      return {
        ...state,
        error_code: '',
        error_message: '',
      };
    default:
      return state;
  }
};

export default reducer;
