export function toCamelCase(sentenceCase: any) {
  var out = '';
  sentenceCase.split(' ').forEach(function (el: any, idx: any) {
    var add = el.toLowerCase();
    out += add[0].toUpperCase() + add.slice(1) + ' ';
  });
  return out;
}

export const colorsArray = [
  {
    dark: 'Red:#B03A2E',
    medium: 'Red:#EB4E3D',
    light: 'Red:#F1948A',
  },
  {
    dark: 'Orange:#EF6C00',
    medium: 'Orange:#FF9800',
    light: 'Orange:#FFB74D',
  },
  {
    dark: 'Yellow:#FDD835',
    medium: 'Yellow:#FFEE58',
    light: 'Yellow:#FFF59D',
  },
  {
    dark: 'Green:#2E7D32',
    medium: 'Green:#4CAF50',
    light: 'Green:#81C784',
  },
  {
    dark: 'Teal:#00897B',
    medium: 'Teal:#26A69A',
    light: 'Teal:#80CBC4',
  },
  {
    dark: 'Blue:#0D47A1',
    medium: 'Blue:#2196F3',
    light: 'Blue:#90CAF9',
  },
  {
    dark: 'Blue-Grey:#455A64',
    medium: 'Blue-Grey:#607D8B',
    light: 'Blue-Grey:#90A4AE',
  },
  {
    dark: 'Navy:#04043F',
    medium: 'Navy:#000080',
    light: 'Navy:#1F1FD0',
  },
  {
    dark: 'Purple:#6A1B9A',
    medium: 'Purple:#9B59B6',
    light: 'Purple:#B39DDB',
  },
  {
    dark: 'Pink:#D81B60',
    medium: 'Pink:#F06292',
    light: 'Pink:#F48FB1',
  },
  {
    dark: 'Brown:#5D4037',
    medium: 'Brown:#795548',
    light: 'Brown:#A1887F',
  },
  {
    dark: 'Tan:#A38761',
    medium: 'Tan:#B9996E',
    light: 'Tan:#CBAD85',
  },
  {
    dark: 'Grey:#5D4037',
    medium: 'Grey:#9E9E9E',
    light: 'Grey:#E0E0E0',
  },
  {
    medium: 'Black:#000000',
  },
  {
    medium: 'White:#FAFAFA',
  },
];
