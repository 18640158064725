import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import reducers from './reducers';

const env = process.env.NODE_ENV === 'production';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = env
  ? createStore(reducers, compose(applyMiddleware(thunk), sentryReduxEnhancer))
  : createStore(reducers, applyMiddleware(thunk));

export default store;
