import React, { Fragment } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Header from './Header';
import DropOffMailIn from './steps/DropOffMailIn';
import ItemCount from './steps/ItemCount';
import ProvideItems from './steps/ProvideItems';
import DropOffSendIn from './steps/DropOffSendIn';
import DropOffSuccess from './steps/DropOffSuccess';
import Offer from './steps/Offer';
import Review from './steps/Review';
import Time from './steps/Time';
import UserInfo from './steps/UserInfo/UserInfo';
import WizardHOC from './WizardParentHOC';
import { CONFIG_DATA, ROUTES } from '../../utils/consts';
import { capitalizeFirstLetter } from '../../utils/functions';

interface IWizardParentContainer {
  classes: any;
}

const WizardParentContainer: React.FC<IWizardParentContainer> = ({ classes }): JSX.Element => {
  const environment = CONFIG_DATA && CONFIG_DATA.environment;
  const query = new URLSearchParams(window.location.search);
  const resumeSellbackRequestId = query.get('resume_s2r_id');

  return (
    <Fragment>
      <Router>
        {environment && environment !== 'production' && (
          <div className={classes.topBanner}>{capitalizeFirstLetter(environment)}</div>
        )}
        <div className={classes.headerBar}>
          <Header />
        </div>
        <Switch>
          <Route exact path={ROUTES.HOME}>
            {resumeSellbackRequestId ? <Offer /> : <DropOffSendIn />}
          </Route>
          <Route path={ROUTES.DROP_OFF_MAIL_IN}>
            <DropOffMailIn />
          </Route>
          <Route path={ROUTES.ITEM_COUNT}>
            <ItemCount />
          </Route>
          <Route path={ROUTES.PROVIDER_ITEMS}>
            <ProvideItems />
          </Route>
          <Route path={ROUTES.REVIEW}>
            <Review />
          </Route>
          <Route path={ROUTES.OFFER}>
            <Offer />
          </Route>
          <Route path={ROUTES.USER_INFO}>
            <UserInfo />
          </Route>
          <Route path={ROUTES.TIME}>
            <Time />
          </Route>
          <Route path={ROUTES.SUCCESS}>
            <DropOffSuccess />
          </Route>
        </Switch>
      </Router>
    </Fragment>
  );
};

const styles: any = {
  headerBar: {
    width: '100%',
    position: 'sticky',
    top: 0,
    backgroundColor: 'rgba(255,255,255, 1)',
    zIndex: '4',
    display: 'flex',
    alignItems: 'baseline',
  },
  listIcon: {
    color: '#fff',
    fontSize: '65px',
  },
  listIconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'fixed',
    zIndex: 600,
  },
  itemNumber: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f00000',
    borderRadius: '50%',
    height: '25px',
    width: '25px',
    color: '#fff',
    fontSize: '20px',
    zIndex: 10,
    position: 'absolute',
    top: '1px',
  },
  topBanner: {
    padding: '10px',
    backgroundColor: '#f00000',
    color: '#ffffff',
    textAlign: 'center',
    fontSize: '1.1em',
    fontWeight: 'bold',
  },
};
export default WizardHOC(withStyles(styles)(WizardParentContainer));
