import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import WizardParentContainer from './wizard/WizardParentContainer';
import Fallback from './Fallback';
import { CONFIG_DATA } from '../utils/consts';

if (CONFIG_DATA.hasOwnProperty('s') && CONFIG_DATA.s.hasOwnProperty('sentry_dsn')) {
  if (CONFIG_DATA.s.sentry_dsn !== '' && CONFIG_DATA.s.sentry_dsn !== null) {
    Sentry.init({
      dsn: CONFIG_DATA.s.sentry_dsn,
      integrations: [new Integrations.BrowserTracing()],
      normalizeDepth: 10,

      // REF: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/14
      ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
        'Extension context invalidated',
        "Identifier 'originalPrompt' has already been declared",
        'window.lintrk is not a function',
      ],
    });
  }
}

function App() {
  const [isLoadedMap, setLoadedMap] = useState(false);

  window.initMap = function () {
    setLoadedMap(true);
  };

  useEffect(() => {
    loadScript(`https://maps.googleapis.com/maps/api/js?key=${CONFIG_DATA.s.maps}&libraries=places&callback=initMap`);
  }, []);

  if (!isLoadedMap) {
    return <></>;
  }

  return (
    <div className="App">
      <Sentry.ErrorBoundary fallback={<Fallback />} showDialog={true}>
        <WizardParentContainer />
      </Sentry.ErrorBoundary>
    </div>
  );
}

function loadScript(url: string) {
  var index = window.document.getElementsByTagName('script')[0];
  var script = window.document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode?.insertBefore(script, index);
}

export default App;
