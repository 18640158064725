export const POST_SELLBACK_STARTED = 'POST_SELLBACK_STARTED';
export const POST_SELLBACK_FULFILLED = 'POST_SELLBACK_FULFILLED';
export const POST_SELLBACK_ERROR = 'POST_SELLBACK_ERROR';

export const STORE_BRAND_SPECIFIC_USER_VALUE = 'STORE_BRAND_SPECIFIC_USER_VALUE';

export const POST_BRAND_SPECIFIC_CUSTOMER_STARTED = 'POST_BRAND_SPECIFIC_CUSTOMER_STARTED';
export const POST_BRAND_SPECIFIC_CUSTOMER_FULFILLED = 'POST_BRAND_SPECIFIC_CUSTOMER_FULFILLED';
export const POST_BRAND_SPECIFIC_CUSTOMER_ERROR = 'POST_BRAND_SPECIFIC_CUSTOMER_ERROR';

export const POST_BRAND_SPECIFIC_CUSTOMER_ADDRESS_STARTED = 'POST_BRAND_SPECIFIC_CUSTOMER_ADDRESS_STARTED';
export const POST_BRAND_SPECIFIC_CUSTOMER_ADDRESS_FULFILLED = 'POST_BRAND_SPECIFIC_CUSTOMER_ADDRESS_FULFILLED';
export const POST_BRAND_SPECIFIC_CUSTOMER_ADDRESS_ERROR = 'POST_BRAND_SPECIFIC_CUSTOMER_ADDRESS_ERROR';

export const TRANSITION_BRAND_SPECIFIC_COMMITTED_STARTED = 'TRANSITION_BRAND_SPECIFIC_COMMITTED_STARTED';
export const TRANSITION_BRAND_SPECIFIC_COMMITTED_FULFILLED = 'TRANSITION_BRAND_SPECIFIC_COMMITTED_FULFILLED';
export const TRANSITION_BRAND_SPECIFIC_COMMITTED_ERROR = 'TRANSITION_BRAND_SPECIFIC_COMMITTED_ERROR';

export const RESET_BRAND_SPECIFIC_ERROR = 'RESET_BRAND_SPECIFIC_ERROR';
