import React, { useEffect } from 'react';
import { withStyles } from '@mui/styles';
import HOC from '../HOC';
import { validateEmail } from '../../../utils/functions';
import InputField from '../../../components/inputField';
import DropDown from '../../../components/DropDownSelectKeyValue';
import { States } from '../../wizard/steps/UserInfo/termsAndConditions';

const UserInfo: React.FC<any> = ({
  classes,
  disableHandle,
  post_brand_specific_items,
  brand_specific_update_items,
}): JSX.Element => {
  const { user } = post_brand_specific_items;
  const { first, last, email, phone_number, address1, address2, city, state, zip } = user;
  const { store_brand_specific_user_value } = brand_specific_update_items;

  const validateInput = (name: string, value: string | any) => {
    // Returns true if validation *fails*

    switch (name) {
      case 'phone_number':
        return value.length !== 10;

      case 'email':
        return value === '' || !validateEmail(value);

      case 'zip':
        return zip.length !== 5;

      // Our default validation just ensures that the value has at least one
      // character
      default:
        return value.length < 2;
    }
  };

  const valueHandler = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
    const { name, value } = e.target;
    let isValid = true;
    if (name === 'phone_number' && isNaN(Number(value))) {
      // not a valid number entered reject the byte
      isValid = false;
    }
    if (isValid) {
      store_brand_specific_user_value(name, value);
    }
  };

  const inputFields: any = [
    {
      value: user.first,
      error: validateInput('first', first),
      name: 'first',
      label: 'First name',
      helperText: 'Please enter your first name',
      helperTextError: 'Please enter your first name',
      type: 'text',
    },
    {
      value: user.last,
      error: validateInput('last', last),
      name: 'last',
      label: 'Last name',
      helperText: 'Please enter your last name',
      helperTextError: 'Please enter your last name',
      type: 'text',
    },
    {
      value: address1,
      error: validateInput('address1', address1),
      name: 'address1',
      label: 'Address',
      helperText: 'Please enter your address',
      helperTextError: 'Please enter a valid address',
    },
    {
      value: address2,
      error: false,
      name: 'address2',
      label: 'Address 2',
      helperText: 'Please enter your address',
      helperTextError: 'Please enter a valid address',
      required: false,
    },
    {
      value: city,
      error: validateInput('city', city),
      name: 'city',
      label: 'City',
      helperText: 'Please enter your city',
      helperTextError: 'Please enter a valid city',
    },
    {
      value: state,
      error: validateInput('state', state),
      name: 'state',
      label: 'State',
      helperText: 'Please enter your state',
      helperTextError: 'Please enter a valid state',
    },
    {
      value: zip,
      error: validateInput('zip', zip),
      name: 'zip',
      label: 'Zip Code',
      helperText: 'Please enter your zip',
      helperTextError: 'Please enter a valid zip',
    },
    {
      value: user.email,
      error: validateInput('email', user.email),
      name: 'email',
      label: 'Email',
      helperText: 'Please enter your email',
      helperTextError: 'Please enter a valid email',
      type: 'text',
    },
    {
      value: user.phone_number,
      error: validateInput('phone_number', user.phone_number),
      name: 'phone_number',
      label: 'Phone',
      helperText: 'Please enter a phone number',
      helperTextError: 'Phone number must be 10 digits',
      type: 'tel',
      inputProps: { maxlength: 10 },
    },
  ];

  useEffect(() => {
    disableHandle(
      !(
        !validateInput('first', first) &&
        !validateInput('last', last) &&
        !validateInput('email', email) &&
        !validateInput('address1', address1) &&
        !validateInput('city', city) &&
        !validateInput('state', state) &&
        !validateInput('zip', zip) &&
        !validateInput('phone_number', phone_number)
      ),
    );
  }, [disableHandle, first, last, zip, email, city, address1, state, phone_number]);

  return (
    <div className={classes.container}>
      <div className={classes.title}>We need a little more info</div>
      <div className={classes.contentContainer}>
        <div className={classes.inputWrapper}>
          {inputFields.slice(0, 5).map((field: any) => (
            <div key={field.name} className={classes.fieldWrapper}>
              <InputField
                value={field.value}
                error={field.error}
                fieldHandler={valueHandler}
                name={field.name}
                label={field.label}
                helperText={field.helperText}
                helperTextError={field.helperTextError}
                required={field.required}
                type={field.type}
                autoComplete={'off'}
                inputProps={field.hasOwnProperty('inputProps') ? field.inputProps : {}}
              />
            </div>
          ))}
        </div>
        <div className={classes.inputWrapper}>
          {inputFields.slice(5).map((field: any) =>
            field.name !== 'state' ? (
              <div key={field.name} className={classes.fieldWrapper}>
                <InputField
                  value={field.value}
                  error={field.error}
                  fieldHandler={valueHandler}
                  name={field.name}
                  label={field.label}
                  helperText={field.helperText}
                  helperTextError={field.helperTextError}
                  required={field.required}
                  type={field.type}
                  autoComplete={'off'}
                  inputProps={field.hasOwnProperty('inputProps') ? field.inputProps : {}}
                />
              </div>
            ) : field.name === 'state' ? (
              <div key={field.name} className={classes.fieldWrapper}>
                <DropDown
                  value={field.value}
                  fieldHandler={valueHandler}
                  data={States}
                  name={field.name}
                  label={field.label}
                  className={'input-field'}
                  required={true}
                  helperText={field.helperText}
                />
              </div>
            ) : (
              <></>
            ),
          )}
        </div>
      </div>
    </div>
  );
};

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    padding: '1rem 2rem',
  },
  title: {
    textTransform: 'uppercase' as 'uppercase',
    fontFamily: 'OldSansBlack',
    fontSize: '2.5em',
    fontWeight: 500,
    textAlign: 'center' as 'center',
  },
  contentContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row' as 'row',
    flexWrap: 'wrap' as 'wrap',
    margin: '40px 0',
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '50%',
    padding: '0 10px',
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  fieldWrapper: {
    margin: '5px 0',
  },
});

export default HOC()(withStyles(styles)(UserInfo));
