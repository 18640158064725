export const POST_PRODUCTS_STARTED = 'POST_PRODUCTS_STARTED';
export const POST_PRODUCTS_FULFILLED = 'POST_PRODUCTS_FULFILLED';
export const POST_PRODUCTS_ERROR = 'POST_PRODUCTS_ERROR';
export const STORE_USER_VALUE = 'STORE_USER_VALUE';
export const STORE_SAVED_USER_VALUE = 'STORE_SAVED_USER_VALUE';

export const POST_USER_STARTED = 'POST_USER_STARTED';
export const POST_USER_FULFILLED = 'POST_USER_FULFILLED';
export const POST_USER_ERROR = 'POST_USER_ERROR';

export const POST_SAVED_USER_STARTED = 'POST_SAVED_USER_STARTED';
export const POST_SAVED_USER_FULFILLED = 'POST_SAVED_USER_FULFILLED';
export const POST_SAVED_USER_ERROR = 'POST_SAVED_USER_ERROR';

export const TRANSITION_SAVED_STARTED = 'TRANSITION_SAVED_STARTED';
export const TRANSITION_SAVED_FULFILLED = 'TRANSITION_SAVED_FULFILLED';
export const TRANSITION_SAVED_ERROR = 'TRANSITION_SAVED_ERROR';

export const TRANSITION_REQESTED_STARTED = 'TRANSITION_REQESTED_STARTED';
export const TRANSITION_REQESTED_FULFILLED = 'TRANSITION_REQESTED_FULFILLED';
export const TRANSITION_REQESTED_ERROR = 'TRANSITION_REQESTED_ERROR';

export const RESET_ERROR = 'RESET_ERROR';
