import * as ActionType from '../types/GetRequestsTypes';
import { CONFIG_DATA } from '../../../utils/consts';
import { brandSpecificCategoriesFilter } from '../../../utils/filterData';

const initialState: GetBrandSpecificRequestState = {
  categories: [],
  brand_specific_products: [],
  loading: false,
  error_message: '',
  isHacking: false,
};

const brandRpecificReducer = (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case ActionType.GET_BRAND_SPECIFIC_CATEGORIES:
      const { brand } = action.payload;
      const categories = brandSpecificCategoriesFilter(CONFIG_DATA, brand);
      return {
        ...state,
        categories,
        isHacking: categories.length === 0,
      };
    case ActionType.GET_BRAND_SPECIFIC_PRODUCT_NAMES_REQUEST_STARTED:
      return {
        ...state,
        loading: true,
      };
    case ActionType.GET_BRAND_SPECIFIC_PRODUCT_NAMES_REQUEST_FULFILLED:
      return {
        ...state,
        loading: false,
        brand_specific_products: action.payload.products,
      };
    case ActionType.GET_BRAND_SPECIFIC_PRODUCT_NAMES_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error_message: action.payload,
      };
    default:
      return state;
  }
};

export default brandRpecificReducer;
