import React, { useCallback, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { Button } from '@mui/material';
import HOC from '../HOC';
import DropDown from '../../../components/DropDownSelect';
import { describeProduct } from '../../../utils/analytics';
import { CONFIG_DATA, SELLBACK_MODE, SELLBACK_STEP } from '../../../utils/consts';

const BrandCategoryDescriptor: React.FC<IChildComponent> = ({
  get_data,
  get_requests,
  store_update_items,
  current_item,
  classes,
  disableHandle,
  disable,
  store_items,
}): JSX.Element => {
  const { store_value, setItemStep } = store_update_items;
  const { get_brands, get_categories, get_descriptors } = get_requests;
  const { brands, categories, descriptors } = get_data;
  const { brand, category, descriptor } = current_item;
  const { path } = store_items;
  const isMailIn = path === SELLBACK_MODE.MAIL;

  const subdomain = CONFIG_DATA?.invocation_brand;
  const is_promote_brand = CONFIG_DATA?.branded_config?.promote_brand_in_product_selection;

  let context_brands;
  if (is_promote_brand == true && subdomain != null) {
    const promote_brand = CONFIG_DATA?.retail_brands_by_subdomain[subdomain];

    context_brands = brands?.filter((item: any) => item !== promote_brand?.label);
    context_brands?.unshift(promote_brand?.label);
  } else {
    context_brands = brands;
  }

  useEffect(() => {
    describeProduct();
  }, []);

  useEffect(() => {
    get_brands(isMailIn);
  }, [brands.length, get_brands, isMailIn]);

  useEffect(() => {
    if (current_item.brand) {
      get_categories(current_item.brand, isMailIn);
    }
  }, [current_item.brand, get_categories, isMailIn]);

  useEffect(() => {
    if (current_item.category && categories.length > 0) {
      get_descriptors(categories, current_item.category);
    }
  }, [categories, get_descriptors, current_item.category, categories.length]);

  const checkDisable = useCallback(() => {
    if (descriptors !== null) {
      return !(brand.length > 0 && category.length > 0 && descriptor.length > 0);
    } else {
      return !(brand.length > 0 && category.length > 0);
    }
  }, [brand, category, descriptor, descriptors]);

  useEffect(() => {
    disableHandle(checkDisable);
  }, [disableHandle, checkDisable]);

  const valueHandler = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
    const { name, value } = e.target;
    if (name === 'brand') {
      store_value({
        [name]: value,
        category: '',
        descriptor: '',
      });
      return;
    }
    if (name === 'category') {
      store_value({
        [name]: value,
        descriptor: '',
      });
      return;
    }
    return store_value({ [name]: value });
  };

  const categoryArray = get_data.categories.map((category: any) => category[0]);

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.itemContainer}>
          <div className="headline">BRAND</div>
          <DropDown
            value={current_item !== undefined ? current_item.brand : null}
            fieldHandler={valueHandler}
            data={context_brands}
            name={'brand'}
            label={'Brand'}
            className={'input-field'}
            required={true}
            helperText={'Select the brand of your item.'}
          />
        </div>
        {current_item.brand && categories && categories.length > 0 && (
          <div className={classes.itemContainer}>
            <div className="headline">CATEGORY</div>
            <DropDown
              value={current_item !== undefined ? current_item.category : null}
              fieldHandler={valueHandler}
              data={categoryArray}
              name={'category'}
              label={'Category'}
              className={'input-field'}
              required={true}
              helperText={'Select the type of you item.'}
            />
          </div>
        )}
        {current_item.category && descriptors !== null && descriptors.length > 0 && (
          <div className={classes.itemContainer}>
            <div className="headline">TYPE</div>
            <DropDown
              value={current_item !== undefined ? current_item.descriptor : null}
              fieldHandler={valueHandler}
              data={descriptors}
              name={'descriptor'}
              label={'Product descriptor'}
              className={'input-field'}
              required={true}
              helperText={'Select descriptor that describes your item.'}
            />
          </div>
        )}
      </div>
      <Button
        onClick={() => setItemStep(SELLBACK_STEP.PRODUCT)}
        className={classes.button}
        color="primary"
        variant="contained"
        disabled={disable}
      >
        Next
      </Button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    height: '100%',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    marginBottom: '30px',
    '& .headline': {
      marginBottom: '12px',
    },
  },
  button: {
    width: '250px',
    margin: '0 5px !important',
    fontSize: '16px !important',
    fontWeight: '900 !important',
  },
};

export default HOC(false)(withStyles(styles)(BrandCategoryDescriptor));
