import * as ActionType from '../types/StoreUpdateItemsTypes';
import { SELLBACK_MODE, SELLBACK_STEP } from '../../utils/consts';

const item = {
  brand: '',
  category: '',
  descriptor: '',
  color: [],
  colors: [],
  condition: '',
  condition_responses: {
    promotional_item: null,
    item_broken: null,
    tags_removed_unreadable: null,
    item_very_old: null,
  },
  product_type: 'name',
  name: '',
  suggested_name: '',
  suggested_style_id: '',
  size: '',
  size_two: '',
  sizes: [],
  sku: '',
  selected_product_internal_sku: '',
  selected_product_style_name: '',
  entered_product_style_id: '',
  entered_product_style_name: '',
};

const initialState: StoreUpdateItemsState = {
  key: 0,
  progressKey: 0,
  step: SELLBACK_STEP.INITIAL,
  items: [{ ...item }],
  path: SELLBACK_MODE.DROPOFF,
  itemCount: 1,
  offerPromotionToken: '',
};

const reducer = (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case ActionType.SET_PATH:
      return {
        ...state,
        path: action.payload,
      };
    case ActionType.CREATE_ITEM:
      return {
        ...state,
        items: [...state.items, { ...item }],
        key: state.items.length,
        progressKey: state.items.length,
        itemCount: state.items.length + 1,
        step: SELLBACK_STEP.INITIAL,
      };
    case ActionType.CREATE_MULTI_ITEM:
      const count = state.itemCount - state.items.length;
      const tempItems =
        count > 0
          ? new Array(count).fill('').map((temp) => {
              return JSON.parse(JSON.stringify(item));
            })
          : [];
      const newItems =
        state.items.length > state.itemCount
          ? [...state.items].slice(0, state.itemCount)
          : [...state.items, ...tempItems];
      return {
        ...state,
        items: newItems,
        key: state.key > newItems.length - 1 ? newItems.length - 1 : state.key,
        progressKey: state.progressKey > newItems.length - 1 ? newItems.length - 1 : state.progressKey,
      };
    case ActionType.STORE_VALUE:
      const data = action.payload;
      const keys = Object.keys(data);
      const isResetStep = keys.find((key) => ['brand', 'category', 'descriptor'].includes(key));
      const resetItem = {
        ...item,
        brand: state.items[state.key].brand,
        category: state.items[state.key].category,
        descriptor: state.items[state.key].descriptor,
        ...data,
      };
      return {
        ...state,
        items: [
          ...state.items.slice(0, state.key),
          Object.assign(
            state.items[state.key],
            isResetStep
              ? resetItem
              : {
                  ...state.items[state.key],
                  ...data,
                },
          ),
          ...state.items.slice(state.key + 1),
        ],
        step: isResetStep ? SELLBACK_STEP.INITIAL : state.step,
      };
    case ActionType.DELETE_ITEM:
      const newArray = deleteItemHandler([...state.items], action.payload);
      return {
        ...state,
        items: newArray,
        key: newArray.length === 0 ? 0 : newArray.length - 1,
        progressKey: newArray.length === 0 ? 0 : newArray.length - 1,
        itemCount: newArray.length,
      };
    case ActionType.SET_KEY:
      return {
        ...state,
        key: action.payload,
        step: SELLBACK_STEP.INITIAL,
      };
    case ActionType.SET_ITEM_COUNT:
      return {
        ...state,
        itemCount: action.payload,
      };
    case ActionType.SET_ITEM_STEP:
      const newKey = action.payload !== SELLBACK_STEP.NEXTITEM ? state.key : state.key + 1;
      const newProgressKey =
        action.payload === SELLBACK_STEP.NEXTITEM && state.key === state.progressKey
          ? state.progressKey + 1
          : state.progressKey;
      return {
        ...state,
        key: newKey,
        progressKey: newProgressKey,
        step: action.payload !== SELLBACK_STEP.NEXTITEM ? action.payload : SELLBACK_STEP.INITIAL,
      };
    case ActionType.SET_OFFER_PROMOTION_TOKEN:
      return {
        ...state,
        offerPromotionToken: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

const deleteItemHandler = (items: any, key: number) => {
  let arr = items.filter((item: any, idx: number) => idx !== key);
  if (arr.length === 0) arr = [{ ...item }];
  return arr;
};
