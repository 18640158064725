import React, { useEffect } from 'react';
import { withStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import HOC from '../HOC';
import { CircularProgress } from '@mui/material';
import { CONFIG_DATA } from '../../../utils/consts';

const Success: React.FC<{ classes: any; post_brand_specific_items: object | any }> = ({
  classes,
  post_brand_specific_items,
}) => {
  const { offer, loading, error_code, error_message } = post_brand_specific_items;

  const CLOSER_CONTENT = CONFIG_DATA.brand_specific_config && CONFIG_DATA.brand_specific_config.content.closer;
  const brandName = CONFIG_DATA?.retail_brand['label'];

  const renderErrorscreen = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0 15px',
      }}
    >
      <div className={classes.errorHeader}>Oops, something’s not right.</div>
    </div>
  );
  useEffect(() => {
    if (error_code || error_message) {
      console.error({ error_code, error_message: error_message ?? '' });
      Sentry.captureMessage(`Oops, something’s not right. ${error_message}`, Sentry.Severity.Error);
    }
  }, [error_code, error_message]);

  return (
    <div className={classes.container}>
      {loading ? (
        <CircularProgress color={'inherit'} style={{ color: '#262262' }} />
      ) : error_code || error_message ? (
        renderErrorscreen()
      ) : (
        <div className={classes.content}>
          <div className={`${classes.title} headlineFont`}>You did it!</div>
          <div className={classes.description}>
            <p>{CLOSER_CONTENT}</p>
            <p>
              Questions about the process?{' '}
              <a
                href={`mailto:brand-partner@outandbackoutdoor.com?subject=${encodeURIComponent(`${brandName} website question`)}`}
                style={{ textDecoration: 'none', color: 'blue' }}
              >
                Email our partners at Out&amp;Back
              </a>{' '}
              and they'd be happy to help out.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = (theme: any) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column' as 'column',
    padding: '2rem 1.3rem',
    '@media (max-width:500px)': {
      paddingTop: '1rem',
    },
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column' as 'column',
    marginBottom: '8rem',
    '@media (max-width:500px)': {
      marginBottom: '5rem',
      paddingTop: '1rem',
    },
  },
  title: {
    fontSize: '2em',
    fontWeight: 500,
    width: '100%',
    maxWidth: '500px',
    alignSelf: 'start',
    textAlign: 'center' as 'center',
    '@media (max-width:500px)': {
      fontSize: '1.7em',
      textAlign: 'left' as 'left',
    },
  },
  headerText: {
    fontSize: '1.1em',
    fontWeight: 500,
    textAlign: 'center' as 'center',
    margin: '5px 0',
    '@media (max-width:500px)': {
      fontSize: '1em',
    },
  },
  description: {
    maxWidth: '560px',
    marginTop: '20px',
    fontSize: '1.1em',
    fontWeight: 500,
    textAlign: 'left' as 'left',
    merginTop: '1rem',
    '@media (max-width:500px)': {
      fontSize: '1em',
    },
  },
  errorHeader: {
    color: '#000',
    fontSize: '35px',
    fontWeight: 400,
    alignSelf: 'center',
    margin: '30px 0',
  },
});
export default HOC()(withStyles(styles)(Success));
