import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import HOC from '../HOC';
import InputField from '../../../components/inputField';
import ReviewCard from './ReviewCard';
import { ROUTES } from '../../../utils/consts';

const Review: React.FC<IChildComponent> = ({
  store_items,
  classes,
  disableHandle,
  store_update_items,
}): JSX.Element => {
  const { items, offerPromotionToken } = store_items;
  const { setKey, delete_item, create_item, setOfferPromotionToken } = store_update_items;
  const history: any = useHistory();
  const { search: queryString } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(queryString);
    const token = query.get('offer_promotion_token');
    if (token) {
      setOfferPromotionToken(token);
    }
  }, [queryString]);

  useEffect(() => {
    disableHandle(items.length === 0);
  }, [disableHandle, items.length]);

  const deleteHandler = (key: number) => {
    if (items.length === 1) {
      delete_item(key);
    }
    delete_item(key);
  };

  const editHandler = (key: number) => {
    setKey(key);
    history.push(`${ROUTES.PROVIDER_ITEMS}${queryString}`);
  };

  const createHanlder = () => {
    create_item();
    history.push(`${ROUTES.PROVIDER_ITEMS}${queryString}`);
  };

  const valueHandler = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
    const { value } = e.target;
    setOfferPromotionToken(value);
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>Final Review</div>
      <div className={classes.subTitle}>(You're almost done...)</div>
      <div className={classes.cardContainer}>
        <ReviewCard
          items={items}
          deleteHandler={deleteHandler}
          createHandler={createHanlder}
          editHandler={editHandler}
        />
      </div>
      <div className={classes.promoWrapper}>
        <InputField
          value={offerPromotionToken}
          fieldHandler={valueHandler}
          name={'offerPromotionToken'}
          label={`OFFER CODE?`}
          description={'ENTER IT HERE'}
          labelWidth={150}
          required={false}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  cardContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row' as 'row',
    flexWrap: 'wrap' as 'wrap',
    alignItems: 'flex-start',
    margin: '20px 0',
    '@media (max-width:820px)': {
      width: '100%',
      overflow: 'scroll',
    },
  },
  promoWrapper: {
    width: '400px',
    marginBottom: '20px',
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  title: {
    textTransform: 'uppercase' as 'uppercase',
    fontFamily: 'OldSansBlack',
    fontSize: '2em',
    fontWeight: 500,
  },
  subTitle: {
    textTransform: 'uppercase' as 'uppercase',
    fontFamily: 'OldSansBlack',
    fontSize: '1.5em',
    fontWeight: 500,
  },
};

export default HOC()(withStyles(styles)(Review));
