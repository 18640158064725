import React from 'react';
import { withStyles } from '@mui/styles';
import { FormControlLabel, Radio } from '@mui/material';

interface RadioButtonProps {
  classes: any;
  color: any;
  selectedValue: any;
  value: any;
  fieldHandler: any;
  name: string;
  label?: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  classes,
  color = 'primary',
  label,
  value,
  name,
  selectedValue,
  fieldHandler,
}) => {
  return (
    <div className={classes.formControl}>
      {label ? (
        <FormControlLabel
          control={
            <Radio name={name} checked={selectedValue === value} color={color} value={value} onChange={fieldHandler} />
          }
          label={label}
        />
      ) : (
        <Radio name={name} checked={selectedValue === value} color={color} value={value} onChange={fieldHandler} />
      )}
    </div>
  );
};

const styles = () => ({
  formControl: {
    '& .MuiFormControlLabel-label': {
      fontFamily: "'Dopis-Light'",
      fontWeight: 500,
    },
    '@media (max-width:500px)': {
      '& .MuiFormControlLabel-label': {
        fontSize: '0.8rem',
      },
    },
  },
});

export default withStyles(styles)(RadioButton);
