import { Dispatch } from 'redux';
import * as ActionType from '../types/GetRequestsTypes';
import restCall from '../../utils/fetch-util';
import { casualTopText } from '../../utils/filterData';

export const get_brands = (isMailIn: boolean) => ({
  type: ActionType.GET_BRANDS,
  payload: { isMailIn: isMailIn },
});

export const get_categories = (brand: string, isMailIn: boolean) => ({
  type: ActionType.GET_CATEGORIES,
  payload: { brand: brand, isMailIn: isMailIn },
});

export const get_descriptors = (categories: object | any, value: string) => ({
  type: ActionType.GET_DESCRIPTORS,
  payload: { categories, value },
});

export const get_product_name = (brand: string, category: string, descriptor: string) => {
  if (descriptor === casualTopText) {
    descriptor = 'Casual Tops';
  }

  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/api/product?brand=${encodeURIComponent(brand)}&product_type=${encodeURIComponent(category)}&descriptor=${encodeURIComponent(descriptor)}`,
      method: 'get',
      startType: ActionType.GET_PRODUCT_NAMES_REQUEST_STARTED,
      successType: ActionType.GET_PRODUCT_NAMES_REQUEST_FULFILLED,
      errorType: ActionType.GET_PRODUCT_NAMES_REQUEST_ERROR,
      dispatch,
    });
  };
};

export const get_sizes = (object: object) => ({
  type: ActionType.GET_SIZES,
  payload: object,
});

export const get_condition_questions = (object: object) => ({
  type: ActionType.CONDITION_QUESTIONS_REQUEST_FULFILLED,
  payload: object,
});
