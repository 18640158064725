import React from 'react';
import Header from './wizard/Header';

const Fallback: React.FC = () => {
  return (
    <div>
      <div
        style={{
          width: '100%',
          height: 75,
          position: 'sticky',
          top: 0,
          backgroundColor: 'rgba(255,255,255, 1)',
          borderBottom: `1px solid #fff`,
          zIndex: '4',
          display: 'flex',
          alignItems: 'baseline',
        }}
      >
        <Header />
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          position: 'absolute',
          top: 0,
        }}
      >
        <h1
          style={{
            color: 'white',
          }}
        >
          An error occurred:
          <br />
          <br />
          Please{' '}
          <a href="/?errors=1" style={{ color: '#e81d24' }}>
            click here to start over
          </a>
        </h1>
      </div>
    </div>
  );
};

export default Fallback;
