import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import HOC from '../HOC';
import { useHistory, useLocation } from 'react-router-dom';
import { CONFIG_DATA, BRAND_SPECIFIC_ROUTES } from '../../../utils/consts';
import { Button, CircularProgress } from '@mui/material';
import CheckBoxButton from '../../../components/CheckBoxButton';
import { OFFER_MODE } from '../../../utils/consts';

interface Props {
  classes: any;
  store_brand_specific_items: any;
  post_brand_specific_items: any;
}

const Offer: React.FC<Props> = ({ classes, store_brand_specific_items, post_brand_specific_items }) => {
  const history: any = useHistory();
  const { search: queryString } = useLocation();

  const [isAgree, setAgree] = useState(false);

  const { addedItems } = store_brand_specific_items;
  const { offer, loading, error_code, error_message } = post_brand_specific_items;

  let subdomain = CONFIG_DATA.invocation_brand;
  let brandName = CONFIG_DATA?.retail_brand['label'];

  const renderErrorscreen = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0 15px',
      }}
    >
      <div className={classes.errorHeader}>Oops, something’s not right.</div>
    </div>
  );

  useEffect(() => {
    if (error_code || error_message) {
      console.error({ error_code, error_message: error_message ?? '' });
      Sentry.captureMessage(`Oops, something’s not right.${error_message}`, Sentry.Severity.Error);
    }
  }, [error_code, error_message]);

  return (
    <div className={classes.container}>
      {loading ? (
        <CircularProgress color={'inherit'} style={{ color: '#262262' }} />
      ) : !offer.entity || !offer?.entity?.offers[OFFER_MODE.DIRECT_GIFT_CARD] ? (
        renderErrorscreen()
      ) : (
        <div className={classes.content}>
          <div className={`${classes.title} headlineFont`}>Thanks for Recycling Your Gear</div>
          <div className={classes.giftCard}>
            <div className={classes.giftAmount}>
              <img
                style={{ marginTop: '1rem' }}
                width={100}
                height={100}
                src={`/images/logos/${subdomain}/${subdomain}.jpg`}
                alt={brandName + ' logo'}
              />
              <div style={{ fontSize: '2.5em', fontWeight: 900 }}>
                ${offer?.entity?.offers[OFFER_MODE.DIRECT_GIFT_CARD].amount}
              </div>
            </div>
          </div>
          <div>
            {addedItems.length && addedItems[0]['name'] && (
              <div>
                <div className="headlineFont" style={{ margin: '1rem 0' }}>
                  Submitted items
                </div>
                {addedItems?.map(
                  ({ category, name }: { category: string; name: string }, idx: number) =>
                    category &&
                    name && (
                      <p key={idx} style={{ fontSize: '.7em' }}>
                        {name} - {category}
                      </p>
                    ),
                )}
              </div>
            )}
            <p className="headlineFont" style={{ fontSize: '.7em' }}>
              Need to edit your submission? Click
              <span
                className="pointer"
                style={{ color: '#48BCD1', paddingLeft: 5 }}
                onClick={() => {
                  history.push(`${BRAND_SPECIFIC_ROUTES.PROVIDER_ITEMS}${queryString}`);
                }}
              >
                here
              </span>
            </p>
          </div>
          <div className={classes.agreeContainer}>
            <p style={{ textAlign: 'center', maxWidth: '500px' }}>
              You'll receive a $20 NEMO gift card for each item you send in for recycling. If the above information is
              correct, accept the offer and we'll create your shipping labels.
            </p>
            <div className={classes.agreeText}>
              I AGREE to Out&amp;Back's{' '}
              <a
                style={{ textDecoration: 'none', color: 'blue' }}
                href="https://outandbackoutdoor.com/pages/terms-of-use"
                target="_blank"
              >
                terms of use
              </a>{' '}
              and opt-in for customer communications
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckBoxButton style={{ padding: '0px' }} valueHandler={() => setAgree(true)} checked={isAgree} />
          </div>
          <div className={classes.FullButtonContainer}>
            <Button
              onClick={() => history.push(`${BRAND_SPECIFIC_ROUTES.BRAND_SPECIFIC_USER_INFO}${queryString}`)}
              className={classes.PathButton}
              variant="contained"
              style={{ background: '#48BCD1', color: '#fff', opacity: isAgree ? 1 : 0.6 }}
              disabled={!isAgree}
            >
              Accept
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = (theme: any) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column' as 'column',
    padding: '0 1.3rem',
    paddingTop: '1rem',
    '@media (max-width:500px)': {
      paddingTop: '1rem',
    },
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column' as 'column',
    '@media (max-width:500px)': {
      paddingTop: '1rem',
    },
  },
  title: {
    fontSize: '1.7em',
    fontWeight: 500,
    textAlign: 'left' as 'left',
    '@media (max-width:500px)': {
      fontSize: '1.3em',
    },
  },
  giftCard: {
    maxWidth: '560px',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as 'column',
  },
  giftAmount: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
  },
  itemsContainer: {
    display: 'flex',
    gap: '1rem',
    marginTop: '2rem',
  },
  agreeContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    margin: '15px 0',
  },
  agreeText: {
    maxWidth: '360px',
    fontSize: '1.1em',
    textAlign: 'center' as 'center',
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  FullButtonContainer: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  PathButton: {
    width: '15em',
    fontSize: '16px !important',
    fontWeight: '900 !important',
  },
  errorHeader: {
    color: '#000',
    fontSize: '35px',
    fontWeight: 400,
    alignSelf: 'center',
    margin: '30px 0',
  },
});

export default HOC()(withStyles(styles)(Offer));
