import React from 'react';
import { withStyles } from '@mui/styles';
import { Tabs, Tab, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import HOC from '../HOC';
import BrandCategoryDescriptor from './BrandCategoryDescriptor';
import Method from './Method';
import Size from './Size';
import Colors from './Colors';
import NewOrUsed from './NewOrUsed';
import Condition from './Condition';
import IsMobile from '../../../utils/IsMobile';
import { hasSizes } from '../../../utils/filterData';
import { SELLBACK_STEP } from '../../../utils/consts';
import { getLastStep, getObjectValues } from '../../../utils/functions';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={value === index ? 'tabPanel' : ''}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    className: 'tabButton',
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Progress: React.FC<IChildComponent> = ({
  classes,
  store_items,
  store_update_items,
  current_item,
  get_data,
}): JSX.Element => {
  const { key, progressKey, itemCount, step, items } = store_items;
  const { setKey, setItemStep } = store_update_items;
  const {
    category,
    brand,
    descriptor,
    product_type,
    name,
    suggested_name,
    suggested_style_id,
    size,
    colors,
    condition,
    condition_responses,
  } = current_item;
  const { isHacking } = get_data;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setItemStep(newValue);
  };

  const onItem = (value: number) => {
    if (value > progressKey) return;
    setKey(value);
  };

  const isCompleted = (index: number) => {
    if (items.length < index) return false;
    const isCompleted = getLastStep(items[index]) === SELLBACK_STEP.NEXTITEM;
    return isCompleted;
  };

  const handleAccordionChange = (panel: any) => (event: any, isExpanded: any) => {
    setItemStep(panel);
  };

  const getDisableStatus = (tempStep: any) => {
    var status = false;
    switch (tempStep) {
      case SELLBACK_STEP.INITIAL:
        status = !brand || !category || (category === 'Sleeping Pad' ? false : !descriptor);
        break;
      case SELLBACK_STEP.PRODUCT:
        if (product_type === 'name') {
          status = !suggested_name && !name;
        } else if (product_type === 'sku') {
          status = !suggested_style_id;
        }
        break;
      case SELLBACK_STEP.SIZE:
        status = !hasSizes({ category, descriptor }) || !size;
        break;
      case SELLBACK_STEP.COLOR:
        status = colors.length === 0;
        break;
      case SELLBACK_STEP.CONDITION:
        status = !condition;
        break;
      case SELLBACK_STEP.SURVEY:
        status = getObjectValues(condition_responses).filter((val: any) => val === 'yes' || val === 'no').length !== 4;
        break;
    }
    return step === tempStep ? false : status;
  };

  if (isHacking) {
    return (
      <div className={classes.container}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '0 15px',
          }}
        >
          <div className={classes.errorHeader}>Oops, something’s not right.</div>
        </div>
        <Button
          onClick={() => {
            window.location.href = '/';
          }}
          className={classes.backButton}
          color="primary"
          variant="contained"
        >
          Start Over
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.itemContainer}>
        {new Array(itemCount).fill(0).map((item, index) => (
          <div
            key={index}
            className={`${classes.itemWrapper} ${isCompleted(index) ? 'completed' : index === key ? 'active' : ''}`}
            onClick={() => onItem(index)}
          >
            <div className="divider">
              <div className="small"></div>
            </div>
            <div className="item">
              <span>Item</span>
              <span>{index + 1}</span>
            </div>
          </div>
        ))}
        {itemCount % 5 !== 0 && (
          <div className={classes.itemWrapper} style={{ height: '60px' }}>
            <div className="divider">
              <div className="small"></div>
            </div>
          </div>
        )}
      </div>
      {brand && descriptor && (
        <div className={classes.productContainer} style={{ background: isCompleted(key) ? '#57ABC3' : '#083741' }}>
          {`${brand} ${descriptor}`}
        </div>
      )}
      {IsMobile() ? (
        <div className={classes.accordionContainer}>
          <Accordion
            expanded={step === SELLBACK_STEP.INITIAL}
            onChange={handleAccordionChange(SELLBACK_STEP.INITIAL)}
            disabled={getDisableStatus(SELLBACK_STEP.INITIAL)}
          >
            <AccordionSummary>1. Basic Info</AccordionSummary>
            <AccordionDetails>
              <BrandCategoryDescriptor />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={step === SELLBACK_STEP.PRODUCT}
            onChange={handleAccordionChange(SELLBACK_STEP.PRODUCT)}
            disabled={getDisableStatus(SELLBACK_STEP.PRODUCT)}
          >
            <AccordionSummary>2. Product Name / Sku</AccordionSummary>
            <AccordionDetails>
              <Method />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={step === SELLBACK_STEP.SIZE}
            onChange={handleAccordionChange(SELLBACK_STEP.SIZE)}
            disabled={getDisableStatus(SELLBACK_STEP.SIZE)}
          >
            <AccordionSummary>3. Size</AccordionSummary>
            <AccordionDetails>{category && <Size />}</AccordionDetails>
          </Accordion>
          <Accordion
            expanded={step === SELLBACK_STEP.COLOR}
            onChange={handleAccordionChange(SELLBACK_STEP.COLOR)}
            disabled={getDisableStatus(SELLBACK_STEP.COLOR)}
          >
            <AccordionSummary>4. Color</AccordionSummary>
            <AccordionDetails>
              <Colors />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={step === SELLBACK_STEP.CONDITION}
            onChange={handleAccordionChange(SELLBACK_STEP.CONDITION)}
            disabled={getDisableStatus(SELLBACK_STEP.CONDITION)}
          >
            <AccordionSummary>5. Condition</AccordionSummary>
            <AccordionDetails>
              <NewOrUsed />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={step === SELLBACK_STEP.SURVEY}
            onChange={handleAccordionChange(SELLBACK_STEP.SURVEY)}
            disabled={getDisableStatus(SELLBACK_STEP.SURVEY)}
          >
            <AccordionSummary>6. Survey</AccordionSummary>
            <AccordionDetails>
              <Condition />
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <div className={classes.tabContainer}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={step}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab {...a11yProps(0)} label="1. Basic Info" disabled={getDisableStatus(SELLBACK_STEP.INITIAL)} />
            <Tab {...a11yProps(1)} label="2. Product Name / Sku" disabled={getDisableStatus(SELLBACK_STEP.PRODUCT)} />
            <Tab
              {...a11yProps(2)}
              label="3. Size"
              disabled={getDisableStatus(SELLBACK_STEP.SIZE)}
              style={{ textDecoration: !hasSizes({ category, descriptor }) ? 'line-through' : 'unset' }}
            />
            <Tab {...a11yProps(3)} label="4. Color" disabled={getDisableStatus(SELLBACK_STEP.COLOR)} />
            <Tab label="5. Condition" {...a11yProps(4)} disabled={getDisableStatus(SELLBACK_STEP.CONDITION)} />
            <Tab label="6. Survey" {...a11yProps(5)} disabled={getDisableStatus(SELLBACK_STEP.SURVEY)} />
          </Tabs>
          <TabPanel value={step} index={SELLBACK_STEP.INITIAL}>
            <BrandCategoryDescriptor />
          </TabPanel>
          <TabPanel value={step} index={SELLBACK_STEP.PRODUCT}>
            <Method />
          </TabPanel>
          <TabPanel value={step} index={SELLBACK_STEP.SIZE}>
            <Size />
          </TabPanel>
          <TabPanel value={step} index={SELLBACK_STEP.COLOR}>
            <Colors />
          </TabPanel>
          <TabPanel value={step} index={SELLBACK_STEP.CONDITION}>
            <NewOrUsed />
          </TabPanel>
          <TabPanel value={step} index={SELLBACK_STEP.SURVEY}>
            <Condition />
          </TabPanel>
        </div>
      )}
    </div>
  );
};

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '100%',
    height: '100%',
  },
  productContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.1em',
    color: '#ffffff',
    fontFamily: 'Dopis-Regular',
    fontWeight: 500,
    background: '#083741',
    padding: '6px',
  },
  tabContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    '& .tabButton': {
      fontFamily: 'OldSansBlack',
      alignItems: 'flex-start',
      textTransform: 'uppercase',
      fontSize: '1.2em',
      color: '#1C2229',
      fontWeight: 500,
      padding: '20px',
      borderBottom: '1px solid #dee1e6',
    },
    '& .tabPanel': {
      width: '100%',
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column' as 'column',
      padding: '40px 30px',
    },
    '& .Mui-selected': {
      color: '#1C2229 !important',
      background: '#f3f3f3 !important',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  accordionContainer: {
    '& .MuiAccordionSummary-content': {
      fontFamily: "'Dopis-Light'",
      textTransform: 'uppercase' as 'uppercase',
      fontWeight: 'bold',
    },
    '& .Mui-disabled': {
      background: '#f9f9f9',
    },
  },
  itemContainer: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
    alignItems: 'center',
    boxShadow: '1px 1px 0px 0.48px rgb(0 0 0 / 13%)',
  },
  itemWrapper: {
    width: '20%',
    display: 'flex',
    '& .item': {
      cursor: 'pointer',
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column' as 'column',
      justifyContent: 'center',
      padding: '10px',
      background: '#fcfbfb',
      borderBottom: '1px solid #dee1e6',
      fontSize: '1em',
    },
    '&.completed .item': {
      background: '#57ABC3',
      color: '#ffffff',
      fontWeight: 600,
      borderBottom: 'unset',
    },
    '&.active .item': {
      background: '#083741',
      color: '#ffffff',
      fontWeight: 600,
      borderBottom: 'unset',
    },
    '& .divider': {
      position: 'relative',
      width: '2px',
    },
    '& .small': {
      display: 'block',
      position: 'absolute',
      width: '2px',
      height: '100%',
      background: '#dee1e6',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '&.active .divider': {
      background: '#262262',
    },
    '&:nth-child(6).active .divider': {
      display: 'none',
    },
    '&.active .small': {
      background: '#ffffff',
      height: '45px',
    },
    '&.completed .divider': {
      background: '#1C2229',
    },
    '&.completed .small': {
      height: '45px',
      background: '#ffffff',
    },
    '&:nth-child(5) div': {
      borderRight: 'unset',
    },
    '&:nth-child(1) .divider': {
      display: 'none',
    },
  },
  errorHeader: {
    color: '#000',
    fontSize: '35px',
    fontWeight: 400,
    alignSelf: 'center',
    margin: '30px 0',
    textAlign: 'center' as 'center',
    '@media (max-width:500px)': {
      fontSize: '20px',
    },
  },
  backButton: {
    width: '15em',
    fontSize: '16px !important',
    fontWeight: '900 !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    marginTop: '30px !important',
    marginBottom: '30px !important',
  },
});

export default HOC()(withStyles(styles)(Progress));
