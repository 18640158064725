import React from 'react';
import { withStyles } from '@mui/styles';
import AddLocationIcon from '@mui/icons-material/AddLocation';

interface IMarker {
  lat: number;
  lng: number;
  text: string;
  address: string;
  valueHandler: Function;
  location: string;
  classes: any;
}

const Marker: React.FC<IMarker> = ({ classes, text, valueHandler, address, location }) => {
  return (
    <div onClick={() => valueHandler(address)}>
      <AddLocationIcon
        style={{
          color: location === address ? '#1C2229' : '#262262',
          fontSize: '35px',
        }}
      />
      <div className={location === address ? classes.unselectedMarker : classes.selectedMarker}>{text}</div>
    </div>
  );
};

const styles = {
  unselectedMarker: {
    width: '120px',
    border: '3px solid #1C2229',
    borderRadius: 20,
    backgroundColor: 'white',
    textAlign: 'center' as 'center',
    color: '#1C2229',
    fontSize: 16,
    fontWeight: 'bold',
    padding: 4,
  },
  selectedMarker: {
    width: '120px',
    border: '3px solid #262262',
    borderRadius: 20,
    backgroundColor: 'white',
    textAlign: 'center' as 'center',
    color: 'F00000',
    fontSize: 16,
    fontWeight: 'bold',
    padding: 4,
  },
};

export default withStyles(styles)(Marker);
