import 'regenerator-runtime/runtime';
import './index.css';

import App from './containers/App';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@mui/material/styles';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import theme from './mui-theme';
import BrandSpecificApp from './containers/brand_specific/BrandSpecificApp';
import { CONFIG_DATA } from './utils/consts';

declare module '@mui/material/styles' {
  interface Theme {
    overrides: object;
  }
  interface ThemeOptions {
    overrides: object;
  }
}

const Main = () => {
  let subdomain = CONFIG_DATA.invocation_brand;
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          {window.location.pathname === `/b/${subdomain}` ? <BrandSpecificApp /> : <App />}
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));
reportWebVitals();
