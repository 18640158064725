import * as ActionType from '../types/StoreUpdateItemsTypes';
import { addProduct } from '../../utils/analytics';

export const set_path = (path: string) => ({
  type: ActionType.SET_PATH,
  payload: path,
});

export const create_item = () => (
  addProduct(),
  {
    type: ActionType.CREATE_ITEM,
  }
);

export const createMultiItem = () => ({
  type: ActionType.CREATE_MULTI_ITEM,
});

export const store_value = (data: any) => ({
  type: ActionType.STORE_VALUE,
  payload: data,
});

export const delete_item = (number: number) => ({
  type: ActionType.DELETE_ITEM,
  payload: number,
});

export const setKey = (key: number) => ({
  type: ActionType.SET_KEY,
  payload: key,
});

export const setItemCount = (number: number) => ({
  type: ActionType.SET_ITEM_COUNT,
  payload: number,
});

export const setItemStep = (number: number) => ({
  type: ActionType.SET_ITEM_STEP,
  payload: number,
});

export const setOfferPromotionToken = (token: string) => ({
  type: ActionType.SET_OFFER_PROMOTION_TOKEN,
  payload: token,
});
