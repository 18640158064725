import React from 'react';
import { withStyles } from '@mui/styles';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import HOC from '../HOC';
import RadioButton from '../../../components/RadioButton';
import { SELLBACK_MODE } from '../../../utils/consts';

const DropOffMailIn: React.FC<IChildComponent> = ({
  classes,
  post_items,
  store_items,
  store_update_items,
}): JSX.Element => {
  const { user } = post_items;
  const { path } = store_items;
  const { set_path } = store_update_items;

  return (
    <div className={classes.container}>
      <div className="headline">Do you want to drop off your gear or mail it in?</div>
      {user.closest_location_address && (
        <div className={classes.rejectedText}>
          The nearest drop point is {Math.ceil(user.closest_location_miles)} miles away <br />
          {user.closest_location_address}
        </div>
      )}
      <div className={classes.checkboxWrapper}>
        <RadioButton
          name="dropoff"
          color="secondary"
          fieldHandler={(event: any) => set_path(event.target.value)}
          selectedValue={path}
          value={SELLBACK_MODE.DROPOFF}
          label={"I'll drop off my gear"}
        />
        <RadioButton
          name="mail"
          color="secondary"
          fieldHandler={(event: any) => set_path(event.target.value)}
          selectedValue={path}
          value={SELLBACK_MODE.MAIL}
          label={'I prefer to mail it in'}
        />
      </div>
      <div className="headline" style={{ textTransform: 'unset' }}>
        Drop off is better!
      </div>
      <div className={classes.dropoffContainer}>
        <div className={classes.dropoffWrapper}>
          <div className={classes.dropoffItem}>
            <CheckBoxIcon />
            <span className={classes.description}>Get paid as soon as you drop off</span>
          </div>
          <div className={classes.dropoffItem}>
            <CheckBoxIcon />
            <span className={classes.description}>30% bonus on gift card payments</span>
          </div>
          <div className={classes.dropoffItem}>
            <CheckBoxIcon />
            <span className={classes.description}>Sell skis / snowboards (no mail in)</span>
          </div>
        </div>
        <div className={classes.dropoffWrapper}>
          <div className={classes.dropoffItem}>
            <CloseIcon />
            <span className={classes.description}>No waiting in line at the post office</span>
          </div>
          <div className={classes.dropoffItem}>
            <CloseIcon />
            <span className={classes.description}>No hassling with packing up gear</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  rejectedText: {
    fontSize: '1.1em',
    fontWeight: 500,
    textAlign: 'center' as 'center',
    marginTop: '35px',
    lineHeight: '1.5em',
    '@media (max-width:500px)': {
      fontSize: '1em',
    },
  },
  checkboxWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '30px 0',
  },
  dropoffContainer: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
    justifyContent: 'center',
    margin: '20px 0',
  },
  dropoffWrapper: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    margin: '0 20px',
    '@media (max-width:500px)': {
      margin: '0',
    },
  },
  dropoffItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0',
    '& img': {
      width: '30px',
      height: '30px',
      '@media (max-width:500px)': {
        width: '20px',
        height: '20px',
      },
    },
  },
  description: {
    fontSize: '1.1em',
    fontWeight: 500,
    marginLeft: '25px',
    '@media (max-width:500px)': {
      fontSize: '1em',
      marginLeft: '15px',
      width: '230px',
    },
  },
});

export default HOC()(withStyles(styles)(DropOffMailIn));
