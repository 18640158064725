import React from 'react';
import { makeStyles } from '@mui/styles';
import { CONFIG_DATA } from '../../utils/consts';

const Header: React.FC = () => {
  let subdomain = CONFIG_DATA.invocation_brand;
  let brandName = CONFIG_DATA?.retail_brand['label'];

  const useStyles = makeStyles({
    container: {
      width: '100%',
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'white',
      padding: '20px',
      borderBottom: '2px solid #dde1e7',
    },
  });

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.container}>
        <img
          style={{
            height: '60px',
          }}
          alt={brandName + ' logo'}
          src={`/images/brand_specific/${subdomain}/logo.png`}
        />
      </div>
    </React.Fragment>
  );
};

export default Header;
