const outAndBackTheme = {
  palette: {
    primary: {
      main: '#1C2229',
      light: '#2a3142',
      dark: '#0d171d',
    },
    secondary: {
      main: '#57ABC3',
      light: '#66bdd6',
      dark: '#448a9e',
    },
    info: {
      main: '#515255',
      light: '#424242',
      dark: '#353535',
      contrastText: '#FFFFFF',
    },
  },
  overrides: {},
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
      },
    },
  },
};

export default outAndBackTheme;
