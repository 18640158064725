import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import { CONFIG_DATA } from '../../../../utils/consts';

interface IMap {
  valueHandler: Function;
  metro: string;
  location: string;
  location_id: string;
  get_data: object | any;
}

const Map: React.FC<IMap> = ({ metro, valueHandler, location, get_data, location_id }) => {
  const { invocationBrand } = get_data;

  // Determine all stores to plot

  let dropOffSite: any = {};
  let centerPoint: any = {};
  Object.keys(CONFIG_DATA.stores_by_subdomain).forEach((key) => {
    CONFIG_DATA.stores_by_subdomain[key].forEach((row: any) => {
      if (!dropOffSite.hasOwnProperty(row.retail_metro_id)) {
        dropOffSite[row.retail_metro_id] = [];
      }
      if (location_id === row.id) {
        centerPoint = { lat: row.latitde, lng: row.longitude };
      }
      let address2 = '';
      if (row.address2) {
        address2 = ' ' + row.address2;
      }

      dropOffSite[row.retail_metro_id].push({
        address: row.address1 + address2 + ' ' + row.city + ', ' + row.state + ' ' + row.zip,
        lat: row.latitde,
        long: row.longitude,
        text: row.branch_designation,
        id: row.id,
        brand: key,
      });
    });
  });

  return (
    <div style={{ height: '250px', width: '100%' }}>
      <GoogleMapReact bootstrapURLKeys={{ key: CONFIG_DATA.s.maps }} center={centerPoint} zoom={14}>
        {dropOffSite[metro].map((obj: any, key: number) => (
          <Marker
            key={key}
            lat={obj.lat}
            lng={obj.long}
            text={obj.text}
            address={obj.address}
            valueHandler={(address: string) => {
              valueHandler(address, obj.id, obj.brand);
            }}
            location={location}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
