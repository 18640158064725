import React, { useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { Button } from '@mui/material';
import HOC from '../HOC';
import ItemName from './ItemName';
import Sku from './Sku';
import RadioButton from '../../../components/RadioButton';
import { SELLBACK_STEP } from '../../../utils/consts';
import { hasSizes } from '../../../utils/filterData';

const Method: React.FC<IChildComponent> = ({
  store_update_items,
  classes,
  disable,
  disableHandle,
  current_item,
}): JSX.Element => {
  const { store_value, setItemStep } = store_update_items;
  const { suggested_name, name, suggested_style_id, category, descriptor, product_type } = current_item;

  useEffect(() => {
    if (product_type === 'name') {
      disableHandle(!(suggested_name !== '' || name !== ''));
    } else if (product_type === 'sku') {
      disableHandle(!(suggested_style_id !== ''));
    } else {
      disableHandle(false);
    }
  }, [suggested_name, name, suggested_style_id, disableHandle, product_type]);

  const onProductType = (event: any) => {
    store_value({ product_type: event.target.value });
  };

  return (
    <div className={classes.container}>
      <div className="headline">Do you know the name or sku of your item?</div>
      <div className={classes.radioContaner}>
        <RadioButton
          name="name"
          color="secondary"
          fieldHandler={onProductType}
          selectedValue={product_type}
          value={'name'}
          label={'I know the name'}
        />
        <RadioButton
          name="sku"
          color="secondary"
          fieldHandler={onProductType}
          selectedValue={product_type}
          value={'sku'}
          label={'I know the SKU'}
        />
        <RadioButton
          name="either"
          color="secondary"
          fieldHandler={onProductType}
          selectedValue={product_type}
          value={'either'}
          label={"I don't know either"}
        />
      </div>
      <div className={classes.contentContainer}>
        {product_type !== 'either' && <div className="headline">Select your item from the list or type its name</div>}
        {product_type === 'name' ? <ItemName /> : product_type === 'sku' ? <Sku /> : <></>}
      </div>
      <Button
        onClick={() => setItemStep(!hasSizes({ category, descriptor }) ? SELLBACK_STEP.COLOR : SELLBACK_STEP.SIZE)}
        className={classes.button}
        color="primary"
        variant="contained"
        disabled={disable}
      >
        Next
      </Button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    height: '100%',
    '& .headline': {
      marginBottom: '30px',
    },
  },
  radioContaner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '30px',
  },
  contentContainer: {
    flex: 1,
    marginBottom: '20px',
  },
  button: {
    width: '250px',
    margin: '0 5px !important',
    fontSize: '16px !important',
    fontWeight: '900 !important',
  },
};

export default HOC(false)(withStyles(styles)(Method));
