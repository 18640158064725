import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { makeStyles } from '@mui/styles';
import BottomNav from './steps/BottomNav';

import {
  setKey,
  store_brand_specific_value,
  create_brand_specific_item,
  delete_brand_specific_item,
} from '../../store/brandSpecificStore/actions/StoreItems';
import {
  get_brand_specific_categories,
  get_brand_specific_products,
} from '../../store/brandSpecificStore/actions/GetRequests';
import {
  create_online_sellback,
  transitionCommited,
  store_brand_specific_user_value,
  post_customer,
  post_customer_address,
} from '../../store/brandSpecificStore/actions/PostRequests';
import { reset_error } from '../../store/actions/PostRequests';
import { buildSellbackItems } from '../../utils/filterData';
import { useHistory, useLocation } from 'react-router-dom';
import { BRAND_SPECIFIC_ROUTES, CONFIG_DATA, OFFER_MODE } from '../../utils/consts';

const mapStateToProps = (state: any) => {
  const { get_brand_specific_data, store_brand_specific_items, post_brand_specific_items } = state;
  return { get_brand_specific_data, store_brand_specific_items, post_brand_specific_items };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      reset_error,
      setKey,
      get_brand_specific_categories,
      get_brand_specific_products,
      store_brand_specific_value,
      create_brand_specific_item,
      delete_brand_specific_item,
      create_online_sellback,
      transitionCommited,
      store_brand_specific_user_value,
      post_customer,
      post_customer_address,
    },
    dispatch,
  );

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const BrandSpecificHoc =
  (isWrapper = true) =>
  (OriginalComponent: any) => {
    const NewComponent: React.FC<Props> = ({ ...Props }) => {
      const {
        setKey,
        reset_error,
        get_brand_specific_categories,
        get_brand_specific_products,
        store_brand_specific_items,
        store_brand_specific_value,
        get_brand_specific_data,
        create_brand_specific_item,
        delete_brand_specific_item,
        create_online_sellback,
        post_brand_specific_items,
        store_brand_specific_user_value,
        transitionCommited,
        post_customer,
        post_customer_address,
      } = Props;

      const brand_specific_get_requests = {
        get_brand_specific_categories,
        get_brand_specific_products,
      };

      const brand_specific_update_items = {
        store_brand_specific_value,
        create_brand_specific_item,
        delete_brand_specific_item,
        store_brand_specific_user_value,
        setKey,
      };

      const brand_specific_post_items = {
        post_customer,
        post_customer_address,
        transitionCommited,
        create_online_sellback,
        post_brand_specific_items,
      };

      const current_brand_specific_items = store_brand_specific_items.items[store_brand_specific_items.key];
      const [disable, disableHandler] = useState<boolean>(false);
      const history: any = useHistory();
      const { search: queryString } = useLocation();

      let brandName = CONFIG_DATA?.retail_brand['label'];

      const postUserInfo = async () => {
        const { first, last, email, phone_number, address1, address2, city, state, zip } =
          post_brand_specific_items.user;

        const id = post_brand_specific_items.offer.entity.id;

        const initialArguments = {
          first: first,
          last: last,
          email: email,
          phone_number: phone_number === '' ? null : phone_number,
          address1: address1,
          address2: address2,
          city: city,
          state: state,
          zip: zip,
          payment_handle: email,
          selected_giftcard_label: brandName,
          payment_provider: 'gift_card',
          selected_offer_mode: OFFER_MODE.DIRECT_GIFT_CARD,
        };

        const customerIdInitialArguments = {
          first_name: first,
          last_name: last,
          email: email,
        };

        const customerAddressInitialArguments = {
          address1: address1,
          address2: address2,
          city: city,
          state: state,
          zip: zip,
        };

        const customer_details: any = await post_customer(customerIdInitialArguments);

        await Promise.all([
          post_customer_address(customerAddressInitialArguments, customer_details.entity.id),
          transitionCommited(id, { arguments: initialArguments }),
        ]);

        history.push(`${BRAND_SPECIFIC_ROUTES.BRAND_SPECIFIC_SUCCESS}${queryString}`);
      };

      const offerHandler = () => {
        const { addedItems, items } = store_brand_specific_items;

        const params: any = {
          items: buildSellbackItems(items),
        };

        create_online_sellback('post', params);
      };

      const useStyles = makeStyles({
        container: {
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '30px',
          background: '#f9f9f9',
          '@media (max-width:500px)': {
            padding: '30px 20px',
          },
        },
        containerBackgroundImage: {
          width: '100%',
          maxWidth: '60rem',
          display: 'flex',
          flexDirection: 'column' as 'column',
          justifyContent: 'center' as 'center',
          border: '1px solid #dee1e6',
          background: '#ffffff',
          '@media (max-width:820px)': {
            alignItems: 'center' as 'center',
          },
          '@media (max-width:500px)': {
            width: '100%',
            height: '100%',
            maxHeight: 'unset',
          },
        },
        childContainerFullWidth: {
          width: '100%',
          height: '100%',
          display: 'flex',
          backgroundColor: 'rgba(255,255,255, 0.75)',
          borderRadius: '10px',
          flexDirection: 'column' as 'column',
          alignItems: 'center' as 'center',
          justifyContent: 'center' as 'center',
          '@media (max-width:820px)': {
            height: '100%',
          },
        },
      });

      const classes = useStyles();

      const original = (
        <OriginalComponent
          brand_specific_update_items={brand_specific_update_items}
          store_brand_specific_items={store_brand_specific_items}
          store_brand_specific_user_value={store_brand_specific_user_value}
          post_brand_specific_items={post_brand_specific_items}
          get_brand_specific_data={get_brand_specific_data}
          current_brand_specific_items={current_brand_specific_items}
          brand_specific_get_requests={brand_specific_get_requests}
          brand_specific_post_items={brand_specific_post_items}
          reset_error={reset_error}
          disableHandle={disableHandler}
          disable={disable}
        />
      );

      if (!isWrapper) {
        return original;
      }

      return (
        <Fragment>
          <div className={classes.container}>
            <div className={classes.containerBackgroundImage}>
              <div className={classes.childContainerFullWidth}>
                {original}
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '2rem 0',
                  }}
                >
                  <BottomNav
                    disable={disable}
                    setKey={setKey}
                    offerHandler={offerHandler}
                    store_brand_specific_items={store_brand_specific_items}
                    current_brand_specific_items={current_brand_specific_items}
                    post_brand_specific_items={post_brand_specific_items}
                    brand_specific_update_items={brand_specific_update_items}
                    postUserInfo={postUserInfo}
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    };
    return NewComponent;
  };

const composeWrapper = (arg1?: any) => compose(connect(mapStateToProps, mapDispatchToProps), BrandSpecificHoc(arg1));

export default composeWrapper;
