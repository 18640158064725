import React from 'react';
import Banner from '../../utils/Banner';
import { CONFIG_DATA } from '../../utils/consts';

const Header: React.FC = () => {
  let brandName = '';
  if (CONFIG_DATA.hasOwnProperty('retail_brand')) {
    brandName = CONFIG_DATA['retail_brand']['label'];
  }
  const isSpecialBrand =
    CONFIG_DATA.invocation_brand !== null &&
    CONFIG_DATA.stores_by_subdomain.hasOwnProperty(CONFIG_DATA.invocation_brand);

  return (
    <React.Fragment>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          backgroundColor: 'white',
          padding: '20px',
          borderBottom: '2px solid #dde1e7',
        }}
      >
        <a target="_blank" href="/">
          <img
            style={{
              height: '45px',
            }}
            alt={brandName + ' logo'}
            src={Banner(CONFIG_DATA?.retail_brand?.identifier)}
          />
        </a>
      </div>
    </React.Fragment>
  );
};

export default Header;
