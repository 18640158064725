import React, { useCallback, useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import HOC from '../../HOC';
import InputField from '../../../../components/inputField';
import DropDown from '../../../../components/DropDownSelectKeyValue';
import { States } from './termsAndConditions';
import { validateEmail, validateVenmo } from '../../../../utils/functions';
import { CONFIG_DATA } from '../../../../utils/consts';

const UserInfo: React.FC<IChildComponent> = ({
  classes,
  disableHandle,
  post_items,
  store_items,
  store_user_value,
}): JSX.Element => {
  const { user } = post_items;
  const {
    first,
    last,
    email,
    phone_number,
    address1,
    address2,
    city,
    state,
    zip,
    payment_provider,
    payment_handle,
    giftCardOption,
    paymentMethods,
  } = user;
  const [giftMethod, setGiftMethod] = useState(-1);
  const convertedPaymentMethods = store_items.path !== 'drop_off' ? paymentMethods : CONFIG_DATA.payment_methods;

  useEffect(() => {
    let paymentProvider = -1;
    if (giftCardOption.label) {
      paymentProvider = convertedPaymentMethods.findIndex(
        (method: any) => method.giftcard_label === giftCardOption.label,
      );
    }
    store_user_value(['payment_provider'], paymentProvider);
    setGiftMethod(paymentProvider);
  }, [giftCardOption]);

  const valueHandler = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
    const { name, value } = e.target;
    let isValid = true;
    if (name === 'phone_number' && isNaN(Number(value))) {
      // not a valid number entered reject the byte
      isValid = false;
    }
    if (isValid) {
      store_user_value(name, value);
    }
  };

  const inputFields: any = [
    {
      value: user.first,
      error: user.first !== '' && user.first.length < 2,
      name: 'first',
      label: 'First name',
      helperText: 'Please enter your first name',
      helperTextError: 'Please enter your first name',
      type: 'text',
    },
    {
      value: user.last,
      error: user.last !== '' && user.last.length < 2,
      name: 'last',
      label: 'Last name',
      helperText: 'Please enter your last name',
      helperTextError: 'Please enter your last name',
      type: 'text',
    },
    {
      value: address1,
      error: address1 !== '' && address1.length < 10,
      name: 'address1',
      label: 'Address',
      helperText: 'Please enter your address',
      helperTextError: 'Please enter a valid address',
    },
    {
      value: address2,
      error: false,
      name: 'address2',
      label: 'Address 2',
      helperText: 'Please enter your address',
      helperTextError: 'Please enter a valid address',
      required: false,
    },
    {
      value: city,
      error: city !== '' && city.length < 2,
      name: 'city',
      label: 'City',
      helperText: 'Please enter your city',
      helperTextError: 'Please enter a valid city',
    },
    {
      value: state,
      error: state !== '' && state.length < 2,
      name: 'state',
      label: 'State',
      helperText: 'Please enter your state',
      helperTextError: 'Please enter a valid state',
    },
    {
      value: zip,
      error: zip !== '' && zip.length !== 5,
      name: 'zip',
      label: 'Zip Code',
      helperText: 'Please enter your zip',
      helperTextError: 'Please enter a valid zip',
    },
    {
      value: user.email,
      error: user.email !== '' && !validateEmail(user.email),
      name: 'email',
      label: 'Email',
      helperText: 'Please enter your email',
      helperTextError: 'Please enter a valid email',
      type: 'text',
    },
    {
      value: user.phone_number,
      error: user.phone_number !== '' && user.phone_number.length !== 10,
      name: 'phone_number',
      label: 'Phone',
      helperText: 'Please enter a phone number',
      helperTextError: 'Phone number must be 10 digits',
      type: 'tel',
      inputProps: { maxlength: 10 },
    },
    {
      value: payment_provider,
      error: payment_provider === -1,
      name: 'payment_provider',
      label: 'Payment Provider',
      helperTextError: 'Please enter a valid payment',
    },
  ];

  const submitHandler = useCallback(() => {
    let disable = false;
    const findPayment = convertedPaymentMethods[payment_provider];
    const allowedPhone = findPayment?.allowed_recipient_types.includes('PHONE');
    if (!findPayment) return disable;

    if (allowedPhone && validateVenmo(payment_handle)) {
      disable = true;
    }
    if (!allowedPhone && validateEmail(payment_handle)) {
      disable = true;
    }
    return disable;
  }, [payment_provider, payment_handle]);

  useEffect(() => {
    disableHandle(
      !(
        first !== '' &&
        last !== '' &&
        email !== '' &&
        validateEmail(email) &&
        phone_number !== '' &&
        phone_number.length === 10 &&
        !isNaN(Number(phone_number)) &&
        address1 !== '' &&
        city !== '' &&
        state !== '' &&
        zip !== '' &&
        submitHandler()
      ),
    );
  }, [disableHandle, submitHandler, first, last, email, phone_number, address1, city, state, zip]);

  const paymentValueHandler = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
    const { value } = e.target;
    store_user_value('payment_provider', value);

    //Prepopulate the email, phone
    let paymentValue = '';
    const findPayment = convertedPaymentMethods[value];
    const allowedPhone = findPayment?.allowed_recipient_types.includes('PHONE');
    const allowedEmail = findPayment?.allowed_recipient_types.includes('EMAIL');
    if (allowedPhone) {
      paymentValue = phone_number;
    } else if (allowedEmail) {
      paymentValue = email;
    } else if (findPayment) {
      Sentry.captureMessage(
        `This type is not supported. - ${findPayment?.allowed_recipient_types.join(',')}`,
        Sentry.Severity.Error,
      );
    }
    store_user_value('payment_handle', paymentValue);
  };

  const renderPaymentHandle = () => {
    const findPayment = convertedPaymentMethods[payment_provider];
    const allowedPhone = findPayment?.allowed_recipient_types.includes('PHONE');
    if (!findPayment) return null;
    return (
      <div className={classes.fieldWrapper}>
        <InputField
          value={payment_handle}
          error={
            payment_handle !== '' && (allowedPhone ? !validateVenmo(payment_handle) : !validateEmail(payment_handle))
          }
          fieldHandler={valueHandler}
          name={'payment_handle'}
          label={allowedPhone ? 'Phone' : 'Email'}
          placeholder={`${allowedPhone ? '123-456-7890' : 'email@domain.com'}`}
          helperText={`Please enter your ${allowedPhone ? 'phone number' : 'email'}`}
          helperTextError={`Please enter a valid ${allowedPhone ? 'phone number' : 'email'}`}
          isBold={allowedPhone ? payment_handle === phone_number : payment_handle === email}
        />
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>Tell us about you</div>
      <div className={classes.contentContainer}>
        <div className={classes.inputWrapper}>
          {inputFields.slice(0, 5).map((field: any) => (
            <div key={field.name} className={classes.fieldWrapper}>
              <InputField
                value={field.value}
                error={field.error}
                fieldHandler={valueHandler}
                name={field.name}
                label={field.label}
                helperText={field.helperText}
                helperTextError={field.helperTextError}
                required={field.required}
                type={field.type}
                autoComplete={'off'}
                inputProps={field.hasOwnProperty('inputProps') ? field.inputProps : {}}
              />
            </div>
          ))}
        </div>
        <div className={classes.inputWrapper}>
          {inputFields.slice(5).map((field: any) =>
            field.name !== 'state' && field.name !== 'payment_provider' ? (
              <div key={field.name} className={classes.fieldWrapper}>
                <InputField
                  value={field.value}
                  error={field.error}
                  fieldHandler={valueHandler}
                  name={field.name}
                  label={field.label}
                  helperText={field.helperText}
                  helperTextError={field.helperTextError}
                  required={field.required}
                  type={field.type}
                  autoComplete={'off'}
                  inputProps={field.hasOwnProperty('inputProps') ? field.inputProps : {}}
                />
              </div>
            ) : field.name === 'state' ? (
              <div key={field.name} className={classes.fieldWrapper}>
                <DropDown
                  value={field.value}
                  fieldHandler={valueHandler}
                  data={States}
                  name={field.name}
                  label={field.label}
                  className={'input-field'}
                  required={true}
                  helperText={field.helperText}
                />
              </div>
            ) : (
              <div key={field.name} className={classes.fieldWrapper}>
                <DropDown
                  value={field.value}
                  fieldHandler={(e: any) => paymentValueHandler(e)}
                  data={convertedPaymentMethods.map((method: any, index: number) => ({
                    value: index,
                    name:
                      store_items.path !== 'drop_off'
                        ? `($${Number(method.offer_amount).toFixed(2)}) ${method.label}`
                        : `${method.label}`,
                  }))}
                  name={field.name}
                  label={field.label}
                  className={'input-field'}
                  required={true}
                  helperText={giftMethod !== -1 ? '' : field.helperText}
                />
              </div>
            ),
          )}
          {payment_provider !== -1 && renderPaymentHandle()}
        </div>
      </div>
    </div>
  );
};

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  title: {
    textTransform: 'uppercase' as 'uppercase',
    fontFamily: 'OldSansBlack',
    fontSize: '2.5em',
    fontWeight: 500,
    textAlign: 'center' as 'center',
  },
  contentContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row' as 'row',
    flexWrap: 'wrap' as 'wrap',
    margin: '40px 0',
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '50%',
    padding: '0 10px',
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  fieldWrapper: {
    margin: '5px 0',
  },
});

export default HOC()(withStyles(styles)(UserInfo));
