import { Dispatch } from 'redux';
import restCall from '../../../utils/fetch-util';
import * as ActionType from '../types/PostRequestsTypes';
import { SELLBACK2_REQUEST_STATE } from '../../../utils/consts';

export const create_online_sellback = (method: string = 'post', data: any) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: '/api/2/sellback/request/online',
      method: method,
      data: data,
      startType: ActionType.POST_SELLBACK_STARTED,
      successType: ActionType.POST_SELLBACK_FULFILLED,
      errorType: ActionType.POST_SELLBACK_ERROR,
      dispatch,
    });
  };
};

export const store_brand_specific_user_value = (key: string, value: any) => ({
  type: ActionType.STORE_BRAND_SPECIFIC_USER_VALUE,
  payload: { key, value },
});

export const post_customer = (data: any) => {
  return async function (dispatch: Dispatch) {
    const response = await restCall({
      url: `/api/customer`,
      method: 'post',
      data: data,
      startType: ActionType.POST_BRAND_SPECIFIC_CUSTOMER_STARTED,
      successType: ActionType.POST_BRAND_SPECIFIC_CUSTOMER_FULFILLED,
      errorType: ActionType.POST_BRAND_SPECIFIC_CUSTOMER_ERROR,
      dispatch,
    });
    return response;
  };
};

export const post_customer_address = (data: any, customer_id: string, address_type: string = 'shipping') => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/api/customer_address/${customer_id}/${address_type}`,
      method: 'post',
      data: data,
      startType: ActionType.POST_BRAND_SPECIFIC_CUSTOMER_ADDRESS_STARTED,
      successType: ActionType.POST_BRAND_SPECIFIC_CUSTOMER_ADDRESS_FULFILLED,
      errorType: ActionType.POST_BRAND_SPECIFIC_CUSTOMER_ADDRESS_ERROR,
      dispatch,
    });
  };
};

export const transitionCommited = (id: string, data: any) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/api/2/sellback/request/${id}/${SELLBACK2_REQUEST_STATE.COMMITTED}`,
      method: 'put',
      data: data,
      startType: ActionType.TRANSITION_BRAND_SPECIFIC_COMMITTED_STARTED,
      successType: ActionType.TRANSITION_BRAND_SPECIFIC_COMMITTED_FULFILLED,
      errorType: ActionType.TRANSITION_BRAND_SPECIFIC_COMMITTED_ERROR,
      dispatch,
    });
  };
};

export const reset_error = () => ({
  type: ActionType.RESET_BRAND_SPECIFIC_ERROR,
});
