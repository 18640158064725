import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { FormHelperText, Typography } from '@mui/material';
import HOC from '../HOC';
import InputField from '../../../components/inputField';
import ModalComponent from '../../../components/Modal';
import SkuImage from '../../../assets/demo_sku.jpg';

const Sku: React.FC<IChildComponent> = ({ get_data, store_update_items, current_item, classes }): JSX.Element => {
  const { store_value } = store_update_items;
  const { sku_tag } = get_data;
  const { brand, category, suggested_style_id } = current_item;

  const [toggle, setToggle] = useState<boolean>(false);

  const checkSkuHandler = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
    const { value } = e.target;
    store_value({
      suggested_style_id: value,
      entered_product_style_id: value,
    });
  };

  const tag = sku_tag.filter((obj: any) => obj.brand === brand);

  const defaultText = `Style and SKU #’s vary by Brand, yet typically can be found on an item’s inside tag.
    Common tag locations include back collar, side seam or even in a sleeve/pant leg. Style and SKU #’s
     are a series of numbers and often begin with a letter. TIP: sequences beginning with CA or
     RN do not represent the style or SKU #.`;
  const defaultSku = tag.length > 0 && tag[0].complete === 'DONE';

  const modalContent = () => (
    <div className={classes.paper}>
      <Typography className={classes.typography} color={'inherit'} align={'left'} component="h2">
        SKU
      </Typography>
      <Typography className={classes.typography} color={'inherit'} align={'left'} component="h2">
        {brand} {category.charAt(0).toUpperCase() + category.slice(1)}
      </Typography>

      <Typography className={classes.typographyParagraph} color={'inherit'} align={'left'} component="h2">
        {defaultSku ? tag[0].text : defaultText}
      </Typography>

      <img
        style={{ width: '200px', display: 'flex', margin: '0 auto' }}
        src={defaultSku ? require(`../../../assets/tags/${tag[0].brand}.jpg`).default : SkuImage}
        alt="brand_sku"
      />
    </div>
  );

  return (
    <div className={classes.container}>
      <InputField
        value={suggested_style_id}
        fieldHandler={(e: any) => checkSkuHandler(e)}
        name={'sku'}
        label={'SKU'}
        helperText={'Please enter the name of your product.'}
        autoComplete={'off'}
        required={false}
      />
      <FormHelperText className={classes.helperText}>
        <b onClick={() => setToggle(!toggle)} className={classes.ModalButton}>
          CLICK HERE
        </b>
        {' for a tip in finding your style or SKU number.'}
      </FormHelperText>
      <ModalComponent modalContent={modalContent} setToggle={setToggle} toggle={toggle} />
    </div>
  );
};

const styles = (theme: any) => ({
  container: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    margin: 'auto',
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    margin: '20px',
    width: '50%',
  },
  typography: {
    fontSize: '22px',
    fontWeight: 800,
    color: theme.palette.secondary.main,
    marginBottom: '10px',
    fontFamily: 'Dopis-Light',
  },
  typographyParagraph: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.secondary.main,
    marginBottom: '20px',
    fontFamily: 'Dopis-Light',
  },
  helperText: {
    width: '90% !important',
    margin: '15px 0  10px 0 !important',
  },
  ModalButton: {
    color: theme.palette.secondary.main,
  },
});

export default HOC(false)(withStyles(styles)(Sku));
