export default (invocation_brand: any, mobile = false) => {
  let brand = invocation_brand;
  if (!invocation_brand) {
    brand = 'default';
  }
  if (mobile) {
    return '/images/logos/' + brand + '/logo_mobile.png';
  } else {
    return '/images/logos/' + brand + '/logo.png';
  }
};

export const getGiftCardOptions = (giftCardOptions: any) => {
  return giftCardOptions.map((cardOption: any) => {
    const label = cardOption.length > 0 ? cardOption[0] : '';
    const image = cardOption.length > 1 ? cardOption[1] : '';
    const background = `rgb(${cardOption[2].join(',')})`;
    return { label, image, background };
  });
};
