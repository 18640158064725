import React, { Fragment } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Header from './Header';
import BrandSpecificHOC from './BrandSpecificParentHOC';
import { CONFIG_DATA, BRAND_SPECIFIC_ROUTES } from '../../utils/consts';
import Getstarted from './steps/GetStarted';
import ProvideItems from './steps/ProvideItems';
import { capitalizeFirstLetter } from '../../utils/functions';
import Offer from './steps/Offer';
import UserInfor from './steps/UserInfo';
import Success from './steps/Success';

interface IBrandSpecificParentContainer {
  classes: any;
}

const BrandSpecificParentContainer: React.FC<IBrandSpecificParentContainer> = ({ classes }): JSX.Element => {
  const environment = CONFIG_DATA && CONFIG_DATA.environment;

  return (
    <Fragment>
      <Router>
        {environment && environment !== 'production' && (
          <div className={classes.topBanner}>{capitalizeFirstLetter(environment)}</div>
        )}
        <div className={classes.headerBar}>
          <Header />
        </div>
        <Switch>
          <Route exact path={BRAND_SPECIFIC_ROUTES.HOME}>
            <Getstarted />
          </Route>
          <Route exact path={BRAND_SPECIFIC_ROUTES.PROVIDER_ITEMS}>
            <ProvideItems />
          </Route>
          <Route exact path={BRAND_SPECIFIC_ROUTES.BRAND_SPECIFIC_OFFER}>
            <Offer />
          </Route>
          <Route exact path={BRAND_SPECIFIC_ROUTES.BRAND_SPECIFIC_USER_INFO}>
            <UserInfor />
          </Route>
          <Route exact path={BRAND_SPECIFIC_ROUTES.BRAND_SPECIFIC_SUCCESS}>
            <Success />
          </Route>
        </Switch>
      </Router>
    </Fragment>
  );
};

const styles: any = {
  headerBar: {
    width: '100%',
    position: 'sticky',
    top: 0,
    backgroundColor: 'rgba(255,255,255, 1)',
    zIndex: '4',
    display: 'flex',
    alignItems: 'baseline',
  },
  listIcon: {
    color: '#fff',
    fontSize: '65px',
  },
  listIconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'fixed',
    zIndex: 600,
  },
  itemNumber: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f00000',
    borderRadius: '50%',
    height: '25px',
    width: '25px',
    color: '#fff',
    fontSize: '20px',
    zIndex: 10,
    position: 'absolute',
    top: '1px',
  },
  topBanner: {
    padding: '10px',
    backgroundColor: '#f00000',
    color: '#ffffff',
    textAlign: 'center',
    fontSize: '1.1em',
    fontWeight: 'bold',
  },
};
export default BrandSpecificHOC(withStyles(styles)(BrandSpecificParentContainer));
