import React from 'react';
import { withStyles } from '@mui/styles';
import HOC from '../HOC';
import { CONFIG_DATA } from '../../../utils/consts';

const Getstarted: React.FC<{ classes: any }> = ({ classes }) => {
  let subdomain = CONFIG_DATA.invocation_brand;
  const openerContent = CONFIG_DATA.brand_specific_config && CONFIG_DATA.brand_specific_config.content.opener;

  const modifiedOpener = openerContent.replace(
    /\$OBO-SELLBACK-URL\$/g,
    `https://${subdomain}.sellback.outandbackoutdoor.com/`,
  );

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={`${classes.title} headlineFont`}>Keep That Gear Out of the Landfill!</div>
        <div className={classes.description} dangerouslySetInnerHTML={{ __html: modifiedOpener }}></div>
      </div>
    </div>
  );
};

const styles = (theme: any) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column' as 'column',
    padding: '2rem 1.3rem',
    '@media (max-width:500px)': {
      paddingTop: '1rem',
    },
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column' as 'column',
    marginBottom: '8rem',
    '@media (max-width:500px)': {
      marginBottom: '5rem',
      paddingTop: '1rem',
    },
  },
  title: {
    fontSize: '2em',
    fontWeight: 500,
    textAlign: 'center' as 'center',
    '@media (max-width:500px)': {
      fontSize: '1.7em',
    },
  },
  headerText: {
    fontSize: '1.1em',
    fontWeight: 500,
    textAlign: 'center' as 'center',
    margin: '5px 0',
    '@media (max-width:500px)': {
      fontSize: '1em',
    },
  },
  description: {
    maxWidth: '560px',
    marginTop: '20px',
    fontSize: '1.1em',
    fontWeight: 500,
    textAlign: 'left' as 'left',
    merginTop: '1rem',
    '@media (max-width:500px)': {
      fontSize: '1em',
    },
  },
});

export default HOC()(withStyles(styles)(Getstarted));
