import React, { Fragment } from 'react';
import { Typography } from '@mui/material';
import CheckBoxButton from '../../../components/CheckBoxButton';
import IsMobile from '../../../utils/IsMobile';

interface ICheckBox {
  classes: any;
  valueHandler: Function;
  arrayQuestion: any;
  arrayNames: any;
  values: any;
}

const ConditionCheckbox: React.FC<ICheckBox> = ({ classes, valueHandler, arrayQuestion, arrayNames, values }): any => {
  const height = window.innerHeight;
  const fontSize = height > 720 ? 15 : 12;
  const isMobile = IsMobile();
  if (arrayQuestion.length === 0) {
    return <></>;
  }

  return (
    arrayQuestion &&
    arrayQuestion.map((question: string, idx: number) =>
      idx === 0 ? (
        <Fragment key={idx}>
          <div className={classes.checkBoxContainer}>
            <div className={classes.checkTextWrap}>
              <Typography
                color={'inherit'}
                style={{
                  fontSize: fontSize,
                  fontWeight: 500,
                  fontFamily: 'Dopis-Light',
                }}
                variant="h5"
                component="h2"
              >
                Is this a promotional item (has someone else's logo on it other than the brand that made it), sample, or
                outlet item?
              </Typography>
            </div>
            <div className={classes.checkBoxWrap}>
              <CheckBoxButton
                style={{
                  marginRight: isMobile ? '30px' : '40px',
                  padding: '0px',
                }}
                valueHandler={valueHandler}
                name={arrayNames[idx]}
                value={'yes'}
                checked={values[idx] === 'yes'}
              />
              <CheckBoxButton
                style={{ padding: '0px' }}
                valueHandler={valueHandler}
                name={arrayNames[idx]}
                value={'no'}
                checked={values[idx] === 'no'}
              />
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment key={idx}>
          <div className={classes.checkBoxContainer}>
            <div className={classes.checkTextWrap}>
              <Typography
                color={'inherit'}
                style={{
                  fontSize: fontSize,
                  fontWeight: 500,
                  fontFamily: 'Dopis-Light',
                }}
                variant="h5"
                component="h2"
              >
                {question}
              </Typography>
            </div>
            <div className={classes.checkBoxWrap}>
              <CheckBoxButton
                style={{
                  marginRight: isMobile ? '30px' : '40px',
                  padding: '0px',
                }}
                valueHandler={valueHandler}
                name={arrayNames[idx]}
                value={'yes'}
                checked={values[idx] === 'yes'}
              />
              <CheckBoxButton
                style={{ padding: '0px' }}
                valueHandler={valueHandler}
                name={arrayNames[idx]}
                value={'no'}
                checked={values[idx] === 'no'}
              />
            </div>
          </div>
        </Fragment>
      ),
    )
  );
};
export default ConditionCheckbox;
