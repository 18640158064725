import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { withStyles } from '@mui/styles';
import PubSub from 'pubsub-js';
import HOC from '../HOC';
import Spinner from '../../../assets/spinner.gif';
import IsMobile from '../../../utils/IsMobile';
import { CONFIG_DATA, ROUTES, SELLBACK_MODE } from '../../../utils/consts';

interface IIntro {
  classes: any;
  disableHandle: any;
  store_update_items: object | any;
  get_data: object | any;
  store_user_value: Function;
}

const Intro: React.FC<IIntro> = ({ classes, disableHandle, store_update_items, get_data, store_user_value }) => {
  const { invocationBrand, googleApiKey } = get_data;
  useEffect(() => {
    disableHandle(false);
  });
  const history: any = useHistory();
  const { search: queryString } = useLocation();
  const { set_path } = store_update_items;
  interface Location {
    address: string;
    metro_id: string;
    location_id: string;
    brand: string;
  }

  // Establish all stores in order to consider if one is close-enough for a
  // drop-off

  let storeLocations: Location[] = [];
  Object.keys(CONFIG_DATA.stores_by_subdomain).forEach((key) => {
    CONFIG_DATA.stores_by_subdomain[key].forEach((row: any) => {
      let address2 = '';
      if (row.address2) {
        address2 = ' ' + row.address2;
      }
      storeLocations.push({
        address: row.address1 + address2 + ' ' + row.city + ', ' + row.state + ' ' + row.zip,
        metro_id: row.retail_metro_id,
        location_id: row.id,
        brand: key,
      });
    });
  });

  const inputSearch = useRef(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [errorMessageValue, setErrorMessage] = useState('');

  const setSearch = (value: string) => {
    if (value !== 'undefined') {
      setSearchValue(value);
    }
    setErrorMessage('');
  };

  const showTheSpinner = () => {
    setShowSpinner(true);
  };

  const hideTheSpinner = () => {
    setShowSpinner(false);
  };

  const checkAddressEntered = () => {
    const errMessage = 'Please enter a valid address or zip code';
    if (searchValue === '') {
      setErrorMessage(errMessage);
      return;
    }
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function (req: XMLHttpRequest, event: Event): any {
      if (req && req.readyState === 4) {
        if (req.status === 200) {
          try {
            determineDistanceToStores();
          } catch (e) {}
        } else {
          hideTheSpinner();
          setErrorMessage(errMessage);
        }
      }
    }.bind(this, xhttp);
    xhttp.open(
      'GET',
      'https://maps.googleapis.com/maps/api/geocode/json?address=' + searchValue + '&key=' + googleApiKey,
      true,
    );
    xhttp.send();
    showTheSpinner();
  };

  const determineDistanceToStores = () => {
    interface RequestResponse {
      index: number;
      address: string;
      metro_id: string;
      location_id: string;
      brand: string;
      response: google.maps.DirectionsStatus;
      payload: google.maps.DirectionsResult;
    }
    interface Distance {
      index: number;
      address: string;
      metro_id: string;
      location_id: string;
      brand: string;
      value: number;
      miles: string;
    }
    // The maximum distance to the nearest store in order to determine if drop-
    // off is an option
    const totalRadiusDistanceFromStore = 25;

    let directionsService = new window.google.maps.DirectionsService();
    let totalRequestsSent = 0;
    let requestResponses: RequestResponse[] = [];
    let checkInterval = window.setInterval(() => {
      if (totalRequestsSent === storeLocations.length) {
        try {
          let allDistances: Distance[] = [];
          requestResponses.forEach((requestResponse) => {
            if (requestResponse.response === 'OK') {
              let directionsData = requestResponse.payload.routes[0].legs[0]; // Get data about the mapped route
              if (directionsData) {
                let metersPerMile = 0.000621371;
                let miles = directionsData.distance.value * metersPerMile;
                allDistances.push({
                  address: requestResponse.address,
                  metro_id: requestResponse.metro_id,
                  brand: requestResponse.brand,
                  location_id: requestResponse.location_id,
                  index: requestResponse.index,
                  value: miles,
                  miles: directionsData.distance.text,
                });
              }
            }
          });
          allDistances.sort((a, b) => {
            return a.value - b.value;
          });
          if (allDistances.length > 0) {
            store_user_value(['closest_location_address'], allDistances[0].address);
            store_user_value(['closest_location_miles'], allDistances[0].value);
            store_user_value(['closest_metro_id'], allDistances[0].metro_id);

            // This auto-selects the Map Marker valueHandler information based off of closest proximity so the user doesnt have to click the location
            store_user_value(['location'], allDistances[0].address);
            store_user_value(['location_id'], allDistances[0].location_id);
            store_user_value(['brand'], allDistances[0].brand);
            if (allDistances[0].value <= totalRadiusDistanceFromStore) {
              history.push(`${ROUTES.DROP_OFF_MAIL_IN}${queryString}`);
            } else {
              set_path(SELLBACK_MODE.MAIL);
              history.push(`${ROUTES.ITEM_COUNT}${queryString}`);
            }
          } else {
            set_path(SELLBACK_MODE.MAIL);
            history.push(`${ROUTES.ITEM_COUNT}${queryString}`);
          }
        } catch (e) {
          Sentry.captureMessage(`Location Search: ${e}`, Sentry.Severity.Error);
          set_path(SELLBACK_MODE.MAIL);
          history.push(`${ROUTES.ITEM_COUNT}${queryString}`);
        }
        hideTheSpinner();
        window.clearInterval(checkInterval);
      }
    }, 500);

    storeLocations.forEach((location, index) => {
      directionsService.route(
        {
          origin: searchValue,
          destination: location.address,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          // anonymous function to capture directions
          totalRequestsSent++;
          requestResponses.push({
            address: location.address,
            metro_id: location.metro_id,
            location_id: location.location_id,
            brand: location.brand,
            index: index,
            response: status,
            payload: response,
          });
        },
      );
    });
  };

  useEffect(() => {
    if (inputSearch.current && window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(inputSearch.current);
      autocomplete.setComponentRestrictions({
        country: ['us'],
      });
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place) {
          setSearch(String(place.formatted_address));
        }
      });
    }
  }, []);

  useEffect(() => {
    // For each change to searchValue rebind the pubsub to get latest value in the closure
    const token = PubSub.subscribe('INTRO_NEXT', checkAddressEntered);
    return () => {
      PubSub.unsubscribe(token);
    };
  }, [searchValue]);
  const isMobile = IsMobile();

  return (
    <div className={classes.container}>
      <div className="headline">Where are you located?</div>
      <div className={classes.inputContainer}>
        <div className="ui icon big input" id="locator-input-section">
          <input
            ref={inputSearch}
            type="text"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onFocus={() => {
              if (isMobile) {
                PubSub.publish('TOGGLE_NEXT_BTN', true);
                window.scrollTo(0, document.body.scrollHeight);
              }
            }}
            onBlur={() => {
              if (isMobile) {
                PubSub.publish('TOGGLE_NEXT_BTN', false);
                window.scrollTo(0, 0);
              }
            }}
            value={searchValue}
            placeholder="City, State or Address"
            id="autocomplete"
          />
          <div style={{ backgroundColor: 'white' }}>
            <img
              style={{ display: showSpinner ? 'block' : 'none', marginTop: 18, marginRight: 12 }}
              src={Spinner}
              className="spinner"
              id="locator-spinner"
            />
          </div>
        </div>
        {errorMessageValue && (
          <div className={classes.headerText} style={{ color: 'red' }}>
            {errorMessageValue}
          </div>
        )}
        <div className={classes.description}>
          Tell us where you're selling from so we can help you through the process
        </div>
      </div>
    </div>
  );
};

const styles = (theme: any) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column' as 'column',
  },
  inputContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column' as 'column',
    margin: '8em 0',
    '@media (max-width:500px)': {
      margin: '5em 0 6em',
    },
  },
  headerText: {
    fontSize: '1.1em',
    fontWeight: 500,
    textAlign: 'center' as 'center',
    margin: '5px 0',
    '@media (max-width:500px)': {
      fontSize: '1em',
    },
  },
  description: {
    maxWidth: '560px',
    marginTop: '20px',
    fontSize: '1.1em',
    fontWeight: 500,
    textAlign: 'center' as 'center',
    '@media (max-width:500px)': {
      fontSize: '1em',
    },
  },
});

export default HOC()(withStyles(styles)(Intro));
