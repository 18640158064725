import * as ActionType from '../types/PostRequestsTypes';

const initialState: any = {
  user: {
    first: '',
    last: '',
    email: '',
    phone_number: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    terms: false,
    mode: '',
    date_time: '',
  },
  customer_details: {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
  },
  customer_address: {
    customer_address_id: '',
  },

  offer: {},
  post_offer: {},
  error_code: '',
  error_message: '',
  loading: false,
};

const reducer = (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case ActionType.POST_SELLBACK_STARTED:
      return {
        ...state,
        error_code: '',
        error_message: '',
        loading: true,
      };
    case ActionType.POST_SELLBACK_FULFILLED:
      return {
        ...state,
        loading: false,
        offer: action.payload,
        error_code: '',
        error_message: '',
      };
    case ActionType.POST_SELLBACK_ERROR:
      return {
        ...state,
        loading: false,
        error_code: `${action.payload?.response?.status}`,
        error_message: action.payload?.response?.data?.error_message,
      };
    case ActionType.STORE_BRAND_SPECIFIC_USER_VALUE:
      const { key, value } = action.payload;
      return {
        ...state,
        user: { ...state.user, [key]: value },
      };
    case ActionType.TRANSITION_BRAND_SPECIFIC_COMMITTED_STARTED:
      return {
        ...state,
        loading: true,
        error_code: '',
        error_message: '',
      };
    case ActionType.TRANSITION_BRAND_SPECIFIC_COMMITTED_FULFILLED:
      return {
        ...state,
        loading: false,
        offer: {
          entity: action.payload.curtailed_entity,
          gift_card_options: action.payload.gift_card_options,
          payment_methods: action.payload.payment_methods,
        },
        error_code: '',
        error_message: '',
      };
    case ActionType.TRANSITION_BRAND_SPECIFIC_COMMITTED_ERROR:
      return {
        ...state,
        loading: false,
        error_code: `${action.payload?.response?.status}`,
        error_message: action.payload?.response?.data?.error_message,
      };
    case ActionType.POST_BRAND_SPECIFIC_CUSTOMER_FULFILLED:
      return {
        ...state,
        loading: true,
        error_code: '',
        error_message: '',
        customer_details: action.payload.entity,
      };
    case ActionType.POST_BRAND_SPECIFIC_CUSTOMER_ERROR:
      return {
        ...state,
        loading: false,
        error_code: `${action.payload?.response?.status}`,
        error_message: action.payload?.response?.data?.error_message,
      };
    case ActionType.POST_BRAND_SPECIFIC_CUSTOMER_ADDRESS_FULFILLED:
      return {
        ...state,
        loading: true,
        error_code: '',
        error_message: '',
        customer_address: action.payload.customer_address_id,
      };
    case ActionType.POST_BRAND_SPECIFIC_CUSTOMER_ADDRESS_ERROR:
      return {
        ...state,
        loading: false,
        error_code: `${action.payload?.response?.status}`,
        error_message: action.payload?.response?.data?.error_message,
      };
    default:
      return state;
  }
};

export default reducer;
