import { CONFIG_DATA, SELLBACK_BRAND_SPECIFIC_STEP, SELLBACK_STEP } from '../../../utils/consts';
import * as ActionType from '../types/StoreItems';

const item = {
  brand: '',
  category: '',
  name: '',
  sku: '',
  style_id: '',
  descriptor: '',
};

const initialState: StoreBrandSpecificItemsState = {
  key: 0,
  progressKey: 0,
  step: SELLBACK_BRAND_SPECIFIC_STEP.INITIAL,
  items: [{ ...item }],
  addedItems: [{ ...item }],
  offerPromotionToken: '',
};

const reducer = (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case ActionType.CREATE_BRAND_SPECIFIC_ITEM:
      return {
        ...state,
        items: [...state.items, { ...item }],
        addedItems: [...state.items, { ...item }],
        key: state.items.length,
        progressKey: state.items.length,
        step: SELLBACK_BRAND_SPECIFIC_STEP.INITIAL,
      };
    case ActionType.STORE_BRAND_SPECIFIC_VALUE:
      const data = action.payload;
      const keys = Object.keys(data);
      const isResetStep = keys.find((key) => ['brand', 'category', 'name'].includes(key));
      const resetItem = {
        ...item,
        brand: state.items[state.key].brand,
        category: state.items[state.key].category,
        ...data,
      };

      return {
        ...state,
        items: [
          ...state.items.slice(0, state.key),
          Object.assign(
            state.items[state.key],
            isResetStep
              ? resetItem
              : {
                  ...state.items[state.key],
                  ...data,
                },
          ),
          ...state.items.slice(state.key + 1),
        ],
        step: isResetStep ? SELLBACK_BRAND_SPECIFIC_STEP.INITIAL : state.step,
      };
    case ActionType.DELETE_BRAND_SPECIFIC_ITEM:
      const updatedItems = deleteItemHandler([...state.addedItems], action.payload);
      const newArray = deleteItemHandler([...state.items], action.payload);
      return {
        ...state,
        items: newArray,
        addedItems: updatedItems,
        key: newArray.length === 0 ? 0 : newArray.length - 1,
        progressKey: newArray.length === 0 ? 0 : newArray.length - 1,
        itemCount: newArray.length,
      };
    case ActionType.SET_BRAND_SPECIFIC_KEY:
      return {
        ...state,
        key: action.payload,
        step: SELLBACK_STEP.INITIAL,
      };
    default:
      return state;
  }
};

export default reducer;

const deleteItemHandler = (items: any, key: number) => {
  let arr = items.filter((item: any, idx: number) => idx !== key);
  if (arr.length === 0) arr = [{ ...item }];
  return arr;
};
