import React from 'react';
import { withStyles } from '@mui/styles';
import { FormHelperText, TextField } from '@mui/material';

interface TextFieldProps {
  classes: any;
  value: any;
  error?: boolean | any;
  fieldHandler: Function;
  name: string;
  label: string;
  helperText?: string;
  helperTextError?: string;
  type?: string;
  autoComplete?: string | any;
  required?: boolean;
  placeholder?: string;
  inputProps?: any;
  description?: any;
  labelWidth?: any;
  isBold?: boolean;
}

const InputField: React.FC<TextFieldProps> = ({
  classes,
  fieldHandler,
  name,
  label = 'Outlined',
  helperText,
  helperTextError,
  error,
  value,
  type,
  autoComplete,
  required = true,
  placeholder,
  inputProps = {},
  description,
  isBold = false,
  labelWidth = 110,
}) => {
  return (
    <div className={classes.formControl}>
      <div className={classes.inputContainer}>
        <div className={classes.labelContainer} style={{ width: `${labelWidth}px` }}>
          {`${label}${required ? '*' : ''}`}
          {description && <div className={classes.description}>{description}</div>}
        </div>
        <TextField
          onChange={(e) => fieldHandler(e)}
          error={error}
          id="outlined-basic"
          color="secondary"
          placeholder={placeholder}
          className={!isBold ? classes.textField : `${classes.textField} ${classes.textFieldBold}`}
          name={name}
          value={value}
          variant="standard"
          required={required}
          type={type}
          autoComplete={autoComplete}
          InputProps={{
            disableUnderline: true,
          }}
        />
      </div>
      {error ? (
        <FormHelperText style={{ color: '#f44336', marginLeft: '10px', fontFamily: 'Dopis-Light' }}>
          {helperTextError}
        </FormHelperText>
      ) : (
        <FormHelperText style={{ textAlign: 'left', marginLeft: '10px', fontFamily: 'Dopis-Light' }}>
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
};

const styles = () => ({
  formControl: {
    width: '100%',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #d3d1d1',
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    width: '110px',
    padding: '0 10px',
    fontSize: '1em',
    height: '48px',
    borderRight: '2px solid #d3d1d1',
  },
  textField: {
    flex: 1,
    padding: '0 10px !important',
    '& .MuiInput-root': {
      fontFamily: 'Dopis-Light !important',
      color: '#1C2229 !important',
      fontSize: '1.1em !important',
      fontWeight: '500 !important',
    },
  },
  textFieldBold: {
    '& .MuiInput-root': {
      fontWeight: '800 !important',
    },
  },
  description: {
    fontSize: '0.8em',
  },
});

export default withStyles(styles)(InputField);
