import React, { Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { Button, CircularProgress } from '@mui/material';
import PubSub from 'pubsub-js';
import { CONFIG_DATA, BRAND_SPECIFIC_ROUTES } from '../../../utils/consts';

interface IBottomNav {
  classes: any;
  setKey: Function;
  disable: boolean;
  offerHandler: Function;
  store_brand_specific_items: object | any;
  post_brand_specific_items: object | any;
  current_brand_specific_items: object | any;
  brand_specific_update_items: object | any;
  postUserInfo: () => void;
}

const BottomNav: React.FC<IBottomNav> = ({
  classes,
  setKey,
  disable,
  offerHandler,
  store_brand_specific_items,
  brand_specific_update_items,
  post_brand_specific_items,
  current_brand_specific_items,
  postUserInfo,
}) => {
  const history: any = useHistory();
  const { search: queryString } = useLocation();
  const location = history.location.pathname;
  const [isNextHidden, setNextHidden] = React.useState(false);

  const { addedItems, items } = store_brand_specific_items;
  const { loading } = post_brand_specific_items;
  const { category, name } = current_brand_specific_items;
  const { create_brand_specific_item } = brand_specific_update_items;

  const notCategorAndName = !category || !name;
  const ifCategorAndName = category && name;

  const notAddedItems = addedItems.length < 2 ? true : false;
  const ifAddedItems = addedItems.length > 1 ? true : false;

  const disabledIfLoading = loading ? true : false;

  const provideItemsButtonText = ifAddedItems && ifCategorAndName ? 'Next (with selected and added)' : 'Next';

  const brandHomepageUrl = CONFIG_DATA?.retail_brand['homepage_url'];
  const brandName = CONFIG_DATA?.retail_brand['label'];

  React.useEffect(() => {
    const token = PubSub.subscribe('TOGGLE_NEXT_BTN', (msg: any, value: boolean) => {
      setNextHidden(value);
    });
    return () => {
      PubSub.unsubscribe(token);
    };
  }, []);

  const scrollToTop = () => window.scrollTo(0, 0);

  const getStarted: any = () => (
    <Fragment>
      <div className={classes.FullButtonContainer}>
        <Button
          onClick={() => {
            history.push(`${BRAND_SPECIFIC_ROUTES.PROVIDER_ITEMS}${queryString}`);
          }}
          className={classes.PathButton}
          color="primary"
          variant="contained"
          style={{ display: isNextHidden ? 'none' : 'block' }}
        >
          Get Started
        </Button>
      </div>
    </Fragment>
  );

  const provideItems: any = () => (
    <Fragment>
      <div className={classes.FullButtonContainer}>
        <Button
          onClick={async () => {
            if (ifCategorAndName) {
              await create_brand_specific_item();
            }
            await offerHandler();
            history.push(`${BRAND_SPECIFIC_ROUTES.BRAND_SPECIFIC_OFFER}${queryString}`);
            scrollToTop();
          }}
          disabled={notCategorAndName && notAddedItems}
          className={classes.PathButton}
          color="primary"
          variant="contained"
          style={{ display: isNextHidden ? 'none' : 'block' }}
        >
          {provideItemsButtonText}
        </Button>
      </div>
    </Fragment>
  );

  const userInfoNav: any = () => (
    <Fragment>
      <Button
        onClick={postUserInfo}
        className={classes.FinishButton}
        color="primary"
        variant="contained"
        disabled={disabledIfLoading}
        style={{ background: '#48BCD1', color: '#fff' }}
      >
        {loading ? <CircularProgress color={'inherit'} style={{ color: '#262262' }} /> : 'Finish & get shipping labels'}
      </Button>
    </Fragment>
  );

  const success: any = () => (
    <Fragment>
      <div className={classes.FullButtonContainer}>
        <Button
          className={classes.PathButton}
          color="primary"
          variant="contained"
          style={{ display: isNextHidden ? 'none' : 'block' }}
        >
          <a style={{ textDecoration: 'none', color: 'inherit' }} href={brandHomepageUrl}>
            Back to {brandName}
          </a>
        </Button>
      </div>
    </Fragment>
  );

  const navigationHandler: Function = () => {
    switch (location) {
      case BRAND_SPECIFIC_ROUTES.HOME:
        return getStarted();
      case BRAND_SPECIFIC_ROUTES.PROVIDER_ITEMS:
        return provideItems();
      case BRAND_SPECIFIC_ROUTES.BRAND_SPECIFIC_USER_INFO:
        return userInfoNav();
      case BRAND_SPECIFIC_ROUTES.BRAND_SPECIFIC_SUCCESS:
        return success();
      default:
        return <></>;
    }
  };

  return <div className={classes.container}>{navigationHandler()}</div>;
};

const styles = () => ({
  container: {
    width: '100%',
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'center',
    marginBotton: '2rem',
  },
  button: {
    width: '15em',
    margin: '0 5px !important',
    fontSize: '16px !important',
    fontWeight: '900 !important',
  },
  nextButton: {
    width: '15em',
    fontSize: '16px !important',
    fontWeight: '900 !important',
  },
  IntroButton: {
    width: '10em',
    margin: '10px 5px !important',
    fontSize: '16px !important',
    fontWeight: '900 !important',
    '@media (max-width:500px)': {
      margin: '25px 5px 0 5px !important',
    },
  },
  PathButtonContainer: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  FullButtonContainer: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  PathButton: {
    width: '15em',
    fontSize: '16px !important',
    fontWeight: '900 !important',
  },
  PathButtonSmall: {
    width: '15em',
    fontSize: '16px !important',
    fontWeight: '900 !important',
    '@media (max-width:500px)': {
      fontSize: '13px !important',
    },
  },
  FinishButton: {
    width: '20em',
    margin: '0 5px !important',
    fontSize: '16px !important',
    fontWeight: '900 !important',
    backgroundColor: '#1C2229',
    color: '#fff !important',
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
    },
  },
});

export default withStyles(styles)(BottomNav);
