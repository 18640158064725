import React from 'react';
import { withStyles } from '@mui/styles';
import { Checkbox } from '@mui/material';

interface CheckBoxButtonProps {
  classes: any;
  style?: any;
  checked: any;
  value?: any;
  valueHandler: any;
  name?: string;
}

const CheckBoxButton: React.FC<CheckBoxButtonProps> = ({
  classes,
  style,
  value = 'check',
  name = 'check',
  checked,
  valueHandler,
}) => {
  return (
    <Checkbox
      style={style}
      onClick={(e) => valueHandler(e)}
      inputProps={{
        'aria-label': 'primary checkbox',
        name,
        value,
      }}
      checked={checked}
      checkedIcon={<span className={classes.checkedIcon} />}
      icon={<span className={classes.icon} />}
    />
  );
};

const styles = () => ({
  icon: {
    width: 20,
    height: 20,
    boxShadow: 'inset 2px 2px 1px 0.25px rgb(0 0 0 / 13%)',
    border: '1px solid #d3d1d1',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    width: 20,
    height: 20,
    boxShadow: 'inset 2px 2px 1px 0.25px rgb(0 0 0 / 13%)',
    border: '1px solid #d3d1d1',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23000000'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff',
    },
  },
});

export default withStyles(styles)(CheckBoxButton);
