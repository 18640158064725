import { Dispatch } from 'redux';
import * as ActionType from '../types/GetRequestsTypes';
import restCall from '../../../utils/fetch-util';

export const get_brand_specific_categories = (brand: string) => ({
  type: ActionType.GET_BRAND_SPECIFIC_CATEGORIES,
  payload: { brand: brand },
});

export const get_brand_specific_products = (brand: string, category: string) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/api/product?brand=${encodeURIComponent(brand)}&product_type=${encodeURIComponent(category)}`,
      method: 'get',
      startType: ActionType.GET_BRAND_SPECIFIC_PRODUCT_NAMES_REQUEST_STARTED,
      successType: ActionType.GET_BRAND_SPECIFIC_PRODUCT_NAMES_REQUEST_FULFILLED,
      errorType: ActionType.GET_BRAND_SPECIFIC_PRODUCT_NAMES_REQUEST_ERROR,
      dispatch,
    });
  };
};
