import React, { Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { Button } from '@mui/material';
import PubSub from 'pubsub-js';
import validation from '../../../utils/validation';
import { ROUTES, SELLBACK_MODE } from '../../../utils/consts';

interface IBottomNav {
  classes: any;
  current_item: object | any;
  post_items: object | any;
  store_items: object | any;
  setKey: Function;
  disable: boolean;
  offerHandler: Function;
  postUserInfo: Function;
  store_update_items: object | any;
  offerHandlerDropOffLong: Function;
}

const BottomNav: React.FC<IBottomNav> = ({
  classes,
  post_items,
  store_items,
  setKey,
  disable,
  offerHandler,
  postUserInfo,
  store_update_items,
  offerHandlerDropOffLong,
}) => {
  const history: any = useHistory();
  const { search: queryString } = useLocation();
  const location = history.location.pathname;
  const { error_code, loading } = post_items;
  const { path, items } = store_items;
  const { createMultiItem } = store_update_items;
  const [isNextHidden, setNextHidden] = React.useState(false);
  const query = new URLSearchParams(queryString);
  const resumeSellbackRequestId = query.get('resume_s2r_id');

  React.useEffect(() => {
    const token = PubSub.subscribe('TOGGLE_NEXT_BTN', (msg: any, value: boolean) => {
      setNextHidden(value);
    });
    return () => {
      PubSub.unsubscribe(token);
    };
  }, []);

  const scrollToTop = () => window.scrollTo(0, 0);

  const reviewHandler = () => {
    let valid = true;
    let invalidProductIndex = 0;
    let errorReason = '';
    let errorDescriptor = '';
    items.forEach((item: any, index: number) => {
      invalidProductIndex = index;
      const errors = validation(item);
      if (errors.length > 0) {
        valid = false;
        errorReason = errors.join(', ');
        errorDescriptor = item.brand + ' ' + item.category + ': ' + item.descriptor;
      }
    });

    scrollToTop();
    if (valid) {
      offerHandler();
      history.push(`${ROUTES.OFFER}${queryString}`);
    } else {
      alert(
        `Whoops! There was an issue recording some information about your: '${errorDescriptor}'.\n\nWe're going to have you walk through a few of the screens where the errors occurred so we can try this again!\n\nReason(s): ${errorReason}`,
      );
      setKey(invalidProductIndex);
      history.push(`${ROUTES.PROVIDER_ITEMS}${queryString}`);
    }
  };

  const fullNav: any = () => (
    <Fragment>
      <div className={classes.FullButtonContainer}>
        <Button
          onClick={() => {
            PubSub.publish('INTRO_NEXT');
            scrollToTop();
          }}
          className={classes.PathButton}
          color="primary"
          variant="contained"
          style={{ display: isNextHidden ? 'none' : 'block' }}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );

  const dropOffMailInNav: any = () => (
    <Fragment>
      <div className={classes.FullButtonContainer}>
        <Button
          onClick={() => {
            history.push(`${ROUTES.HOME}${queryString}`);
          }}
          className={classes.button}
          color="info"
          variant="contained"
        >
          Back
        </Button>
        <Button
          onClick={() => {
            history.push(`${ROUTES.ITEM_COUNT}${queryString}`);
          }}
          className={classes.button}
          color="primary"
          variant="contained"
        >
          Next
        </Button>
      </div>
    </Fragment>
  );

  const itemCount: any = () => (
    <Fragment>
      <div className={classes.FullButtonContainer}>
        <Button
          onClick={() => {
            createMultiItem();
            history.push(`${ROUTES.PROVIDER_ITEMS}${queryString}`);
          }}
          className={classes.nextButton}
          color="primary"
          variant="contained"
        >
          Next
        </Button>
      </div>
    </Fragment>
  );

  const reviewNav: any = () => (
    <Fragment>
      <Button
        onClick={() => {
          history.push(`${ROUTES.PROVIDER_ITEMS}${queryString}`);
        }}
        className={classes.button}
        color="info"
        variant="contained"
      >
        Back
      </Button>
      <Button
        onClick={() => reviewHandler()}
        className={classes.FinishButton}
        color="inherit"
        variant="contained"
        disabled={disable}
      >
        GET MY OFFER
      </Button>
    </Fragment>
  );

  const locationTimeNavDropOffLong: any = () => (
    <Fragment>
      <Button
        onClick={() => {
          history.push(`${ROUTES.USER_INFO}${queryString}`);
        }}
        className={classes.button}
        color="info"
        variant="contained"
      >
        Back
      </Button>
      <Button
        onClick={() => offerHandlerDropOffLong()}
        className={classes.FinishButton}
        color="primary"
        variant="contained"
        disabled={disable}
      >
        Finish
      </Button>
    </Fragment>
  );

  const userInfoNav: any = () => (
    <Fragment>
      {!resumeSellbackRequestId && (
        <Button
          onClick={() => {
            history.push(`${ROUTES.OFFER}${queryString}`);
          }}
          className={classes.button}
          color="info"
          variant="contained"
        >
          Back
        </Button>
      )}
      <Button
        onClick={() => postUserInfo()}
        className={path === SELLBACK_MODE.MAIL ? classes.FinishButton : classes.button}
        color="primary"
        variant="contained"
        disabled={disable}
      >
        {path === SELLBACK_MODE.MAIL ? 'Finish' : 'Next'}
      </Button>
    </Fragment>
  );

  const navigationHandler: Function = () => {
    switch (location) {
      case ROUTES.HOME:
        return resumeSellbackRequestId ? <></> : fullNav();
      case ROUTES.DROP_OFF_MAIL_IN:
        return dropOffMailInNav();
      case ROUTES.ITEM_COUNT:
        return itemCount();
      case ROUTES.REVIEW:
        return reviewNav();
      case ROUTES.USER_INFO:
        return userInfoNav();
      case ROUTES.TIME:
        return error_code || loading ? <></> : locationTimeNavDropOffLong();
      default:
        return <></>;
    }
  };

  return <div className={classes.container}>{navigationHandler()}</div>;
};

const styles = () => ({
  container: {
    width: '100%',
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'center',
  },
  button: {
    width: '15em',
    margin: '0 5px !important',
    fontSize: '16px !important',
    fontWeight: '900 !important',
  },
  nextButton: {
    width: '15em',
    fontSize: '16px !important',
    fontWeight: '900 !important',
  },
  IntroButton: {
    width: '10em',
    margin: '10px 5px !important',
    fontSize: '16px !important',
    fontWeight: '900 !important',
    '@media (max-width:500px)': {
      margin: '25px 5px 0 5px !important',
    },
  },
  PathButtonContainer: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  FullButtonContainer: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  PathButton: {
    width: '15em',
    fontSize: '16px !important',
    fontWeight: '900 !important',
  },
  PathButtonSmall: {
    width: '15em',
    fontSize: '16px !important',
    fontWeight: '900 !important',
    '@media (max-width:500px)': {
      fontSize: '13px !important',
    },
  },
  FinishButton: {
    width: '15em',
    margin: '0 5px !important',
    fontSize: '16px !important',
    fontWeight: '900 !important',
    backgroundColor: '#1C2229 !important',
    color: '#fff !important',
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
    },
  },
});

export default withStyles(styles)(BottomNav);
