export const GET_BRANDS = 'GET_BRANDS';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_DESCRIPTORS = 'GET_DESCRIPTORS';

export const GET_PRODUCT_NAMES_REQUEST_STARTED = 'GET_PRODUCT_NAMES_REQUEST_STARTED';
export const GET_PRODUCT_NAMES_REQUEST_FULFILLED = 'GET_PRODUCT_NAMES_REQUEST_FULFILLED';
export const GET_PRODUCT_NAMES_REQUEST_ERROR = 'GET_PRODUCT_NAMES_REQUEST_ERROR';

export const GET_SIZES = 'GET_SIZES';

export const CONDITION_QUESTIONS_REQUEST_FULFILLED = 'CONDITION_QUESTIONS_REQUEST_FULFILLED';
