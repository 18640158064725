import { casualTopText } from './filterData';
import { getObjectValues } from '../utils/functions';

const filterItems = (items: any) => {
  const entries = getObjectValues(items);
  const arr = entries.map((product) => {
    const obj = {
      id: product.sku === '' ? null : product.sku,
      name: product.name === '' ? null : product.name,
      brand: product.brand,
      condition: product.condition,
      category: product.category,
      descriptor: product.descriptor === casualTopText ? 'Casual Tops' : product.descriptor,
    };
    return obj;
  });
  return arr;
};

const submitUserInfo = () => {
  window.dataLayer.push({
    form_type: 'sellback-main',
    event: 'form_step',
    form_step: 'user information',
  });
};

export function describeProduct() {
  window.dataLayer.push({
    form_type: 'sellback-main',
    event: 'form_step',
    form_step: 'describe products',
  });
}
export function addProduct() {
  window.dataLayer.push({
    form_type: 'sellback-main',
    event: 'form_step',
    form_step: 'add products',
  });
}
export function inputProduct(step: any) {
  window.dataLayer.push({
    form_type: 'sellback-input-product',
    event: 'form_step',
    form_step: step,
  });
}
export function offerPresented(items: any, cash_later: any, cash_now: any) {
  window.dataLayer.push({
    form_type: 'sellback-main',
    event: 'form_step',
    form_step: 'get offer',
    offers: [
      {
        offer_type: 'cash now',
        offer: cash_now,
      },
      {
        offer_type: 'cash later',
        offer: cash_later,
      },
    ],
    products: filterItems(items),
  });
}
export function offerAccepted(items: any, offer_type: any, cash_later: any, cash_now: any) {
  window.dataLayer.push({
    form_type: 'sellback-main',
    event: 'form_step',
    form_step: 'accept offer',
    offer_type_accepted: offer_type,
    offers: [
      {
        offer_type: 'cash now',
        offer: cash_now,
      },
      {
        offer_type: 'cash later',
        offer: cash_later,
      },
    ],
    products: filterItems(items),
  });
}

export function sellbackComplete(items: any, offer_type: any, cash_later: any, cash_now: any) {
  submitUserInfo();
  window.dataLayer.push({
    form_type: 'sellback-main',
    event: 'form_complete',
    offer_type_accepted: offer_type,
    offers: [
      {
        offer_type: 'cash now',
        offer: cash_now,
      },
      {
        offer_type: 'cash later',
        offer: cash_later,
      },
    ],
    products: filterItems(items),
  });
}
