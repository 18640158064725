import axios from 'axios';
import * as ActionType from '../store/types/PostRequestsTypes';
import * as Sentry from '@sentry/react';

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

export default function restCall(params: any) {
  const { url, method, data, startType, successType, errorType, dispatch } = params;
  const axVars = {
    method,
    url: url,
    data: data,
  };
  if (startType) {
    dispatch({ type: startType });
  }
  return instance(axVars)
    .then((resp) => {
      dispatch({ type: successType, payload: resp.data });
      return resp.data;
    })
    .catch((error) => {
      if (method === 'put' && errorType === ActionType.POST_PRODUCTS_ERROR) {
        const storeData = localStorage.getItem('storeData');
        if (storeData) {
          Sentry.setContext('Server Failure', JSON.parse(storeData));
        }
      }
      dispatch({ type: errorType, payload: error });
    });
}

export function restMockCall(params: any) {
  const { errorType, status, errorMessage, dispatch } = params;
  return dispatch({
    type: errorType,
    payload: {
      response: {
        status: status,
        data: {
          error_message: errorMessage,
        },
      },
    },
  });
}
