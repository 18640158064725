import React, { useEffect, useMemo } from 'react';
import { withStyles } from '@mui/styles';
import HOC from '../HOC';
import DropDown from '../../../components/DropDownSelect';
import { buildProductOptions } from '../../../utils/filterData';
import { CircularProgress } from '@mui/material';
import { CONFIG_DATA } from '../../../utils/consts';
import { capitalizeFirstLetter } from '../../../utils/functions';

//https://www.svgrepo.com/svg/

const AddIcon = ({ color }: any) => (
  <svg width="74px" height="74px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12.5 5.5C13.0523 5.5 13.5 5.94772 13.5 6.5V10.5H17.5C18.0523 10.5 18.5 10.9477 18.5 11.5V12.5C18.5 13.0523 18.0523 13.5 17.5 13.5H13.5V17.5C13.5 18.0523 13.0523 18.5 12.5 18.5H11.5C10.9477 18.5 10.5 18.0523 10.5 17.5V13.5H6.5C5.94772 13.5 5.5 13.0523 5.5 12.5V11.5C5.5 10.9477 5.94772 10.5 6.5 10.5H10.5V6.5C10.5 5.94772 10.9477 5.5 11.5 5.5H12.5Z"
        fill={color}
      ></path>{' '}
    </g>
  </svg>
);

const CancelIcon = () => (
  <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M16 8L8 16M8.00001 8L16 16"
        stroke="#000000"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

interface Props {
  classes: any;
  brand_specific_get_requests: object | any;
  get_brand_specific_data: object | any;
  current_brand_specific_items: object | any;
  brand_specific_update_items: object | any;
  store_brand_specific_items: any;
}

const ProvideItems: React.FC<Props> = ({
  classes,
  brand_specific_get_requests,
  current_brand_specific_items,
  get_brand_specific_data,
  brand_specific_update_items,
  store_brand_specific_items,
}) => {
  const { get_brand_specific_categories, get_brand_specific_products } = brand_specific_get_requests;
  const { store_brand_specific_value, create_brand_specific_item, delete_brand_specific_item } =
    brand_specific_update_items;
  const { category, brand, name } = current_brand_specific_items;
  const { addedItems } = store_brand_specific_items;
  const { brand_specific_products, categories, loading } = get_brand_specific_data;

  const disabledAdd = !category || !name;
  const disabledInput = brand_specific_products.length < 1;

  const brandName = CONFIG_DATA?.retail_brand['label'];

  useEffect(() => {
    if (brandName) {
      store_brand_specific_value({ brand: brandName });
    }
    if (brand) {
      get_brand_specific_categories(brand);
    }
    if (brand && category) {
      get_brand_specific_products(brand, category);
    }
  }, [current_brand_specific_items.brand, get_brand_specific_categories, category, brand, brandName]);

  const valueHandler = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
    const { name, value } = e.target;

    const selectedOption = e.target.options[e.target.selectedIndex];
    const product = JSON.parse(selectedOption.getAttribute('data-product'));

    if (name === 'category') {
      store_brand_specific_value({
        [name]: value,
        name: '',
      });
      return;
    }
    if (name === 'name') {
      store_brand_specific_value({
        [name]: product.label,
        sku: product.sku,
        style_id: product.style_id,
        descriptor: product.descriptor,
      });
      return;
    }
    return store_brand_specific_value({ [name]: value });
  };

  const productArray = buildProductOptions(brand_specific_products, category);

  const deleteHandler = (key: number) => {
    if (addedItems.length === 1) {
      delete_brand_specific_item(key);
    }
    delete_brand_specific_item(key);
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={`${classes.title} headlineFont`}>Select Your ENDLESS PROMISE&reg; Gear for Recycling</div>
        <div className={classes.description}>
          <p>
            Use the drop-down below to select the specific ENDLESS PROMISE&reg; item(s) you're sending in. Add as many
            items as you have.
          </p>
        </div>

        <div className={classes.itemsContainer}>
          <div>
            <div className="headlineFont" style={{ paddingBottom: '1em' }}>
              CATEGORY
            </div>
            <DropDown
              value={current_brand_specific_items !== undefined ? current_brand_specific_items.category : null}
              fieldHandler={valueHandler}
              data={categories}
              name={'category'}
              label={'Category'}
              className={'input-field'}
              required={true}
              helperText={'Select descriptor that describes your item.'}
            />
          </div>

          {loading && brand_specific_products.length < 1 ? (
            <CircularProgress color={'inherit'} style={{ color: '#262262' }} />
          ) : (
            <div className={classes.itemContainer}>
              <div className="headlineFont" style={{ paddingBottom: '1em' }}>
                NAME
              </div>
              <DropDown
                disabled={disabledInput}
                value={current_brand_specific_items && current_brand_specific_items.style_id}
                fieldHandler={valueHandler}
                data={productArray !== undefined && productArray}
                name={'name'}
                label={'Name'}
                className={'input-field'}
                required={true}
                helperText={'Select the name that describes your item.'}
              />
            </div>
          )}
        </div>
        <button
          disabled={disabledAdd}
          style={{ margin: '2rem 0' }}
          className={`pointer ${classes.addBtn}`}
          onClick={create_brand_specific_item}
        >
          <AddIcon color={disabledAdd ? '#0000001F' : '#F4BB44'} />
        </button>
        {addedItems.length && addedItems[0]['name'] && (
          <div>
            <div className="headlineFont" style={{ marginBottom: '1rem' }}>
              Submitted items
            </div>
            {addedItems?.map(
              ({ category, name }: { category: string; name: string }, idx: number) =>
                category &&
                name && (
                  <div className={`pointer ${classes.items}`} key={idx}>
                    <div style={{ fontSize: '.7em' }}>
                      {name} - {category}
                    </div>
                    <div onClick={() => deleteHandler(idx)}>
                      <CancelIcon />
                    </div>
                  </div>
                ),
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const styles = (theme: any) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column' as 'column',
    padding: '0 1.3rem',
    paddingTop: '1rem',
    '@media (max-width:500px)': {
      paddingTop: '1rem',
    },
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column' as 'column',
    '@media (max-width:500px)': {
      paddingTop: '1rem',
    },
  },
  title: {
    fontSize: '1.7em',
    fontWeight: 500,
    textAlign: 'left' as 'left',
    '@media (max-width:500px)': {
      fontSize: '1.3em',
    },
  },
  description: {
    maxWidth: '560px',
    marginTop: '20px',
    fontSize: '1.1em',
    fontWeight: 500,
    textAlign: 'left' as 'left',
    merginTop: '1rem',
    '@media (max-width:500px)': {
      fontSize: '1em',
    },
  },
  itemsContainer: {
    display: 'flex',
    alignItems: 'center' as 'center',
    gap: '1rem',
    marginTop: '2rem',
    '@media (max-width:500px)': {
      flexDirection: 'column' as 'column',
    },
  },
  items: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
  },
  addBtn: {
    border: 'none',
    outline: 'none',
    background: 'transparent',
  },
});

export default HOC()(withStyles(styles)(ProvideItems));
