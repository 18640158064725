import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  const { store_brand_specific_items } = state;
  return { store_brand_specific_items };
};

type Props = ReturnType<typeof mapStateToProps>;

const UpdatedComponent = (OriginalComponent: any) => {
  const NewComponent: React.FC<Props> = ({ ...Props }) => {
    return <OriginalComponent {...Props} />;
  };
  return NewComponent;
};

const composeWrapper = compose(connect(mapStateToProps), UpdatedComponent);

export default composeWrapper;
