import React from 'react';
import { withStyles } from '@mui/styles';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { CONFIG_DATA } from '../utils/consts';

interface RadioButtonProps {
  classes: any;
  itemCount: number;
  onCallback: any;
}

const Count: React.FC<RadioButtonProps> = ({ classes, itemCount, onCallback }) => {
  const onMinus = () => {
    if (itemCount === 1) return;
    onCallback(-1);
  };

  const onPlus = () => {
    const maxItemCount = CONFIG_DATA?.parameters?.maximum_item_count;
    if (maxItemCount && itemCount >= maxItemCount) return;
    onCallback(1);
  };

  return (
    <div className={classes.container}>
      <Button className={classes.minusButton} variant="contained" onClick={onMinus}>
        <RemoveIcon />
      </Button>
      <div className={classes.input}>
        <span style={{ height: 18 }}>{itemCount}</span>
      </div>
      <Button className={classes.plusButton} variant="contained" onClick={onPlus}>
        <AddIcon />
      </Button>
    </div>
  );
};

const styles = () => ({
  container: {
    display: 'flex',
  },
  minusButton: {
    width: '50px',
    height: '50px',
    borderRadius: '0px !important',
  },
  plusButton: {
    width: '50px',
    height: '50px',
    borderRadius: '0px !important',
  },
  input: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
    border: '1px solid #d3d1d1',
    fontSize: '1.1em',
    fontWeight: 500,
    padding: '5px 10px',
    '@media (max-width:500px)': {
      fontSize: '1em',
    },
  },
});

export default withStyles(styles)(Count);
