import * as ActionType from '../types/GetRequestsTypes';
import { brandFilter, categoriesFilter, descriptorFilter, questionsFilter, sizesFilter } from '../../utils/filterData';
import SkuData from '../configData/sku.json';
import { CONFIG_DATA } from '../../utils/consts';

const invocationBrand = CONFIG_DATA ? CONFIG_DATA.invocation_brand : '';
const googleApiKey = CONFIG_DATA ? CONFIG_DATA.s.maps : process.env.REACT_APP_GOOGLE_API_KEY;

const initialState: GetRequestState = {
  brands: [],
  categories: [],
  descriptors: [],
  products: [],
  sizes: [],
  questions: [],
  loading: false,
  error_message: '',
  sku_tag: SkuData,
  invocationBrand: invocationBrand,
  googleApiKey: googleApiKey,
  isHacking: false,
};

const reducer = (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case ActionType.GET_BRANDS:
      return {
        ...state,
        brands: brandFilter(CONFIG_DATA, action.payload.isMailIn),
      };
    case ActionType.GET_CATEGORIES:
      const { isMailIn, brand } = action.payload;
      const categories = categoriesFilter(CONFIG_DATA, brand, isMailIn);
      return {
        ...state,
        categories,
        isHacking: categories.length === 0,
      };
    case ActionType.GET_DESCRIPTORS:
      return {
        ...state,
        descriptors: descriptorFilter(action.payload),
      };
    case ActionType.GET_PRODUCT_NAMES_REQUEST_STARTED:
      return {
        ...state,
        loading: true,
      };
    case ActionType.GET_PRODUCT_NAMES_REQUEST_FULFILLED:
      return {
        ...state,
        loading: false,
        products: action.payload.products,
      };
    case ActionType.GET_PRODUCT_NAMES_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error_message: action.payload,
      };
    case ActionType.GET_SIZES:
      return {
        ...state,
        sizes: sizesFilter(action.payload),
      };
    case ActionType.CONDITION_QUESTIONS_REQUEST_FULFILLED:
      return {
        ...state,
        questions: questionsFilter(CONFIG_DATA, action.payload.category),
      };
    default:
      return state;
  }
};

export default reducer;
