import { Dispatch } from 'redux';
import * as ActionType from '../types/PostRequestsTypes';
import restCall, { restMockCall } from '../../utils/fetch-util';
import { SELLBACK2_REQUEST_STATE, COMMON_RESPONSE } from '../../utils/consts';

export const post_products = (method: string = 'post', url: string | any, data: any) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: url,
      method: method,
      data: data,
      startType: ActionType.POST_PRODUCTS_STARTED,
      successType: ActionType.POST_PRODUCTS_FULFILLED,
      errorType: ActionType.POST_PRODUCTS_ERROR,
      dispatch,
    });
  };
};

export const obo_set_api_validation_fail = (errorCode: any, errorMessage: string) => {
  return async function (dispatch: Dispatch) {
    await restMockCall({
      errorType: ActionType.POST_PRODUCTS_ERROR,
      status: errorCode,
      errorMessage,
      dispatch,
    });
  };
};

export const store_user_value = (key: string, value: any) => ({
  type: ActionType.STORE_USER_VALUE,
  payload: { key, value },
});

export const storeSavedUser = (key: string, value: any) => ({
  type: ActionType.STORE_SAVED_USER_VALUE,
  payload: { key, value },
});

export const post_user = (id: string, data: any) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/api/2/sellback/request/${id}/${SELLBACK2_REQUEST_STATE.COMMITTED}`,
      method: 'put',
      data: data,
      startType: ActionType.POST_USER_STARTED,
      successType: ActionType.POST_USER_FULFILLED,
      errorType: ActionType.POST_USER_ERROR,
      dispatch,
    });
  };
};

export const postSavedUser = (data: any) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/api/customer`,
      method: 'post',
      data: data,
      startType: ActionType.POST_SAVED_USER_STARTED,
      successType: ActionType.POST_SAVED_USER_FULFILLED,
      errorType: ActionType.POST_SAVED_USER_ERROR,
      dispatch,
    });
  };
};

export const transitionSaved = (id: string, data: any) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/api/2/sellback/request/${id}/${SELLBACK2_REQUEST_STATE.ONLINE_SAVED}`,
      method: 'put',
      data: data,
      startType: ActionType.TRANSITION_SAVED_STARTED,
      successType: ActionType.TRANSITION_SAVED_FULFILLED,
      errorType: ActionType.TRANSITION_SAVED_ERROR,
      dispatch,
    });
  };
};

export const transitionRequested = (id: string, data: any) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/api/2/sellback/request/${id}/${SELLBACK2_REQUEST_STATE.REQUESTED}`,
      method: 'put',
      data: data,
      startType: ActionType.TRANSITION_REQESTED_STARTED,
      successType: ActionType.TRANSITION_REQESTED_FULFILLED,
      errorType: ActionType.TRANSITION_REQESTED_ERROR,
      dispatch,
    });
  };
};

export const reset_error = () => ({
  type: ActionType.RESET_ERROR,
});
