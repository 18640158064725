import * as ActionType from '../types/StoreItems';

export const create_brand_specific_item = () => ({
  type: ActionType.CREATE_BRAND_SPECIFIC_ITEM,
});

export const store_brand_specific_value = (data: any) => ({
  type: ActionType.STORE_BRAND_SPECIFIC_VALUE,
  payload: data,
});

export const delete_brand_specific_item = (number: number) => ({
  type: ActionType.DELETE_BRAND_SPECIFIC_ITEM,
  payload: number,
});

export const setKey = (key: number) => ({
  type: ActionType.SET_BRAND_SPECIFIC_KEY,
  payload: key,
});
