import React from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import BrandSpecificConatiner from './BrandSpecificConatiner';
import { CONFIG_DATA } from '../../utils/consts';
import Fallback from '../Fallback';

function App() {
  if (CONFIG_DATA.hasOwnProperty('s') && CONFIG_DATA.s.hasOwnProperty('sentry_dsn')) {
    if (CONFIG_DATA.s.sentry_dsn !== '' && CONFIG_DATA.s.sentry_dsn !== null) {
      Sentry.init({
        dsn: CONFIG_DATA.s.sentry_dsn,
        integrations: [new Integrations.BrowserTracing()],
        normalizeDepth: 10,

        // REF: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/14
        ignoreErrors: [
          'Non-Error exception captured',
          'Non-Error promise rejection captured',
          'Extension context invalidated',
          "Identifier 'originalPrompt' has already been declared",
          'window.lintrk is not a function',
        ],
      });
    }
  }
  return (
    <React.Fragment>
      <Sentry.ErrorBoundary fallback={<Fallback />} showDialog={true}>
        <BrandSpecificConatiner />
      </Sentry.ErrorBoundary>
    </React.Fragment>
  );
}

export default App;
