import React, { Fragment, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { Button } from '@mui/material';
import HOC from '../HOC';
import DropDown from '../../../components/DropDownSelect';
import { SELLBACK_STEP } from '../../../utils/consts';
import { hasSizes } from '../../../utils/filterData';

const Size: React.FC<IChildComponent> = ({
  get_data,
  get_requests,
  store_update_items,
  current_item,
  classes,
  disable,
  disableHandle,
}): JSX.Element => {
  const { store_value, setItemStep } = store_update_items;
  const { get_sizes } = get_requests;
  const { sizes } = get_data;
  const { category, descriptor, size, size_two } = current_item;

  useEffect(() => {
    if (category) {
      get_sizes({ category, descriptor });
    }
  }, [get_sizes, category, descriptor]);

  useEffect(() => {
    if (!hasSizes({ category, descriptor })) {
      disableHandle(false);
      return;
    }
    disableHandle(!(size && sizes && sizes['Size2'] ? size !== '' && size_two !== '' : size !== ''));
  }, [disableHandle, sizes, size, size_two, category]);

  const valueHandler = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
    const { name, value } = e.target;
    store_value({ [name]: value });
  };

  const labelHandler = () => {
    switch (category) {
      case 'Sleeping Bag':
        return 'Length';
      case 'Sleeping Pad':
        return 'Length';
      case "Men's Outerwear":
        return 'Inseam';
      case "Women's Outerwear":
        return 'Inseam';
      default:
        break;
    }
  };

  return (
    <div className={classes.container}>
      <div className="headline">Select the size of your item</div>
      <div className={classes.contentContainer}>
        <DropDown
          value={size}
          fieldHandler={valueHandler}
          data={sizes && sizes['Size1'] ? sizes['Size1'] : []}
          name={'size'}
          label={'Size'}
          className={'input-field'}
          required={true}
          helperText={'Please select the size of your item.'}
        />
        {size && sizes && sizes['Size2'] && (
          <Fragment>
            <br />
            <DropDown
              value={size_two}
              fieldHandler={valueHandler}
              data={sizes['Size2']}
              name={'size_two'}
              label={labelHandler()}
              className={'input-field'}
              required={true}
              helperText={`Please select the ${labelHandler()} of ${category}`}
            />
          </Fragment>
        )}
      </div>
      <Button
        onClick={() => setItemStep(SELLBACK_STEP.COLOR)}
        className={classes.button}
        color="primary"
        variant="contained"
        disabled={disable}
      >
        Next
      </Button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    height: '100%',
    minHeight: '250px',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: '250px',
    margin: '0 5px !important',
    fontSize: '16px !important',
    fontWeight: '900 !important',
  },
};

export default HOC(false)(withStyles(styles)(Size));
