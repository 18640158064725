import { getObjectEntries } from './functions';
import { CONFIG_DATA } from './consts';

const matchDescriptor = (types: any, want_human_product_type: any) => {
  for (const type of types) {
    const [have_human_product_type, have_human_descriptor] = type;
    if (have_human_product_type === want_human_product_type) {
      return have_human_descriptor;
    } else {
      return '';
    }
  }
};

export const buildProductOptions = (products = [], category: any) => {
  const options = [];
  for (let obj of products) {
    options.push({
      label: obj['style_name'],
      style_id: obj['style_id'],
      sku: obj['sku'],
      descriptor: matchDescriptor(obj['types'], category),
    });
  }
  return options;
};

export function brandFilter(object: any, isMailin: any) {
  if (isMailin) {
    return Object.keys(object.matrix);
  } else {
    return Object.keys(object.matrix_for_dropoffs);
  }
}

export function categoriesFilter(object: any, brand: any, isMailin: any) {
  if (isMailin) {
    return getObjectEntries(object.matrix[brand]);
  } else {
    return getObjectEntries(object.matrix_for_dropoffs[brand]);
  }
}

export function brandSpecificCategoriesFilter(data: any, brand: any) {
  const config = data.brand_specific_config;
  if (config) {
    return config.forced_human_product_types;
  }

  // categories from matrix as fallback
  return Object.keys(data.matrix_for_dropoffs[brand]);
}

export function descriptorFilter(payload: any) {
  const { categories, value } = payload;
  const category = categories.filter((category: any) => category[0] === value);
  return category.length > 0 ? category[0][1] : '';
}

export const casualTopText = 'Casual Tops (not a long or short sleeve shirt)';

export function hasSizes(object: any) {
  const { category } = object;
  if (!category) return true;
  const sizes = sizesFilter(object);
  return sizes !== null ? true : false;
}

export function sizesFilter(object: any) {
  const { category, descriptor } = object;
  const realDescriptor = descriptor === casualTopText ? 'Casual Tops' : descriptor;
  let sizes = { Size1: [], Size2: [] };
  let mappings: any = {};
  CONFIG_DATA.sizes__type_and_descriptor_to_series_mappings.map((size: any) => {
    if (!mappings.hasOwnProperty(size[0])) {
      mappings[size[0]] = {};
    }
    mappings[size[0]][size[1] || ''] = CONFIG_DATA.sizes__series[size[2]];
  });
  if (mappings[category]) {
    return mappings[category][realDescriptor] || sizes;
  }

  return null;
}

export function questionsFilter(config: any, category: any) {
  return config.questions[category] || [];
}

export function buildSellbackItems(arrayOfObj: any) {
  const newArray: any[] = [];

  arrayOfObj.map((item: any) => {
    const obj = {
      human_product_type: item.category,
      human_descriptor: item.descriptor,
      human_brand: item.brand,
      simple_condition: 'used and busted',
      human_condition_survey: {
        promotional_item: 'no',
        item_broken: 'no',
        tags_removed_unreadable: 'no',
        item_very_old: 'no',
      },
      selected_product_internal_sku: item.sku,
      style_id: item.style_id,
      colors: ['none:#000000'],
      size: null,
      size2: null,
    };

    // In our state reducer, we have the initial state of the items set to
    // empty strings. This condition ensures that we haven't passed an empty
    // object to the request payload.
    if (obj.human_product_type !== '' && obj.human_descriptor !== '') {
      newArray.push(obj);
    }
  });
  return newArray;
}

export function filterItems(items: any) {
  const arr = items.map((product: any) => {
    const obj: any = {
      simple_condition: product.condition.toLowerCase(),
      human_condition_survey: product.condition_responses,
      human_product_type: product.category,
      human_descriptor: product.descriptor === casualTopText ? 'Casual Tops' : product.descriptor,
      human_brand: product.brand,
      style_id: product.suggested_style_id === '' ? null : product.suggested_style_id,
      colors: product.colors,
      size: product.size,
      size2: product.size_two === '' ? null : product.size_two,
    };
    if (product.selected_product_internal_sku) {
      obj.selected_product_internal_sku = product.selected_product_internal_sku;
    }
    if (product.selected_product_style_name) {
      obj.selected_product_style_name = product.selected_product_style_name;
    }
    if (product.entered_product_style_id) {
      obj.entered_product_style_id = product.entered_product_style_id;
    }
    if (product.entered_product_style_name) {
      obj.entered_product_style_name = product.entered_product_style_name;
    }
    if (product.suggested_name) {
      obj.suggested_name = product.suggested_name;
    }
    return obj;
  });
  return arr;
}

export function filterItemsDropOffShort(items: any) {
  const arr = items.map((product: any) => {
    const obj = {
      human_product_type: product.category,
      human_descriptor: product.descriptor === casualTopText ? 'Casual Tops' : product.descriptor,
      human_brand: product.brand,
    };
    return obj;
  });
  return arr;
}

export const giftCardDescriptions = [
  {
    subdomain: 'publiclands',
    giftCardLabel: 'Public Lands',
    description:
      'Public Lands is a new kind of outdoor store that empowers everyone to get outside with the latest gear, services and workshops. Whether you are a seasoned pro or just beginning your journey into the outdoors, Public Lands invites you to gear up and get out there.',
  },
  {
    subdomain: 'dicks',
    giftCardLabel: "Dick's",
    description: "DICKS SPORTING GOODS is America's #1 destination for all your sporting goods needs!",
  },
  {
    subdomain: 'obohq',
    giftCardLabel: 'OBO',
    description: '',
  },
];

export function getGiftCardDescription(giftCardLabel: any) {
  let description = '';
  const giftCard = giftCardDescriptions.find((card) => card.giftCardLabel === giftCardLabel);
  if (giftCard) {
    description = giftCard.description;
  }
  return description;
}
