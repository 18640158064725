import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { withStyles } from '@mui/styles';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import HOC from '../HOC';
import InputField from '../../../components/inputField';

const ItemName: React.FC<IChildComponent> = ({
  get_data,
  get_requests,
  store_update_items,
  current_item,
  classes,
}): JSX.Element => {
  const { store_value } = store_update_items;
  const { get_product_name } = get_requests;
  const { loading, products } = get_data;
  const { brand, category, descriptor, suggested_name, name, sku } = current_item;

  useEffect(() => {
    if (brand && category) {
      get_product_name(brand, category, descriptor);
    }
  }, [brand, category, descriptor, get_product_name]);

  const valueHandler = (e: any, values: any) => {
    const { value } = e.target;
    if (values !== null) {
      if (values === undefined) {
        Sentry.captureMessage(`The product is undefined`, Sentry.Severity.Error);
        return;
      }
      store_value({
        name: values.style_name,
        sku: values.sku,
        suggested_style_id: values.style_id,
        selected_product_internal_sku: values.sku,
        selected_product_style_name: values.style_name,
      });
    } else {
      store_value({
        name: '',
        suggested_name: value,
        suggested_style_id: '',
        selected_product_internal_sku: '',
        selected_product_style_name: '',
      });
    }
  };

  const valueStaticHandler = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
    const { name, value } = e.target;
    store_value({
      [name]: value,
      sku: null,
      entered_product_style_name: value,
    });
  };

  return (
    <div className={classes.container}>
      {loading ? (
        <CircularProgress color={'inherit'} style={{ color: '#262262' }} />
      ) : products && products.length > 0 ? (
        <Autocomplete
          id="combo-box-demo"
          options={products}
          value={products.find((product: any) => product.sku === sku)}
          classes={{
            clearIndicator: classes.clearIndicator,
            endAdornment: classes.endAdornment,
            popupIndicator: classes.popupIndicator,
          }}
          popupIcon={
            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <svg
                width="24"
                height="18"
                x="7"
                y="11"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.66732 0.999999L8.72964 10.8681C8.76363 10.9095 8.80536 10.9428 8.85208 10.9655C8.89879 10.9882 8.94943 11 9.00065 11C9.05187 11 9.10251 10.9882 9.14922 10.9655C9.19594 10.9428 9.23767 10.9095 9.27167 10.8681L17.334 1"
                  stroke="#999797"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </svg>
          }
          getOptionLabel={(option: any) => option.style_name}
          className={classes.autoCompleteWrapper}
          onChange={valueHandler}
          noOptionsText={'No names found please click next or try again'}
          renderInput={(params: any) => (
            <TextField
              value={name}
              {...params}
              className={classes.autoComplete}
              onChange={(e) => valueHandler(e, null)}
              id="outlined-basic"
              color="secondary"
              name={'name'}
              variant="outlined"
            />
          )}
        />
      ) : (
        <InputField
          value={suggested_name}
          error={false}
          fieldHandler={(e: any) => valueStaticHandler(e)}
          name={'suggested_name'}
          label={'Product name'}
          labelWidth={130}
          helperText={'Please enter the name of your product.'}
          autoComplete={'off'}
          required={false}
        />
      )}
    </div>
  );
};

const styles = {
  container: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    margin: 'auto',
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  autoCompleteWrapper: {
    width: '400px',
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  autoComplete: {
    '& .MuiAutocomplete-input': {
      padding: '5px 4px 5px 6px !important',
      zIndex: '5 !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d3d1d1',
      borderRadius: '0px',
      background: '#f9f9f9',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d3d1d1',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d3d1d1 !important',
    },
  },
  endAdornment: {
    right: '0px !important',
    top: '0px !important',
    background: '#ececec',
    width: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #d3d1d1',
    zIndex: 5,
  },
  clearIndicator: {
    display: 'none !important',
  },
  popupIndicator: {
    transform: 'unset !important',
  },
};

export default HOC(false)(withStyles(styles)(ItemName));
