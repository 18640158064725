import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { withStyles } from '@mui/styles';
import { CircularProgress, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HOC from '../HOC';
import { offerAccepted, offerPresented } from '../../../utils/analytics';
import ModalComponent from '../../../components/Modal';
import RadioButton from '../../../components/RadioButton';
import CheckBoxButton from '../../../components/CheckBoxButton';
import InputField from '../../../components/inputField';
import dollar from '../../../assets/dollar.png';
import { getGiftCardOptions } from '../../../utils/Logo';
import {
  CONFIG_DATA,
  ROUTES,
  SELLBACK_MODE,
  OFFER_MODE,
  COMMON_RESPONSE,
  SELLBACK2_REQUEST_STATE,
} from '../../../utils/consts';
import { validateEmail, getObjectValues } from '../../../utils/functions';

const Offer: React.FC<IChildComponent> = ({
  get_data,
  post_items,
  store_items,
  classes,
  store_user_value,
  store_post_items,
  store_update_items,
}) => {
  const history: any = useHistory();
  const { search: queryString } = useLocation();
  const { invocationBrand } = get_data;
  const { items, path, offerPromotionToken } = store_items;
  const { storeSavedUser, postSavedUser, transitionSaved, transitionRequested } = store_post_items;
  const { offer, loading, savedLoading, savedUser, error_code, error_message } = post_items;
  const { set_path } = store_update_items;
  const { id: customer_id, first_name, last_name, email } = savedUser;
  const query = new URLSearchParams(queryString);
  const resumeSellbackRequestId = query.get('resume_s2r_id');

  const [radioType, setRadioType] = useState('');
  const [isAgree, setAgree] = useState(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const isValidationError = error_code === `${COMMON_RESPONSE.CODE_VALIDATION_EXCEPTION}`;

  const invocation_brand = CONFIG_DATA.invocation_brand;

  useEffect(() => {
    if (resumeSellbackRequestId) {
      transitionRequested(resumeSellbackRequestId, {
        arguments: {},
      });
      set_path(SELLBACK_MODE.MAIL);
    }
  }, [resumeSellbackRequestId]);

  useEffect(() => {
    if (offer.entity && offer.entity.offers) {
      const { direct } = offer.entity.offers;
      if (direct) {
        offerPresented(items, null, direct.amount);
      }
    }
  });

  useEffect(() => {
    // When the sellback state is requested
    if (
      customer_id &&
      offer &&
      offer.entity &&
      offer.entity.id &&
      offer.entity.state === SELLBACK2_REQUEST_STATE.REQUESTED
    ) {
      transitionSaved(offer.entity.id, {
        arguments: {
          saved_by_customer_id: customer_id,
        },
      });
    }
  }, [customer_id, offer]);

  useEffect(() => {
    if (offer && offer.sellback2_request_id) {
      Sentry.setContext('SW', {
        'S2RI ID': offer.sellback2_request_id,
      });
    }
    if (offer && offer.entity && offer.entity.state === SELLBACK2_REQUEST_STATE.ONLINE_SAVED) {
      history.push(ROUTES.SUCCESS);
      setToggle(false);
    }
    if (offer && offer.entity && offer.entity.offers) {
      const offers = offer.entity.offers;
      if (offers[OFFER_MODE.DIRECT]) {
        setRadioType('cash');
        return;
      }
      if (offers[OFFER_MODE.DIRECT_GIFT_CARD]) {
        const giftCardOptions: any = getGiftCardOptions(offer['gift_card_options']);
        if (giftCardOptions.length > 0) {
          setRadioType(giftCardOptions[0].label);
        }
      }
    }
  }, [offer]);

  const inputFields: any = [
    {
      value: first_name,
      error: first_name !== '' && first_name.length < 2,
      name: 'first_name',
      label: 'First name',
      helperText: 'Please enter your first name',
      helperTextError: 'Please enter your first name',
      type: 'text',
    },
    {
      value: last_name,
      error: last_name !== '' && last_name.length < 2,
      name: 'last_name',
      label: 'Last name',
      helperText: 'Please enter your last name',
      helperTextError: 'Please enter your last name',
      type: 'text',
    },
    {
      value: email,
      error: email !== '' && !validateEmail(email),
      name: 'email',
      label: 'Email',
      helperText: 'Please enter your email',
      helperTextError: 'Please enter a valid email',
      type: 'text',
    },
  ];

  const rejectedHandler = () => {
    return (
      <Fragment>
        <div className={classes.rejectedText}>
          It looks like your gear has seen some good times but, unfortunately, we can't extend an offer for these items
          today. We’re sorry for the inconvenience &#128577;.
        </div>
        <div className={classes.rejectedText}>
          You might be feeling a little down right now. But before tossing your item, consider one of these
          organizations that responsibly dispense old gear.
        </div>
        <div className={classes.rejectedText}>
          And when the time is right and you’re ready to find something new (or used), we’d like to offer you a 20%
          discount on your next purchase on{' '}
          <a
            target="blank"
            style={{ textDecoration: 'none', color: '#f00000' }}
            href="https://www.outandbackoutdoor.com/"
          >
            https://www.outandbackoutdoor.com
          </a>
          .
        </div>
        <div className={classes.rejectedTextCoupon}>SELLBACKTHANKS</div>
        <div className={classes.rejectedText}>
          Please note that is a 20% off code for anything being sold directly by Out&amp;Back.
        </div>
      </Fragment>
    );
  };

  const valueHandler = (mode: string, giftCardOption: any) => {
    store_user_value(['mode'], mode);
    store_user_value(['giftCardOption'], giftCardOption);
    store_user_value(['paymentMethods'], offer.payment_methods);
    offerAccepted(items, mode, null, offer.entity.offers[mode].amount);
  };

  const offHandler = () => {
    if (radioType === 'cash') {
      valueHandler(OFFER_MODE.DIRECT, {});
    } else {
      const giftCardOptions: any = getGiftCardOptions(offer['gift_card_options']);
      const cardOption = giftCardOptions.find((card: any) => card.label === radioType);
      valueHandler(OFFER_MODE.DIRECT_GIFT_CARD, cardOption);
    }
    history.push(`${ROUTES.USER_INFO}${queryString}`);
  };

  const getGiftLink = (label: string) => {
    let url = '#';
    // TODO(dustin): This requires for us to always provide `retail_brands_by_subdomain` configuration and to populate it for both the current experience, if brand-specific, as well as all gift-card brands
    if (CONFIG_DATA.retail_brands_by_subdomain) {
      const domains = getObjectValues(CONFIG_DATA.retail_brands_by_subdomain);
      const find: any = domains.find((domain: any) => domain.label === label);
      if (find) {
        url = find.homepage_url;
      }
    }
    return url;
  };

  const savedUserHandler = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
    const { name, value } = e.target;
    storeSavedUser(name, value);
  };

  const onSavedOffer = () => {
    postSavedUser({
      first_name,
      last_name,
      email,
    });
  };

  const modalContent = () => (
    <div className={classes.paper}>
      <div className={classes.savedTitle}>Save Your Offer For Later!</div>
      <br />
      <div className={classes.saveText}>
        Enter your name and email address and we'll email you a copy of this offer.
      </div>
      <br />
      <div className={classes.saveText}>
        If in the next 30 days you decide to accept the offer, just click "Accept This Offer" link in your email and
        we'll process your sellback and get you paid!
      </div>
      <div className={classes.closeWrapper} onClick={() => setToggle(false)}>
        <CloseIcon fontSize={'small'} color={'error'} />
      </div>
      <div className={classes.inputWrapper}>
        {inputFields.map((field: any) => (
          <div key={field.name} className={classes.fieldWrapper}>
            <InputField
              value={field.value}
              error={field.error}
              fieldHandler={savedUserHandler}
              name={field.name}
              label={field.label}
              helperText={field.helperText}
              helperTextError={field.helperTextError}
              required={false}
              type={field.type}
              autoComplete={'off'}
              inputProps={field.hasOwnProperty('inputProps') ? field.inputProps : {}}
            />
          </div>
        ))}
      </div>
      <Button
        style={{ width: '15em', backgroundColor: '#1C2229', color: '#fff' }}
        className={classes.reviewButton}
        color="inherit"
        variant="contained"
        disabled={
          !(first_name.length > 1 && last_name.length > 1 && email !== '' && validateEmail(email) && !savedLoading)
        }
        onClick={onSavedOffer}
      >
        SAVE MY OFFER
      </Button>
    </div>
  );

  // Reference for rel=noreferrer:
  //
  // https://mathiasbynens.github.io/rel-noopener/#recommendations
  //
  const cashNowHandler = (offer: any) => {
    const giftCardOptions: any = getGiftCardOptions(offer['gift_card_options']);
    const offers = offer.entity.offers;
    return (
      <>
        <div className={classes.title}>Your Offers</div>
        {offerPromotionToken && (
          <>
            {offers[OFFER_MODE.DIRECT].promotional_amount || offers[OFFER_MODE.DIRECT_GIFT_CARD].promotional_amount ? (
              <div className={classes.promoTitle}>Promotion Applied</div>
            ) : (
              <div className={classes.promoTitle} style={{ color: '#f00000', textAlign: 'center' }}>
                PROMOTION DOES NOT APPLY
              </div>
            )}
          </>
        )}
        <div className={classes.contentContainer}>
          {offers[OFFER_MODE.DIRECT] && (
            <div className={classes.cardContainer}>
              <div className={classes.cardItem}>
                <div className={classes.header}>Cash</div>
                <img className={classes.cardLogo} src={dollar} alt="Cash Offer" />
                <div className={classes.amountText}>${Math.floor(offers[OFFER_MODE.DIRECT].amount)}</div>
                {offerPromotionToken && offers[OFFER_MODE.DIRECT].original_amount && (
                  <div className={classes.promoText}>
                    {`$${Math.floor(offers[OFFER_MODE.DIRECT].original_amount)} + `}
                    <span className={classes.promoValue}>
                      {`$${Math.floor(offers[OFFER_MODE.DIRECT].promotional_amount)} (PROMO)`}
                    </span>
                  </div>
                )}
                <div className={classes.cardDescription}>I'll take cash</div>
                <RadioButton
                  name="cash"
                  color="secondary"
                  fieldHandler={(event: any) => setRadioType(event.target.value)}
                  selectedValue={radioType}
                  value={'cash'}
                />
                <div className={classes.nextTextPara}></div>
              </div>
            </div>
          )}
          {offers[OFFER_MODE.DIRECT_GIFT_CARD] &&
            giftCardOptions.map((cardOption: any, index: number) => (
              <div className={classes.cardContainer} key={index}>
                <div className={classes.cardItem}>
                  <div className={classes.header}>{`${cardOption.label}`}</div>
                  {cardOption.image && (
                    <img className={classes.cardLogo} src={cardOption.image} alt="Gift Card Offer" />
                  )}
                  <div className={classes.amountText}>${Math.floor(offers[OFFER_MODE.DIRECT_GIFT_CARD].amount)}</div>
                  {offerPromotionToken && offers[OFFER_MODE.DIRECT_GIFT_CARD].original_amount && (
                    <div className={classes.promoText}>
                      {`$${Math.floor(offers[OFFER_MODE.DIRECT_GIFT_CARD].original_amount)} + `}
                      <span className={classes.promoValue}>
                        {`$${Math.floor(offers[OFFER_MODE.DIRECT_GIFT_CARD].promotional_amount)} (PROMO)`}
                      </span>
                    </div>
                  )}
                  <div className={classes.cardDescription}>I'll take a gift card</div>
                  <RadioButton
                    name="gift"
                    color="secondary"
                    fieldHandler={(event: any) => setRadioType(event.target.value)}
                    selectedValue={radioType}
                    value={cardOption.label}
                  />
                </div>
                {invocation_brand && invocationBrand === cardOption.label.toLowerCase() ? (
                  <></>
                ) : (
                  <div className={classes.nextTextPara}>
                    {`Learn more about ${cardOption.label} `}
                    <a
                      target="_blank"
                      href={getGiftLink(cardOption.label)}
                      style={{ textDecoration: 'unset' }}
                      rel="noreferrer"
                    >
                      here
                    </a>
                  </div>
                )}
              </div>
            ))}
        </div>
        <div className={classes.agreeContainer}>
          <div className={classes.agreeText}>
            I AGREE to Out&amp;Back's{' '}
            <a href="https://outandbackoutdoor.com/pages/terms-of-use" target="_blank">
              terms of use
            </a>{' '}
            and opt-in for customer communications
          </div>
          <CheckBoxButton style={{ padding: '0px' }} valueHandler={() => setAgree(true)} checked={isAgree} />
        </div>
        <div className={classes.buttonWrapper}>
          {!resumeSellbackRequestId && (
            <Button
              onClick={() => {
                history.push(`${ROUTES.REVIEW}${queryString}`);
              }}
              className={classes.button}
              color="info"
              variant="contained"
            >
              Back
            </Button>
          )}
          <Button
            style={{ backgroundColor: '#1C2229', color: '#fff' }}
            onClick={offHandler}
            className={classes.reviewButton}
            color="inherit"
            variant="contained"
            disabled={!isAgree}
          >
            {path === SELLBACK_MODE.DROPOFF ? 'ACCEPT & SCHEDULE YOUR DROP-OFF' : 'ACCEPT & GET YOUR SHIPPING LABEL'}
          </Button>
          {path === SELLBACK_MODE.MAIL && (
            <Button
              style={{ backgroundColor: '#FFFFFF', color: '#26235c' }}
              onClick={() => setToggle(!toggle)}
              className={classes.savedButton}
              color="inherit"
              variant="contained"
              disabled={!isAgree}
            >
              SAVE OFFER FOR LATER
            </Button>
          )}
        </div>
        <ModalComponent modalContent={modalContent} setToggle={setToggle} toggle={toggle} />
      </>
    );
  };

  const rejectedOfferHandler = (offer: any) => (
    <Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '0 15px',
        }}
      >
        <div className={classes.cashNowTest}>
          <b>Rejected Item(s)</b>
        </div>
        <div className={classes.rejectedTextSmall}>Looks like some of your gear has seen some good times--but</div>
        <div className={classes.rejectedTextSmall}>
          unfortunately, we can't extend an offer to you on all of it today.
        </div>
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {error_code ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: '0 15px',
            }}
          >
            <div className={classes.cashNowTest}>Something’s not right.</div>
            <div className={classes.rejectedTextSmall}>
              There was a problem generating your offer
              {isValidationError ? `: ${error_message}` : '.'}
            </div>
            {isValidationError ? (
              <Button
                onClick={() => {
                  history.push(`${ROUTES.REVIEW}${queryString}`);
                }}
                className={classes.backButton}
                color="info"
                variant="contained"
              >
                Back
              </Button>
            ) : (
              <Button
                onClick={() => {
                  window.location.href = '/';
                }}
                className={classes.backButton}
                color="primary"
                variant="contained"
              >
                Start Over
              </Button>
            )}
          </div>
        ) : loading ? (
          <CircularProgress color={'inherit'} style={{ color: '#262262' }} />
        ) : offer.entity && offer.entity.offers ? (
          <Fragment>
            <div className={classes.container}>
              {(offer.entity.offers[OFFER_MODE.DIRECT] || offer.entity.offers[OFFER_MODE.DIRECT_GIFT_CARD]) &&
                offer.entity.id &&
                cashNowHandler(offer)}
              {offer.entity.id === null && rejectedOfferHandler(offer)}
            </div>
          </Fragment>
        ) : (
          <div style={{ padding: '0 15px' }}>{rejectedHandler()}</div>
        )}
      </div>
    </Fragment>
  );
};

const styles = (theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  title: {
    textTransform: 'uppercase' as 'uppercase',
    fontFamily: 'OldSansBlack',
    fontSize: '2.5em',
    fontWeight: 500,
  },
  promoTitle: {
    textTransform: 'uppercase' as 'uppercase',
    fontSize: '1.5em',
    color: '#1C2229',
    fontWeight: 500,
  },
  contentContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row' as 'row',
    flexWrap: 'wrap' as 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    margin: '20px 0 40px',
  },
  header: {
    textTransform: 'uppercase' as 'uppercase',
    fontFamily: 'Dopis-Regular',
    fontSize: '1.5em',
    fontWeight: 500,
    textAlign: 'center' as 'center',
    marginBottom: '20px',
  },
  amountText: {
    textTransform: 'uppercase' as 'uppercase',
    fontSize: '4em',
    fontWeight: 500,
    textAlign: 'center' as 'center',
    margin: '10px 0',
  },
  promoText: {
    textTransform: 'uppercase' as 'uppercase',
    fontSize: '1em',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  promoValue: {
    color: '#1C2229',
  },
  cashNowTest: {
    color: '#000',
    fontSize: '35px',
    fontWeight: 400,
    alignSelf: 'center',
    margin: '30px 0',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
  },
  cardItem: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    padding: '15px',
    border: '1px solid #dee1e6',
    margin: '10px',
    height: '320px',
  },
  cardDescription: {
    textTransform: 'uppercase' as 'uppercase',
    fontSize: '1em',
    fontWeight: 500,
    textAlign: 'center' as 'center',
  },
  cardLogo: {
    height: '40px',
  },
  nextTextPara: {
    color: '#000',
    fontSize: '14px',
    textAlign: 'center' as 'center',
  },
  agreeContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  agreeText: {
    width: '360px',
    fontSize: '1.1em',
    marginRight: '20px',
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
  },
  button: {
    margin: '5px !important',
    fontSize: '16px !important',
    fontWeight: '900 !important',
    '@media (max-width:1023px)': {
      width: '100%',
    },
  },
  reviewButton: {
    margin: '5px !important',
    fontSize: '16px !important',
    fontWeight: '900 !important',
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
    },
    '@media (max-width:1023px)': {
      width: '100%',
    },
  },
  savedButton: {
    margin: '5px !important',
    fontSize: '16px !important',
    fontWeight: '900 !important',
    border: '1px solid #dee1e6 !important',
    boxShadow: '1px 1px 0px 0.2px rgb(0 0 0 / 13%) !important',
    '&.Mui-disabled': {
      color: 'rgb(255, 255, 255) !important',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
    },
    '@media (max-width:1023px)': {
      width: '100%',
    },
  },
  backButton: {
    width: '15em',
    fontSize: '16px !important',
    fontWeight: '900 !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    marginTop: '30px !important',
  },
  typography: {
    fontSize: '20px',
    fontWeight: 800,
    color: theme.palette.secondary.main,
    padding: '0 35px',
  },
  rejectedContainer: {
    marginTop: '25px',
  },
  rejectedText: {
    fontSize: '20px',
    textAlign: 'center' as 'center',
    fontWeight: 600,

    color: theme.palette.secondary.main,
    marginBottom: '15px',
    '@media (max-width:600px)': {
      fontSize: '17.5px',
    },
  },
  rejectedTextCoupon: {
    fontSize: '20px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    marginBottom: '15px',
    textAlign: 'center' as 'center',
  },
  rejectedTextSmall: {
    textAlign: 'center' as 'center',
    fontSize: '18px',
    fontWeight: 400,
    color: '#000',
    marginBottom: '5px',
  },
  paper: {
    position: 'relative' as 'relative',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5, 4),
    outline: 'none',
    margin: '20px',
    width: '100%',
    maxWidth: '800px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    borderRadius: '12px',
    '@media (max-width:500px)': {
      padding: '20px',
    },
  },
  ModalButton: {
    fontWeight: 500,
    cursor: 'pointer',
    color: theme.palette.secondary.main,
  },
  savedTitle: {
    textTransform: 'uppercase' as 'uppercase',
    fontSize: '2em',
    fontWeight: 500,
    textAlign: 'center' as 'center',
    '@media (max-width:500px)': {
      fontSize: '1.5em',
    },
  },
  saveText: {
    fontSize: '1.3em',
    textAlign: 'center' as 'center',
    '@media (max-width:500px)': {
      fontSize: '1em',
    },
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '100%',
    maxWidth: '400px',
    marginTop: '30px',
    marginBottom: '30px',
    '@media (max-width:500px)': {
      marginTop: '20px',
      marginBottom: '20px',
    },
  },
  fieldWrapper: {
    margin: '5px 0',
  },
  closeWrapper: {
    cursor: 'pointer',
    position: 'absolute' as 'absolute',
    right: '-10px',
    top: '-10px',
    border: '1px solid #d1d1d1',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    background: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ff0000',
  },
});

export default HOC()(withStyles(styles)(Offer));
