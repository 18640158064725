import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { Button } from '@mui/material';
import HOC from '../HOC';
import { colorsArray } from '../../../utils/Objects';
import { SELLBACK_STEP } from '../../../utils/consts';

const Colors: React.FC<IChildComponent> = ({
  store_update_items,
  current_item,
  classes,
  disable,
  disableHandle,
}): JSX.Element => {
  const { store_value, setItemStep } = store_update_items;
  const { colors } = current_item;

  useEffect(() => {
    disableHandle(!(colors && colors.length > 0));
  }, [disableHandle, colors]);

  const handleChange = (color: any) => {
    store_value({ colors: [...colors, color] });
    setMediumColor('');
  };

  const deleteHandler = (val: string) => {
    const newValue = [...colors];
    newValue.splice(colors.indexOf(val), 1);
    store_value({ colors: newValue });
  };

  const [mediumColor, setMediumColor] = useState<string>('');

  const filterColors: any = colorsArray.filter((color) => color.medium === mediumColor)[0];

  return (
    <div className={classes.container}>
      <div className="headline">Select the colors that best match your item</div>
      <div className={classes.contentContainer}>
        {colors.length > 0 && (
          <div className={classes.selectedColorsContainer}>
            <div className={classes.selectedText}>
              {colors.length === 1 ? `1 Selected Color` : `${colors.length} out of 5 Colors Selected`}
            </div>
            <div className={classes.selectedColorsRow}>
              {colors.map((color: string, idx: number) => {
                return (
                  <div
                    onClick={() => deleteHandler(color)}
                    key={idx}
                    className={classes.displayColor}
                    style={{ backgroundColor: color.split(':')[1] }}
                  />
                );
              })}
            </div>
            <div className={classes.selectedTextDelete}>Click color to delete</div>
          </div>
        )}
        {colors.length === 5 ? null : filterColors === undefined ? (
          <div className={classes.colorContainer}>
            {colorsArray.map((color: any, idx: number) => {
              return (
                <div
                  key={idx}
                  style={{
                    display: 'flex',
                    width: '20%',
                    justifyContent: 'center',
                    marginBottom: '15px',
                  }}
                >
                  <div
                    onClick={() => setMediumColor(color.medium)}
                    style={{
                      boxShadow: '0 2px 10px rgba(0,0,0,.12), 0 2px 5px rgba(0,0,0,.16)',
                      backgroundColor: color.medium.split(':')[1],
                      width: '35px',
                      height: '35px',
                      borderRadius: '50%',
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className={classes.colorContainer}>
            {filterColors !== undefined && filterColors.light !== undefined && (
              <div className={classes.colorWrapper}>
                <div
                  onClick={() => handleChange(filterColors.light)}
                  style={{
                    boxShadow: '0 2px 10px rgba(0,0,0,.12), 0 2px 5px rgba(0,0,0,.16)',
                    backgroundColor: filterColors.light.split(':')[1],
                    width: '35px',
                    height: '35px',
                    borderRadius: '50%',
                  }}
                />
              </div>
            )}
            <div className={classes.colorWrapper}>
              <div
                onClick={() => handleChange(filterColors.medium)}
                style={{
                  boxShadow: '0 2px 10px rgba(0,0,0,.12), 0 2px 5px rgba(0,0,0,.16)',
                  backgroundColor: filterColors.medium.split(':')[1],
                  width: '35px',
                  height: '35px',
                  borderRadius: '50%',
                }}
              />
            </div>
            {filterColors !== undefined && filterColors.light !== undefined && (
              <div className={classes.colorWrapper}>
                <div
                  onClick={() => handleChange(filterColors.dark)}
                  style={{
                    boxShadow: '0 2px 10px rgba(0,0,0,.12), 0 2px 5px rgba(0,0,0,.16)',
                    backgroundColor: filterColors.dark.split(':')[1],
                    width: '35px',
                    height: '35px',
                    borderRadius: '50%',
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <Button
        onClick={() => setItemStep(SELLBACK_STEP.CONDITION)}
        className={classes.button}
        color="primary"
        variant="contained"
        disabled={disable}
      >
        Next
      </Button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    height: '100%',
    '& .headline': {
      marginBottom: '30px',
    },
  },
  contentContainer: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: '250px',
    margin: '30px 5px 0 !important',
    fontSize: '16px !important',
    fontWeight: '900 !important',
  },
  selectedColorsContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '90%',
    borderRadius: '10px',
    border: `1px solid #262262`,
    margin: '0 0 25px 0',
  },
  selectedColorsRow: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
  },
  colorWrapper: {
    display: 'flex',
    width: '20%',
    justifyContent: 'center',
    marginBottom: '15px',
    alignItems: 'center',
  },
  displayColor: {
    boxShadow: '0 2px 10px rgba(0,0,0,.12), 0 2px 5px rgba(0,0,0,.16)',
    width: '30px',
    height: '30px',
    margin: '5px 5px 10px 5px',
    borderRadius: '10px',
  },
  selectedText: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: 800,
    margin: '10px 0',
  },
  selectedTextDelete: {
    display: 'flex',
    fontSize: '12px',
    fontWeight: 500,
    margin: '0px 0 5px 0',
  },
  colorContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '80%',
    flexWrap: 'wrap' as 'wrap',
    height: '150px',
  },
};

export default HOC(false)(withStyles(styles)(Colors));
