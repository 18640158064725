import React from 'react';
import { withStyles } from '@mui/styles';
import { Fade, Modal } from '@mui/material';

interface IModal {
  classes: any;
  modalContent: any;
  toggle: boolean;
  setToggle: Function;
}

const ModalComponent: React.FC<IModal> = ({ classes, modalContent, toggle, setToggle }): JSX.Element => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={toggle}
      onClose={() => setToggle(!toggle)}
      closeAfterTransition
    >
      <Fade in={toggle}>{modalContent()}</Fade>
    </Modal>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default withStyles(styles)(ModalComponent);
